import {logger} from './index';

export default async () => {
  if (!process.env.REACT_APP_IP_INFO_TOKEN) {
    return '';
  }

  const url = `https://pro.ip-api.com/json/?fields=regionName&key=${process.env.REACT_APP_IP_INFO_TOKEN}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    const regionName = data.regionName || '';
    return regionName;
  } catch (error) {
    logger.error('Error finding region:', error);
    return '';
  }
};
