import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {CurrencyField, ButtonSelect, CheckboxPanel} from '@renofi/components';

import {Info} from '../../components';
import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

const options = {
  Yes: true,
  No: false,
};
function ChildSupport({onChange, onToggle, onPaymentSelectionsChange, noDebt}) {
  const {childSupportPayment} = useReactiveVar(leadVar);
  const {isChildSupportGroupOpen, isMoreThan10MChildSupportLeft} =
    useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Child support payment"
      onToggle={onToggle}
      value={isChildSupportGroupOpen}
      greyOut={noDebt}>
      <Flex mb={30}>
        <Box width={1 / 2}>
          <SideLabel>
            Will the payments continue for more than 10 months from now?
          </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <ButtonSelect
            value={isMoreThan10MChildSupportLeft}
            inline
            internal
            onClick={(value) =>
              onPaymentSelectionsChange('isMoreThan10MChildSupportLeft', value)
            }
            options={options}
          />
        </Box>
      </Flex>
      {isMoreThan10MChildSupportLeft && (
        <Flex width={1}>
          <Box width={1 / 2}>
            <SideLabel>
              <strong>Monthly</strong> child support
            </SideLabel>
          </Box>
          <Box width={1 / 2}>
            <CurrencyField
              errMessages={{
                max: `You have entered $${toCurrency(
                  childSupportPayment,
                )} as your monthly child support payment. If that's incorrect, please update, otherwise continue.`,
              }}
              errorCss={{position: 'static'}}
              max={5000}
              currency
              icon
              name="child-support-payment"
              value={childSupportPayment}
              onChange={(value) => onChange('childSupportPayment', value)}
              analyticsEnabled
              analyticsPrefix="POS"
            />
          </Box>
        </Flex>
      )}
      {isMoreThan10MChildSupportLeft === false && (
        <Info>
          Balances that will be paid off in the next 10 months are excluded from
          your DTI calculation.
        </Info>
      )}
    </CheckboxPanel>
  );
}

ChildSupport.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPaymentSelectionsChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default ChildSupport;
