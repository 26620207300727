import React from 'react';

import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {Heading} from '../../../components';

import Step from './Step';
import {HowItWorksSteps, CTA, Disclaimer} from './styled';

const HowItWorksSection = ({
  title = 'How does this work?',
  steps,
  callToAction,
  disclaimer,
}) => (
  <Box
    css={{
      backgroundColor: '#F7FCFF',
    }}>
    <Flex justifyContent={'center'}>
      <Box>
        <Heading
          center
          css={{
            marginTop: ['24px', '56px'],
            '& mark': {backgroundColor: 'transparent', color: '#FF5253'},
          }}>
          {title}
        </Heading>
        <HowItWorksSteps>
          {steps.map(({title, description}, index) => (
            <Step key={index} title={title} description={description} />
          ))}
        </HowItWorksSteps>
        <Flex justifyContent={'center'}>
          <CTA>{callToAction}</CTA>
        </Flex>
      </Box>
    </Flex>
    <Disclaimer>{disclaimer}</Disclaimer>
  </Box>
);

HowItWorksSection.propTypes = {
  steps: PropTypes.array,
  callToAction: PropTypes.any,
};

export default HowItWorksSection;
