import {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as ScheduleIcon} from './img/schedule.svg';
import {ReactComponent as SchedulesIcon} from './img/schedules.svg';
import {ReactComponent as SchedulesPlusIcon} from './img/60days.svg';

const options = [
  {
    title: 'In next 30 days',
    value: 'thirty',
    icon: ScheduleIcon,
  },
  {
    title: 'In next 60 days',
    value: 'sixty',
    icon: SchedulesIcon,
  },
  {
    title: 'In more than 60 days',
    value: 'more_than_sixty',
    icon: SchedulesPlusIcon,
  },
];

function PurchaseClosing({updateScenario}) {
  const next = useNext();
  const {estimatedDaysUntilClosingPurchase: value} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Purchase-Closing-Page'), []);

  function onSubmit(estimatedDaysUntilClosingPurchase) {
    setLead({estimatedDaysUntilClosingPurchase});
    updateScenario({estimatedDaysUntilClosingPurchase});

    sendEvent('POS/Purchase-Closing-Entered', {
      estimatedDaysUntilClosingPurchase,
    });
    next('/renovation-start/after-closing');
  }

  return (
    <>
      <Heading>When are you closing on your home purchase?</Heading>
      <IconSelect onClick={onSubmit} options={options} value={value} />
      <Buttons
        disableNext={isNil(value)}
        onNext={() => onSubmit(value)}
        value={value}
      />
    </>
  );
}

PurchaseClosing.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default PurchaseClosing;
