import React from 'react';

import styled from '@emotion/styled/macro';

import {red} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

import {BaseIntro} from '../components';

import {CancelledIcon} from './img';

const StyledCancelledIcon = styled(CancelledIcon)(
  mediaQuery({
    width: [217, 236],
    height: [119, 127],
  }),
);

const CancelledQuestionnaire = () => {
  return (
    <BaseIntro
      subHeading={
        'You are no longer required to complete the RenoFi contractor questionnaire.'
      }
      alertText={
        <>
          After an initial review of the renovation project,{' '}
          <b>
            you are no longer required to complete the Contractor questionnaire
          </b>{' '}
          previously requested. Apologies for any inconvenience caused and
          please do not hesitate to contact us should you have questions on this
          matter.
        </>
      }
      subHeadingProps={{color: red, small: true}}
      mainImage={<StyledCancelledIcon />}
    />
  );
};

export default CancelledQuestionnaire;
