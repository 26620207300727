import PropTypes from 'prop-types';
import {isNil} from 'lodash';

import {Buttons} from '../index';
import IconSelect from '../IconSelect';

import {ReactComponent as YesIcon} from './img/yes.svg';
import {ReactComponent as NoIcon} from './img/no.svg';
import {iconCss} from './styled';

function YesNoSelect({
  value,
  loading,
  onSubmit,
  showButtons = true,
  yesText = 'Yes',
  noText = 'No',
}) {
  const options = [
    {
      title: noText,
      value: false,
      icon: NoIcon,
    },
    {
      title: yesText,
      value: true,
      icon: YesIcon,
    },
  ];

  return (
    <>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={value}
        css={iconCss}
      />
      {showButtons ? (
        <Buttons
          loading={loading}
          disableNext={isNil(value)}
          onNext={() => onSubmit(value)}
          value={value}
        />
      ) : null}
    </>
  );
}

YesNoSelect.propTypes = {
  yesText: PropTypes.string,
  noText: PropTypes.string,
  value: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default YesNoSelect;
