import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {land as enums} from '@renofi/api/src/enums';
import {roundToNextMillion, getPercent} from '@renofi/utils/src/format';
import {sendEvent} from '@renofi/analytics';
import {useRenovationUtils} from '@renofi/utils';

import {Buttons, Heading, Slider, Narrow} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';

function RemainingBalance({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {
    firstMortgageBalance = 0,
    homeValue = 0,
    propertyOwnershipStatus,
  } = lead;
  const {isHomeBuild} = useRenovationUtils(lead);
  const isLandOwned = propertyOwnershipStatus === enums.owned;
  const max =
    homeValue > 0
      ? roundToNextMillion(homeValue + getPercent(30, homeValue))
      : 2000000;

  useEffect(() => sendEvent('POS/Remaining-Balance-Page'), []);

  function onChange(value) {
    setLead({firstMortgageBalance: value});
  }

  function onSubmit() {
    onChange(firstMortgageBalance);

    updateScenario({firstMortgageBalance});

    sendEvent('POS/Remaining-Balance-Entered', {firstMortgageBalance});
    let path = '/credit-score';

    if (isHomeBuild && isLandOwned) {
      path = '/construction/structure/tear-down';
    }

    next(path);
  }

  return (
    <>
      <Narrow maxWidth={700}>
        <Heading>
          {isLandOwned
            ? 'Estimated current mortgage balance on the property'
            : 'My estimated remaining mortgage balance is about'}
        </Heading>
      </Narrow>
      <Slider
        noTooltip
        min={0}
        max={max}
        step={5000}
        suffix={firstMortgageBalance === 0 ? ' (Paid off)' : ''}
        minLabelSuffix=" (Paid off)"
        onChange={onChange}
        value={firstMortgageBalance}
      />
      <Buttons onNext={onSubmit} />
    </>
  );
}

export default RemainingBalance;
