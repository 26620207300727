import {makeVar} from '@apollo/client';

// Reactive variables
export const paymentSelectionsVar = makeVar([]);

// Setters
export const setPaymentSelections = (paymentSelections) => {
  paymentSelectionsVar({...paymentSelectionsVar(), ...paymentSelections});
};

// Apollo field policy
export const paymentSelectionsPolicy = {
  read() {
    return paymentSelectionsVar();
  },
};

export const resetPaymentSelections = () => {
  paymentSelectionsVar({});
};
