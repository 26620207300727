/* eslint-disable no-undef */

import {logger} from '@renofi/utils';

import {gaParams} from './analytics';

const execute = (func) => {
  try {
    func();
  } catch (error) {
    logger.log('ERROR', error);
  }
};

export function initSegment(options) {
  const SEGMENT_KEY =
    process.env.REACT_APP_SEGMENT_KEY || process.env.NEXT_PUBLIC_SEGMENT_KEY;
  const appEnv = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;
  const renofiTld =
    process.env.REACT_APP_RENOFI_TLD || process.env.NEXT_PUBLIC_RENOFI_TLD;

  if (appEnv !== 'production' && appEnv !== 'staging') {
    return;
  }
  // Create a queue, but don't obliterate an existing one!
  var analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (logger?.error) {
      logger.error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://segcdn.${renofiTld}/analytics.js/v1/${key}/analytics.min.js`;

    // Insert our script next to the first script element.
    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = SEGMENT_KEY;

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.15.2';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  if (SEGMENT_KEY) analytics.load(SEGMENT_KEY, options ? options : undefined);

  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  analytics.page(window.location.pathname, {
    ...gaParams(),
    url: window.location.href,
    path: window.location.pathname,
    search: window.location.search,
  });

  analytics.ready(function () {
    execute(() => {
      ga('require', 'linker');
      ga('linker:autoLink', ['renofi.com']);
    });
  });

  window.analytics = analytics;
}
