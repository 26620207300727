import {makeVar} from '@apollo/client';

// Reactive variables
export const gcddReviewsVar = makeVar([]);

// Setter
export const setGcddReviews = (gcddReviews) => {
  gcddReviewsVar([...gcddReviews]);
};

export const isQuestionnaireCancelled = (gcddReviews) =>
  gcddReviews.every((review) => !!review.cancelledAt);

// Apollo field policy
export const gcddReviewsPolicy = {
  read() {
    return gcddReviewsVar();
  },
};

export default {
  gcddReviewsPolicy,
  setGcddReviews,
};
