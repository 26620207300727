import styled from '@emotion/styled/macro';
import {Flex, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {PanelWhite} from '@renofi/components';

export const IconsWrapper = styled(Flex)(
  mediaQuery({
    flexWrap: 'wrap',
    justifyContent: 'center',
    gridTemplateColumns: '50% 50%',
    maxWidth: [420, '100%'],
    margin: ['auto', 0],
    marginTop: [-28, -28],
  }),
  ({vertical}) =>
    mediaQuery({
      padding: ['30px 30px'],
      display: vertical ? 'flex' : ['grid', 'flex'],
      gridGap: vertical ? 8 : 30,
      flexDirection: ['column', vertical ? 'column' : 'row'],
    }),
);

export const IconSelectWrapper = styled(PanelWhite)(
  mediaQuery({
    zIndex: 1,
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    svg: {
      maxHeight: [50, 70],
    },
  }),
  ({vertical, isLoading, css}) =>
    mediaQuery({
      maxWidth: vertical ? 350 : 188,
      width: ['100%', vertical ? 350 : 188],
      height: ['100%', vertical ? 70 : ''],
      minHeight: ['100%', vertical ? 70 : 164],
      margin: ['auto', vertical ? 'auto' : 0],
      flexDirection: vertical ? 'row' : 'column',
      padding: vertical ? '16px 24px' : '16px 8px',
      justifyContent: isLoading ? 'center' : 'flex-start',
      ...css,
    }),
);

export const ButtonText = styled(Box)({}, ({vertical}) =>
  mediaQuery({
    marginTop: vertical ? 0 : 12,
    marginBottom: [0, '5px'],
    marginLeft: vertical ? 24 : 0,
    lineHeight: vertical ? '40px' : '19px',
  }),
);

export const IconWrapper = styled(Flex)({}, ({vertical}) =>
  mediaQuery({
    height: vertical ? 'auto' : 75,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }),
);
