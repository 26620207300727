import React, {useState} from 'react';

import {isNil} from 'lodash';
import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {useScreenSize} from '@renofi/utils';

import {TooltipButton, TooltipWrapper, StyledTooltip, Text} from './styled';

const InfoTooltip = ({
  id,
  css,
  children,
  place,
  iconWidth,
  width,
  customIcon,
  delayHide,
  dark,
  stopPropagation,
}) => {
  const [clicked, setClicked] = useState(false);
  const {isMobile} = useScreenSize();

  return (
    <TooltipWrapper
      css={css}
      key={isMobile ? 'mobile-rebuild-key' : 'desktop-rebuild-key'}>
      <Box data-tooltip-id={id}>
        {customIcon ? (
          customIcon
        ) : (
          <TooltipButton
            onClick={(e) => stopPropagation && e.stopPropagation()}
            data-testid={`${id}-trigger`}
            iconWidth={iconWidth}
            id={id}
            tabIndex={-1}
            clicked={isMobile ? clicked : null}
          />
        )}
      </Box>
      <StyledTooltip
        openOnClick={isMobile}
        closeEvents={{
          mouseleave: true,
          blur: true,
          click: true,
          dblclick: true,
          mouseup: true,
        }}
        globalCloseEvents={{
          scroll: true,
          escape: true,
          resize: true,
          clickOutsideAnchor: true,
        }}
        afterHide={() => setClicked(false)}
        afterShow={() => setClicked(true)}
        place={place || undefined}
        ref={(ref) => ref}
        opacity={1}
        id={id}
        backgroundColor="white"
        className={dark ? 'react-tooltip__dark react-tooltip' : 'react-tooltip'}
        delayHide={isNil(delayHide) ? 100 : 0}
        effect="solid"
        width={width}
        clickable>
        <Text>{children}</Text>
      </StyledTooltip>
    </TooltipWrapper>
  );
};

InfoTooltip.propTypes = {
  id: PropTypes.string,
  css: PropTypes.object,
  children: PropTypes.object,
  width: PropTypes.number,
  iconWidth: PropTypes.number,
  place: PropTypes.string,
  delayHide: PropTypes.number,
  customIcon: PropTypes.element,
  dark: PropTypes.bool,
  stopPropagation: PropTypes.bool,
};

export default InfoTooltip;
