export const EARLY_SCHEDULED_CALL_DETAILS_COOKIE_KEY =
  'earlyScheduledCallDetails';

export const PROGRESS_SECTIONS = {
  RENOVATION_COST: 'RENOVATION_COST',
  CONTACT_INFO: 'CONTACT_INFO',
  PROPERTY_INFO: 'PROPERTY_INFO',
  RENOVATION_TIMELINE: 'RENOVATION_TIMELINE',
  INCOME_AND_PAYMENTS: 'INCOME_AND_PAYMENTS',
  RENOVATION_EXECUTION: 'RENOVATION_EXECUTION',
};

export const PAYMENTS_MAP = {
  mortgage: {
    group: 'isMortgageGroupOpen',
    payment: 'primaryMortgagePayment',
  },
  vehicleLoan: {
    group: 'isVehicleLoanGroupOpen',
    selection: 'isMoreThan10MVehicleLoanLeft',
    selectionImpactOn: true,
    payment: 'vehiclePayment',
  },
  creditCard: {
    group: 'isCreditCardGroupOpen',
    selection: 'creditCardPaidOff',
    selectionImpactOn: false,
    payment: 'creditCardPayment',
  },
  personalLoan: {
    group: 'isPersonalLoanGroupOpen',
    payment: 'personalLoanPayment',
  },
  studentLoan: {
    group: 'isStudentLoanGroupOpen',
    selection: 'studentLoanIsPaying',
    selectionImpactOn: true,
    payment: 'studentLoanPayment',
  },
  homeEquity: {
    group: 'isSecondaryMortgageGroupOpen',
    payment: 'secondaryMortgagePayment',
  },
  childSupport: {
    group: 'isChildSupportGroupOpen',
    selection: 'isMoreThan10MChildSupportLeft',
    selectionImpactOn: true,
    payment: 'childSupportPayment',
  },
  alimony: {
    group: 'isAlimonyGroupOpen',
    selection: 'isMoreThan10MAlimonyLeft',
    selectionImpactOn: true,
    payment: 'alimonyPayment',
  },
};
