import {useState} from 'react';

import {useHistory} from 'react-router-dom';

import {useLocalStorage, getQueryParam} from '@renofi/utils';
import {stringifyUrlParams} from '@renofi/utils/src/format';
import {getPersonalLeadData} from '@renofi/utils/src/lead';

import useApi from '../../api/useApi';
import {setLead} from '../../api/cache';

function isScheduleParamSet() {
  const scheduleParam = getQueryParam('schedule');
  return Boolean(scheduleParam && scheduleParam !== 'false');
}

const useFetchLead = () => {
  const [fetchStatus, setFetchStatus] = useState('loading');
  const history = useHistory();
  const {queryScenario} = useApi();
  const [_, setScheduleStorage] = useLocalStorage('renofi:schedule');

  async function fetchLead(id) {
    if (id) {
      const scenario = await queryScenario(id);
      const newLead = scenario?.lead;
      const prequalCompleted = newLead?.prequalCompletedAt;
      const isSchedule = isScheduleParamSet();

      const personalData = getPersonalLeadData(id);
      setLead(personalData);

      setScheduleStorage(isSchedule);

      if (!newLead || !prequalCompleted) {
        const path = isSchedule ? '/intro' : '/loan-type';
        history.push(`${path}?${stringifyUrlParams()}`);
      }

      setFetchStatus('success');
    } else {
      setFetchStatus('success');
    }
  }

  return {fetchLead, fetchStatus, setFetchStatus};
};

export default useFetchLead;
