import React, {useEffect, useState} from 'react';

import {Box} from 'rebass';
import {keys} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {CurrencyField, Label} from '@renofi/components';

import {Buttons, Heading, Narrow, Info} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';
import {canShowRatesPage} from '../util';

const PAYMENTS_MAP = {
  primaryMortgagePayment: 'Primary mortgage payment',
  vehiclePayment: 'Vehicle loan payment',
  creditCardPayment: 'Credit card payment',
  personalLoanPayment: 'Personal loan payment',
  studentLoanPayment: 'Student loan payment',
  secondaryMortgagePayment: 'Secondary mortgage payment',
  childSupportPayment: 'Child support payment',
  alimonyPayment: 'Alimony payment',
};

function Dti({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {
    annualIncomePretax,
    calculatedDti = 0.5,
    primaryMortgagePayment,
    vehiclePayment,
    creditCardPayment,
    personalLoanPayment,
    studentLoanPayment,
    secondaryMortgagePayment,
    childSupportPayment,
    alimonyPayment,
  } = lead;
  const [localPayments, setLocalPayments] = useState({
    annualIncomePretax,
    primaryMortgagePayment,
    vehiclePayment,
    creditCardPayment,
    personalLoanPayment,
    studentLoanPayment,
    secondaryMortgagePayment,
    childSupportPayment,
    alimonyPayment,
  });
  const [paymentsChanged, setPaymentsChanged] = useState(false);

  const payments = keys(PAYMENTS_MAP).filter((key) => !!lead[key]);

  useEffect(() => sendEvent('POS/Dti-Page'), []);

  function onChange(key, value) {
    if (localPayments[key] !== value) {
      setPaymentsChanged(true);
      setLocalPayments({...localPayments, [key]: value});
    }
  }

  function onNext() {
    if (paymentsChanged) {
      setLead(localPayments);
      updateScenario(localPayments);
    }

    const path = canShowRatesPage(lead) ? '/rate' : '/contractors';
    next(path);
  }

  return (
    <>
      <Heading>
        Your debt-to-income seems high ({(calculatedDti * 100).toFixed(2)}%).{' '}
        <br />
        Please review and confirm your details:
      </Heading>
      <Narrow>
        <Label css={{marginBottom: '10px'}}>
          <strong>Income</strong>
        </Label>
        <Label small htmlFor="annualIncomePretax">
          Total&nbsp;<strong>annual</strong>&nbsp;gross income before tax
        </Label>
        <CurrencyField
          name="annualIncomePretax"
          icon
          value={localPayments.annualIncomePretax}
          onChange={(value) => onChange('annualIncomePretax', value)}
        />

        {!!payments.length && (
          <>
            <Label css={{marginTop: '30px', marginBottom: '10px'}}>
              <strong>Payments</strong>
            </Label>

            {payments.map((paymentKey, index) => (
              <Box key={index}>
                <Label small htmlFor={paymentKey}>
                  {PAYMENTS_MAP[paymentKey]}
                </Label>
                <Box marginBottom={20}>
                  <CurrencyField
                    data-testid={`${paymentKey}-test-id`}
                    icon
                    value={localPayments[paymentKey]}
                    onChange={(value) => onChange(paymentKey, value)}
                  />
                </Box>
              </Box>
            ))}
            <Info>
              Balances that will be paid off in the next 10 months are excluded.
            </Info>
          </>
        )}

        <Buttons onNext={onNext} />
      </Narrow>
    </>
  );
}

export default Dti;
