import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export default styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    margin: ['0', 'auto'],
  }),
  ({width, align}) =>
    mediaQuery({
      width: ['100%', width ? width : 920],
      maxWidth: ['none', '100%'],
      textAlign: align ? align : 'center',
    }),
);
