import {useState, useEffect} from 'react';

import {Box, Text, Image} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {usePriceScenario} from '@renofi/api';
import {Alert} from '@renofi/components';
import {getLeadCreditScore, constants} from '@renofi/utils';
import {toCurrency} from '@renofi/utils/src/format';

import {leadVar, earlyOptionsVar, setEarlyOptions} from '../../api/cache';

import loader from './loader.svg';

const getPurposeByLoanSubject = (loanSubject) => {
  const {CURRENT_HOME, HOME_PURCHASED, ADU} = constants.LOAN_SUBJECT;
  const renovationSubjects = [CURRENT_HOME, HOME_PURCHASED, ADU];
  if (renovationSubjects.includes(loanSubject)) return 'renovation';
  return 'construction';
};

const AvailableOptionsAlert = () => {
  const lead = useReactiveVar(leadVar);
  const earlyOptions = useReactiveVar(earlyOptionsVar);
  const {
    loanSubject,
    afterRenovatedValue,
    homeValue,
    propertyPurchasePrice,
    firstMortgageBalance,
    propertyUseType,
    propertyStructureType,
    loanAmountDesiredForRenovation,
    zipCode,
  } = lead;
  const [optionsFetched, setOptionsFetched] = useState(
    Boolean(earlyOptions.length),
  );
  const fetchPotentialOptions = usePriceScenario({
    onCompleted: ({priceScenario}) => {
      setOptionsFetched(true);
      setEarlyOptions(priceScenario);
    },
  });

  const optionsCount = earlyOptions.length;
  const hasOptions = Boolean(optionsCount);
  const loanAmountAvailable = hasOptions
    ? Math.max(
        ...earlyOptions.map(
          ({loanAmountDesiredForRenovation}) => loanAmountDesiredForRenovation,
        ),
      )
    : 0;

  const dataAvailable = Boolean(
    getLeadCreditScore(lead) &&
      loanSubject &&
      afterRenovatedValue &&
      (homeValue || propertyPurchasePrice) &&
      loanAmountDesiredForRenovation &&
      zipCode,
  );

  useEffect(() => {
    if (!earlyOptions.length && dataAvailable) {
      const loanData = {
        afterRenovationValue: afterRenovatedValue,
        creditScore: getLeadCreditScore(lead),
        currentHomeValue: homeValue || propertyPurchasePrice,
        firstMortgageBalance: firstMortgageBalance || 0,
        loanAmount: loanAmountDesiredForRenovation,
        loanPurpose: getPurposeByLoanSubject(loanSubject),
        occupancyType: propertyUseType || 'primary_residence',
        propertyType: propertyStructureType || 'single_family',
        secondMortgageBalance: 0,
        zipCode,
      };
      fetchPotentialOptions(loanData);
    }
  }, [dataAvailable]);

  if (!dataAvailable) {
    return null;
  }

  return (
    <Box
      marginLeft="auto"
      marginRight="auto"
      marginBottom="48px"
      display="inline-block">
      <Alert bgColor="#E7F9E9" color="#0B7517" border="1px solid #13C427">
        <Text
          alignItems="center"
          textAlign="left"
          fontSize={[15, 20]}
          lineHeight="22px">
          {!optionsFetched && (
            <>
              <Image marginRight="20px" src={loader} />
              Searching for the best possible options for your project...
            </>
          )}
          {optionsFetched && !hasOptions && (
            <>We need some more information to find the perfect option.</>
          )}
          {optionsFetched && hasOptions && (
            <>
              🎉 {optionsCount} potential loan options found allowing you to
              borrow up to&nbsp;
              <strong>${toCurrency(loanAmountAvailable)}</strong>
            </>
          )}
        </Text>
      </Alert>
    </Box>
  );
};

export default AvailableOptionsAlert;
