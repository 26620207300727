import {useEffect} from 'react';

import {
  getDomain,
  useCookies,
  isDeployedUnderNetlifyDomain,
} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';

export default function useCookieConsent(callback, isLead) {
  const {cookie, updateCookie} = useCookies(
    UTM_COOKIE_KEYS['cookieconsent'],
    false,
  );
  useEffect(() => {
    if (cookie || isLead) {
      callback();
    }
  }, [cookie, isLead]);

  const setConsentCookie = () => {
    const domain = getDomain();
    /* set standard cookie on preview links */
    if (isDeployedUnderNetlifyDomain()) {
      updateCookie({value: true, numberOfDays: 365});
    }
    if (domain) {
      updateCookie({value: true, numberOfDays: 365, domain: `.${domain}`});
    }
  };
  return {cookie, setConsentCookie};
}
