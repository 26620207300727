import {makeVar} from '@apollo/client';

// Reactive variables
export const loanProductsVar = makeVar([]);

// Setters
export const setLoanProducts = (loanProducts) => {
  loanProductsVar(loanProducts);
};

// Apollo field policy
export const loanProductsPolicy = {
  read() {
    return loanProductsVar();
  },
};
