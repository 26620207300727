import React from 'react';

import {noop} from 'lodash';
import {Box} from 'rebass';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {useScreenSize} from '@renofi/utils';
import {Loader} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

import StickyBottom from '../StickyBottom';

import {ButtonsWrapper} from './styled';
import BackButton from './BackButton';
import NextButton from './NextButton';

const Buttons = ({
  children = null,
  onNext = noop,
  onBack,
  disableNext,
  disableBack,
  loading,
  hideBack,
  hideNext,
  fullWidth,
  backButtonProps,
  nextButtonProps,
  customContinueText,
  css,
}) => {
  const {isMobile} = useScreenSize();

  function onNextClick() {
    if (loading) return;
    onNext();
  }

  function getLoadingColor() {
    if (disableNext) return gray;
    return nextButtonProps?.secondary ? '#DEDEDE' : 'white';
  }

  const content = (
    <StickyBottom>
      <Box bg={['rgb(231, 249, 233)', 'transparent']}>
        {children}
        <ButtonsWrapper
          hideBack={hideBack}
          hideNext={hideNext}
          css={css}
          fullWidth={fullWidth}>
          {' '}
          {hideBack ? null : (
            <BackButton
              css={{
                width: hideNext ? '100%' : backButtonProps?.text ? 'auto' : 160,
              }}
              onClick={onBack}
              disabled={disableBack}
              {...backButtonProps}
            />
          )}
          {hideNext ? null : (
            <NextButton
              handleEnter
              onClick={onNextClick}
              disabled={disableNext}
              css={{width: hideBack ? '100%' : 160}}
              {...nextButtonProps}>
              {loading ? (
                <Loader mt={0} color={getLoadingColor()} />
              ) : (
                customContinueText || 'Continue'
              )}
            </NextButton>
          )}
        </ButtonsWrapper>
      </Box>
    </StickyBottom>
  );

  return isMobile
    ? ReactDOM.createPortal(content, document.getElementById('footer-mobile'))
    : content;
};

Buttons.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  children: PropTypes.node,
  disableNext: PropTypes.bool,
  disableBack: PropTypes.bool,
  backButtonProps: PropTypes.object,
  nextButtonProps: PropTypes.object,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideBack: PropTypes.bool,
  hideNext: PropTypes.any,
  customContinueText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Buttons;
