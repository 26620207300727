import {omitBy, isNull, size, isObject} from 'lodash';

import {toCurrency} from '@renofi/utils/src/format';
import {getLeadCreditScore} from '@renofi/utils';
import {
  getPersonalLeadData,
  savePersonalLeadData,
} from '@renofi/utils/src/lead';

import {setLead, setLoanProducts} from '../cache';

export default function (scenario) {
  const urlParams = new URLSearchParams(window.location.search);
  const starsParam = urlParams.get('stars');
  const loanAmountParam = urlParams.get('loanAmount');
  const optionsParam = urlParams.get('options');

  const {
    preferredProductMatch,
    loanProductMatches,
    loanProductMatchErrors,
    lead,
    leadPersonalAttributes,
    isPeak,
  } = scenario;
  const isProductSelected = size(preferredProductMatch);

  const products = isProductSelected
    ? [preferredProductMatch.product]
    : loanProductMatches?.map((item) => item.product) || [];
  const productMatches = isProductSelected
    ? [preferredProductMatch]
    : loanProductMatches;

  if (lead) {
    const {creditScore, loanAmountDesiredForRenovation, renovationCost} = lead;
    let productsHelpers = {};
    let productsWithCalculation = [];
    const amount =
      Number(loanAmountParam) ||
      loanAmountDesiredForRenovation ||
      renovationCost;

    if (products?.length) {
      const maxLoanAmounts = productMatches.map(
        (match) => match.offeredLoanAmountExcludingRefinance,
      );
      const maxLoanAmount = Math.max(...maxLoanAmounts);
      const maxAmountAvailable = toCurrency(
        Math.max(...products.map((option) => option.maxLoanAmount)),
      );

      const isLowCreditScore =
        Boolean(creditScore) &&
        products.every((option) => {
          const userScoreValue = getLeadCreditScore(lead);
          return userScoreValue <= option.minCreditScore;
        });

      const desireLoanAmountAvailable =
        amount <= maxLoanAmount * 1.2 && !isLowCreditScore;

      const loanAmountByProducts = desireLoanAmountAvailable
        ? amount
        : maxLoanAmount;

      productsWithCalculation = productMatches.map(
        ({product, ...otherValues}) => ({
          ...product,
          ...otherValues,
        }),
      );

      productsHelpers = {
        maxAmountAvailable,
        isLowCreditScore,
        desireLoanAmountAvailable,
        maxLoanAmount,
        loanAmountByProducts,
        ...(amount ? {loanAmountDesiredForRenovation: amount} : {}),
        lendersCount: isProductSelected
          ? 1
          : new Set(products?.map((p) => p.lender.name)).size,
      };
    }

    const noneEmptyLeadValues = omitBy(lead, (value) => isNull(value));

    const personalData = isObject(leadPersonalAttributes)
      ? leadPersonalAttributes
      : getPersonalLeadData(lead?.id);

    savePersonalLeadData(personalData, lead?.id);

    setLoanProducts(
      optionsParam
        ? Array(Number(optionsParam)).fill(productsWithCalculation[0])
        : productsWithCalculation,
    );
    setLead({
      ...noneEmptyLeadValues,
      ...productsHelpers,
      ...personalData,
      loanProductMatchErrors,
      haveMatches: productMatches?.length > 0,
      ...(starsParam ? {stars: Number(starsParam)} : {}),
      isPeak,
    });
  }
}
