/* eslint-disable max-lines */
import {cloneDeep, merge} from 'lodash';

import {RENOVATION_SCENARIO} from '@renofi/api';
import {setIn} from '@renofi/utils';

import USAllianceMock from './USAllianceProducts.mock';
import {LENDING_TREE_PRODUCTS} from './renovationScenario';

export const homeEquityProduct = {
  maxLoanAmountOffered: 250000,
  minMonthlyPayment: 1200,
  refinancedMonthlyMortgagePayments: 800,
  renovationFractionMinMonthlyPayment: 700,
  offeredLoanAmountExcludingRefinance: 140000,
  product: {
    allowedCombinations: null,
    cashDisbursementType: 'full_upfront',
    id: 'equity',
    interestOnly: false,
    isFirstLien: false,
    lender: {
      name: 'First Commonwealth FCU',
      code: 'fcfcu',
      id: '3',
    },
    lenderId: '3',
    maxArvCltv: 0.8,
    maxDti: 0.43,
    maxLoanAmount: 250000,
    maxPeakCltv: 1.25,
    maxPurchaseLtv: null,
    minCreditScore: 660,
    minLoanAmount: 25000,
    name: 'RenoFi Fixed Rate Home Equity',
    productType: 'renofi_home_equity',
    rateType: 'fixed',
    rates: [3.49, 4.24, 5.84],
    refinanceRequired: false,
    repaymentPenalty: false,
    repaymentPeriod: 5,
  },
};

export const helocProduct = {
  maxLoanAmountOffered: 500000,
  minMonthlyPayment: 1300,
  refinancedMonthlyMortgagePayments: 0,
  renovationFractionMinMonthlyPayment: 700,
  offeredLoanAmountExcludingRefinance: 140000,
  product: {
    allowedCombinations: null,
    cashDisbursementType: 'full_upfront',
    id: 'heloc',
    interestOnly: true,
    isFirstLien: false,
    lender: {
      name: 'Quorum FCU 1',
      code: 'quorum',
      id: '1',
    },
    lenderId: '1',
    maxArvCltv: 0.95,
    maxDti: 0.43,
    maxLoanAmount: 500000,
    maxPeakCltv: 1.25,
    maxPurchaseLtv: null,
    minCreditScore: 660,
    minLoanAmount: 25000,
    name: 'RenoFi HELOC',
    productType: 'renofi_heloc',
    rateType: 'variable',
    rates: [3.95, 5.49, 7.99],
    refinanceRequired: false,
    repaymentPenalty: false,
    repaymentPeriod: 20,
  },
};

const renoRefiProduct = {
  maxLoanAmountOffered: 650000,
  minMonthlyPayment: 1400,
  refinancedMonthlyMortgagePayments: 0,
  renovationFractionMinMonthlyPayment: 700,
  offeredLoanAmountExcludingRefinance: 140000,
  product: {
    allowedCombinations: null,
    cashDisbursementType: 'full_upfront',
    id: 'refi',
    interestOnly: false,
    isFirstLien: true,
    lender: {
      name: 'Quorum FCU',
      code: 'quorum',
      id: '1',
    },
    lenderId: '1',
    maxArvCltv: 0.8,
    maxDti: 0.43,
    maxLoanAmount: 650000,
    maxPeakCltv: 1,
    maxPurchaseLtv: null,
    minCreditScore: 660,
    minLoanAmount: 25000,
    name: 'RenoFi Refi',
    productType: 'renofi_cashout_refi',
    rateType: 'fixed',
    rates: [3.25],
    refinanceRequired: true,
    repaymentPenalty: false,
    repaymentPeriod: 30,
  },
};

const calculatorLoanProducts = [
  {
    ...homeEquityProduct.product,
    variants: [
      {
        id: '3',
        rates: [4.74, 5.49, 5.84],
        repaymentPeriod: 20,
      },
      {
        id: '4',
        rates: [4.24, 5.24, 5.84],
        repaymentPeriod: 15,
      },
      {
        id: '5',
        rates: [3.74, 4.99, 5.84],
        repaymentPeriod: 10,
      },
      {
        id: '6',
        rates: [3.49, 4.24, 5.84],
        repaymentPeriod: 5,
      },
    ],
  },
  {
    ...helocProduct.product,
    variants: [
      {
        id: '1',
        rates: [3.95, 5.49, 7.99],
        repaymentPeriod: 20,
      },
    ],
  },
  {
    ...renoRefiProduct.product,
    variants: [
      {
        id: '1001',
        rates: [3.25],
        repaymentPeriod: 30,
      },
    ],
  },
];

const loanPassMatch = {
  id: 1,
  productType: 'traditional_heloc',
  loanAmount: 300000,
  estimatedApr: 7.34,
  estimatedPayment: 1000,
  loanTermMonths: 120,
  interestOnlyPeriod: true,
  amortizationType: 'adjustable-rate',
};

export const lead = {
  calculatorToken: 'blah',
  additionalRenovationDetails: 'Additional info',
  brokerSourced: false,
  id: 'mockedToken',
  firstName: 'Bob',
  hasNewsletterConsent: false,
  creditScore: 'eleven',
  selfReportedCreditScore: 'ten',
  realCreditScoreNumber: 759,
  afterRenovatedValue: 400000,
  estimatedDownpayment: null,
  homeValue: 300000,
  hasRenovationEstimate: true,
  estimatedHomeValue: 310209,
  propertyPurchasePrice: null,
  firstMortgageBalance: 150000,
  firstMortgageRate: 4.99,
  loanAmount: 100000,
  loanAmountDesiredForRenovation: 160000,
  renovationCost: null,
  medal: 'platinum',
  stars: 1,
  state: 'Pennsylvania',
  tcpaConsentAt: '2020-06-08T07:12:30Z',
  createdAt: '2020-06-08T07:11:30Z',
  updatedAt: '2020-06-08T07:11:30Z',
  monthlyIncomeAlimony: null,
  monthlyIncomeChildSupport: null,
  monthlyDebt: 1800,
  estimatedDti: null,
  prequalStartedAt: null,
  prequalCompletedAt: null,
  prequalConditions: [],
  prequalFailures: [],
  prequalResult: 'SUCCESS',
  alimonyPayment: null,
  childSupportPayment: null,
  isLivingInPropertyDuringReno: null,
  loanSubject: 'renovate_current_home',
  alreadyStartedPropertyStateDetails: null,
  annualIncomePretax: 190000,
  creditCardPayment: null,
  hasLateMortgagePayments: null,
  primaryMortgagePayment: 400,
  propertyOwnershipStatus: 'owned',
  secondaryMortgagePayment: 900,
  secondMortgageBalance: 90000,
  personalLoanPayment: null,
  propertyStructureType: null,
  propertyUseType: null,
  recentBankruptcy: null,
  renovationIdealStart: null,
  renovationPerformedByType: null,
  selfReportedOriginType: null,
  selfReportedOriginDetails: null,
  studentLoanPayment: null,
  studentLoanBalance: null,
  studentLoanPrePandemicPayment: null,
  isSubcontractingMany: null,
  isLicensedContractor: null,
  inLicensedState: true,
  vehiclePayment: null,
  totalMortgageBalance: 24000,
  estimatedProjectSize: 'large',
  creditReportPreparedAt: null,
  equifaxScore: null,
  experianScore: null,
  transunionScore: null,
  sourceLenderCode: null,
  utmCampaign: null,
  utmContent: null,
  utmMedium: null,
  utmSource: null,
  utmTerm: null,
  leadExternalOriginType: null,
  demolishExistingStructure: null,
  preExistingStructuresOnLandPresent: null,
  anythingLeftFromOriginalStructure: null,
  teardownOfExistingStructure: null,
  subcontractorsSecured: true,
  dashboardLastVisitedAt: null,
  updatedByUserAt: null,
  contractorRequestedAt: null,
  leadSourceCrmName: null,
  borrowerRegistrationCompletedAt: null,
  convertedAt: null,
  experimentalId: '0c99251c-1ad6-4a8c-898a-d5640f5ff86e',
};

const leadPersonalAttributes = {
  firstName: 'James',
  lastName: 'Bond',
  email: 'james@renofi.dev',
  phone: '4845219623',
  city: 'Los Angeles',
  streetAddress: '123 RenoFi Street',
  county: 'California',
  zipCode: '90210',
  lat: '90.987987',
  lng: '34.96879',
};

const crmOwner = {
  firstName: 'Mark',
  lastName: 'Advisorski',
  nmlsId: '123',
  phoneNumber: '9879879877',
  photoUrl: 'https://ucarecdn.com/24e60990-1ea0-46eb-a0a2-2151a0a551dd/',
};

export const usAllianceMock = {
  data: {
    result: {
      crmOwner,
      lead,
      recommendedLender: {
        name: 'USALLIANCE Financial',
        code: 'us_alliance',
        id: 'us_alliance',
      },
      preferredLender: null,
      calculatorLoanProducts: [
        USAllianceMock.renofiHeloc,
        USAllianceMock.renofiHomeEquity,
      ],
      loanProductMatches: [],
      loanPassMatches: [loanPassMatch],
      loanProductMatchErrors: [],
      isPeak: true,
      preferredProductMatch: null,
      positionedInterestRate: 9.25,
    },
  },
};

export const homeEquityMock = {
  data: {
    result: {
      crmOwner,
      leadPersonalAttributes: null,
      lead,
      recommendedLender: {
        logoUrl:
          'https://assets.renofi.com/logo-ardent-1aed062bcae53958ea4dabcb63d58291.svg',
        name: 'Ardent Credit Union',
        code: 'ardent',
      },
      preferredLender: null,
      calculatorLoanProducts,
      loanProductMatches: [homeEquityProduct, helocProduct, renoRefiProduct],
      loanPassMatches: [loanPassMatch],
      loanProductMatchErrors: [],
      isPeak: true,
      positionedInterestRate: 9.25,
      preferredProductMatch: null,
    },
  },
};

export const helocMock = {
  data: {
    result: {
      crmOwner,
      lead,
      leadPersonalAttributes: null,
      recommendedLender: {
        logoUrl: null,
        name: 'Quorum FCU',
        code: 'quorum',
      },
      preferredLender: null,
      calculatorLoanProducts: [
        calculatorLoanProducts[1], // heloc
        calculatorLoanProducts[0], // equity
        calculatorLoanProducts[2], // refi
      ],
      loanProductMatches: [helocProduct, homeEquityProduct, renoRefiProduct],
      loanPassMatches: [loanPassMatch],
      loanProductMatchErrors: [],
      isPeak: true,
      preferredProductMatch: null,
      positionedInterestRate: 9.25,
    },
  },
};

export const refiMock = {
  data: {
    result: {
      crmOwner,
      leadPersonalAttributes: null,
      lead,
      recommendedLender: {
        logoUrl: null,
        name: 'Quorum FCU',
        code: 'quorum',
      },
      preferredLender: null,
      calculatorLoanProducts: [...calculatorLoanProducts].reverse(),
      loanProductMatches: [renoRefiProduct, helocProduct, homeEquityProduct],
      loanPassMatches: [loanPassMatch],
      loanProductMatchErrors: [],
      isPeak: true,
      preferredProductMatch: null,
      positionedInterestRate: 9.25,
    },
  },
};

export const completedHelocMock = setIn(
  helocMock,
  'data.result.lead.prequalCompletedAt',
  '2020-05-27T20:28:49Z',
);

export const authenticatedHelocMock = setIn(
  helocMock,
  'data.result.leadPersonalAttributes',
  leadPersonalAttributes,
);

export const gcReferralMock = merge(cloneDeep(helocMock), {
  data: {
    result: {
      lead: {
        utmSource: 'jest-test',
        utmMedium: 'partner-site',
      },
    },
  },
});

const mock = (mock, fetchBy = 'token', query = RENOVATION_SCENARIO) => ({
  request: {
    query,
    variables: {
      [fetchBy]: 'mockedToken',
    },
  },
  result: mock,
});

export default {
  gcReferral: mock(gcReferralMock, 'id'),
  heloc: mock(helocMock),
  helocById: mock(helocMock, 'id'),
  authenticatedHelocById: mock(authenticatedHelocMock, 'id'),
  completedHelocById: mock(completedHelocMock, 'id'),
  homeEquity: mock(homeEquityMock),
  refi: mock(refiMock),
  empty: mock({data: {result: null}}),
  loanPass: mock(
    {data: {result: {loanPassMatches: [loanPassMatch]}}},
    'id',
    LENDING_TREE_PRODUCTS,
  ),
};
