import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import copyBg from './img/copyBg.svg';

export const AdvisorText = styled(Text)(
  mediaQuery({
    width: 230,
    height: 80,
    padding: '19px 0 0 40px',
    textAlign: 'left',
    lineHeight: '19px',
    background: `url(${copyBg}) no-repeat`,
  }),
);
