import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export default styled(Text)(
  mediaQuery({
    fontWeight: 400,
  }),
  ({small, xsmall, left, center, css}) =>
    mediaQuery({
      position: 'relative',
      fontSize: small ? [24, 32] : xsmall ? [20, 20] : [24, 40],
      lineHeight: small
        ? ['32px', '40px']
        : xsmall
          ? ['32px', '38px']
          : ['32px', '49px'],
      textAlign: center ? 'center' : left ? 'left' : '',
      marginBottom: 32,
      ...css,
    }),
);
