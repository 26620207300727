import {useState, useEffect} from 'react';

import {isNil} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {usePrevious} from '@renofi/utils';

import {progressVar} from '../api/cache';

export default function usePosition() {
  const {locationIndex} = useReactiveVar(progressVar);
  const prevIndex = usePrevious(locationIndex);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (!isNil(prevIndex) && locationIndex !== prevIndex) {
      const movingForward = locationIndex > prevIndex;
      setPosition(position + (movingForward ? 1 : -1));
    }
  }, [locationIndex, prevIndex]);

  return position;
}
