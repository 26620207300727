import styled from '@emotion/styled/macro';
import {Flex, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {CloudsIcon} from '@renofi/components';

export const Container = styled(Flex)(
  mediaQuery({
    overflow: 'hidden',
    position: 'relative',
    minHeight: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    transition: ['none', 'background-position-x 1s .10s'],
    backgroundImage: ['none', `url(${CloudsIcon})`],
    backgroundColor: '#F4FBFD',
    backgroundRepeat: 'repeat-x',
    backgroundPositionY: 40,
  }),
  ({bgXPos}) => ({
    backgroundPositionX: bgXPos,
  }),
);

export const Wrapper = styled(Box)(
  mediaQuery({
    position: 'relative',
    display: ['block', 'flex'],
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    margin: ['0', 'auto'],
    padding: ['0 15px 0 15px', '0 25px'],
  }),
  ({width}) =>
    mediaQuery({
      width: '100%',
      maxWidth: ['100%', width + 50 || 960],
    }),
);
