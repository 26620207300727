import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {projectSize as enums} from '@renofi/api/src/enums';
import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as Small} from './img/small.svg';
import {ReactComponent as Medium} from './img/medium.svg';
import {ReactComponent as Large} from './img/large.svg';

const options = [
  {
    // prettier-ignore
    title: <><strong>Small</strong><br />under $35k</>,
    value: enums.small,
    icon: Small,
  },
  {
    // prettier-ignore
    title: <><strong>Medium</strong><br />$35 - $100k</>,
    value: enums.medium,
    icon: Medium,
  },
  {
    // prettier-ignore
    title: <><strong>Large</strong><br />$100k+</>,
    value: enums.large,
    icon: Large,
  },
];

const projectSizeMap = {
  [enums.small]: 20000,
  [enums.medium]: 50000,
  [enums.large]: 150000,
};

function ProjectSize({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {estimatedProjectSize} = lead;

  useEffect(() => sendEvent('POS/Project-Size-Page'), []);

  function onSubmit(estimatedProjectSize) {
    const renovationCost = projectSizeMap[estimatedProjectSize];
    const values = {
      renovationCost,
      estimatedProjectSize,
      loanAmountDesiredForRenovation: renovationCost,
    };

    setLead(values);
    updateScenario(values);

    sendEvent('POS/Project-Size-Entered', {estimatedProjectSize});
    next('/credit-score');
  }

  return (
    <>
      <Heading>What’s the size of your project?</Heading>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={estimatedProjectSize}
      />
      <Buttons
        disableNext={isNil(estimatedProjectSize)}
        onNext={() => onSubmit(estimatedProjectSize)}
        value={estimatedProjectSize}
      />
    </>
  );
}

export default ProjectSize;
