import styled from '@emotion/styled/macro';
import {ToastContainer as ToastComponent} from 'react-toastify';
import './animations.css';

export const ToastContainer = styled(ToastComponent)({
  '--toastify-toast-width': 'auto',
  '.Toastify__toast-icon': {
    width: 36,
    marginRight: 10,
  },
  '.Toastify__toast': {
    backgroundColor: '#404040',
    color: 'white',
    fontSize: 16,
    fontWeight: 300,
    padding: '10px 12px 10px 10px',
    borderRadius: 8,
  },
  '&&.Toastify__toast-container--bottom-center': {
    bottom: '1em',
    left: '1em',
    transform: 'none',
  },
});
