import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

export const AlertText = styled.p(
  mediaQuery({
    margin: '3px 0',
    textAlign: 'left',
  }),
);
