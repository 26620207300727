import React, {useState} from 'react';

import {InfoBlueIcon, InfoIcon, Button} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Tooltip, TooltipContent} from '../../components';

const TeardownInfo = () => {
  const {isMobile} = useScreenSize();
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Button
        css={{maxWidth: 50, padding: 0, height: 'auto', margin: '5px 0 0 5px'}}
        transparent
        tabIndex={-1}
        clicked={isMobile ? clicked : null}
        data-tip
        data-for="teardown-tip">
        <InfoBlueIcon />
      </Button>

      <Tooltip
        css={{maxWidth: isMobile ? 250 : 295}}
        place={isMobile ? 'left' : 'right'}
        event={isMobile ? 'click' : null}
        afterHide={() => setClicked(false)}
        afterShow={() => setClicked(true)}
        offset={{right: isMobile ? 15 : 0}}
        delayHide={100}
        id="teardown-tip">
        <TooltipContent icon={InfoIcon}>
          In this context the primary structure and/or foundation requires
          modification and this will be removed and replaced with a new
          foundation and structure.
        </TooltipContent>
      </Tooltip>
    </>
  );
};

export default TeardownInfo;
