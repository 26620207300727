import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {useRenovationUtils} from '@renofi/utils';
import {land as landEnums} from '@renofi/api/src/enums';
import {sendEvent} from '@renofi/analytics';

import {Buttons, Heading, Slider} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';

const {owned} = landEnums;

function HomeValue({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {homeValue = 10000, propertyOwnershipStatus} = useReactiveVar(leadVar);
  const {isHomeBuild} = useRenovationUtils(lead);

  useEffect(() => sendEvent('POS/Home-Value-Page'), []);

  function onChange(value) {
    setLead({homeValue: value});
  }

  function onSubmit() {
    onChange(homeValue);
    updateScenario({homeValue});
    sendEvent('POS/Home-Value-Entered', {homeValue});
    let path = '/after-renovation-value';
    if (isHomeBuild && propertyOwnershipStatus === owned)
      path = '/construction/mortgage-balance';
    next(path);
  }

  return (
    <>
      <Heading>
        {isHomeBuild
          ? 'Estimated current value of the property'
          : 'My estimated current home value is about'}
      </Heading>
      <Slider
        infinite
        noTooltip
        min={10000}
        max={2000000}
        step={10000}
        onChange={onChange}
        value={homeValue}
      />
      <Buttons onNext={onSubmit} disableNext={homeValue < 10001} />
    </>
  );
}

export default HomeValue;
