import React from 'react';

import PropTypes from 'prop-types';

import {useScreenSize} from '@renofi/utils';

import {StyledTooltip} from './styled';

const Tooltip = ({children, id, css, place, ...props}) => {
  const {isMobile} = useScreenSize();
  return (
    <StyledTooltip
      openOnClick={isMobile}
      globalCloseEvents={{scroll: true, clickOutsideAnchor: true}}
      opacity={1}
      css={css}
      place={place || 'top'}
      effect="solid"
      id={id}
      backgroundColor="white"
      className="react-tooltip"
      {...props}>
      {children}
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
  place: PropTypes.string,
  id: PropTypes.string,
};

export default Tooltip;
