import styled from '@emotion/styled';
import {Flex, Box, Text} from 'rebass';

import {TextField, PhoneField} from '@renofi/components';
import {mediaQuery} from '@renofi/utils';

export const InnerWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    padding: ['30px 0 0 0', '30px 44px'],
    alignItems: 'center',
  }),
);

export const Heading = styled(Text)(() =>
  mediaQuery({
    fontSize: ['24px', '28px'],
    lineHeight: ['36px', '42px'],
    color: '#212F49',
    textAlign: 'center',
  }),
);
export const SubHeading = styled(Text)(() =>
  mediaQuery({
    fontSize: ['14px', '18px'],
    lineHeight: ['20px', '24px'],
    color: '#212F49',
    textAlign: 'center',
    padding: '20px 0 10px 0',
  }),
);

export const FieldWrapper = styled(Box)(({half, left}) =>
  mediaQuery({
    width: half ? ['100%', '50%'] : '100%',
    marginTop: '24px',
    marginRight: left ? 'auto' : '0',
  }),
);

export const Field = styled(TextField)({
  boxShadow: '0px 0px 7px rgba(0, 160, 255, 0.1)',
});

export const PhoneNumberField = styled(PhoneField)({
  boxShadow: '0px 0px 7px rgba(0, 160, 255, 0.1)',
});

export const ButtonsWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: ['column', 'row'],
    marginTop: 36,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: ['center', 'stretch'],
  }),
);

export const DisclaimerWrapper = styled(Box)({
  backgroundColor: '#E5F5FF',
  padding: '8px 12px',
  borderRadius: 8,
  color: '#006099',
  fontStyle: 'italic',
  textAlign: 'center',
  marginTop: 21,
});
