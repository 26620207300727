import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box, Link} from 'rebass';

import {darkBlue} from '@renofi/utils';

import Checkbox from '../Checkbox';
import Label, {CheckboxLabel} from '../Label';

const PrivacyCheckbox = ({
  id = 'agree',
  name = 'agree',
  onChange,
  value,
  color,
  children,
}) => {
  return (
    <Flex alignItems="center">
      <Label css={{cursor: 'pointer'}}>
        <Box minWidth={32}>
          <Checkbox
            checked={Boolean(value)}
            id={id}
            name={name}
            onChange={() => onChange(!value)}
          />
        </Box>
        {!children && (
          <CheckboxLabel color={color}>
            I agree to{' '}
            <Link
              color={color || darkBlue}
              fontSize={14}
              target="_blank"
              href="https://www.renofi.com/notices/legal">
              Terms of Service
            </Link>{' '}
          </CheckboxLabel>
        )}
        {children}
      </Label>
    </Flex>
  );
};

PrivacyCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  color: PropTypes.string,
};

export default PrivacyCheckbox;
