import {useEffect} from 'react';

import {isEmpty} from 'lodash';

import {constants} from '@renofi/utils';

import {UTM_COOKIE_KEYS, SR_LOAN_OFFICERS} from '../const';

import useUTMTags from './useUTMTags';

const useSrLO = () => {
  const {utmTags, persistUTMTags} = useUTMTags(UTM_COOKIE_KEYS.slo);

  useEffect(() => {
    if (utmTags.medium === 'sr-lo' || utmTags.medium === 'lo-b2b2c') {
      persistUTMTags(() => constants.HUNDRED_YEARS_IN_DAYS);
    }
  }, [utmTags.medium]);

  if (
    isEmpty(utmTags) ||
    !utmTags.source ||
    !SR_LOAN_OFFICERS[utmTags.source]
  ) {
    return null;
  }

  return SR_LOAN_OFFICERS[utmTags.source];
};

export default useSrLO;
