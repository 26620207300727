import React, {cloneElement, forwardRef, useRef} from 'react';

import PropTypes from 'prop-types';

import {useClickOutside} from '@renofi/utils';

const ClickOutside = forwardRef(
  ({children, passRef = true, onClickOutside}, ownRef) => {
    const newRef = useRef(null);
    const [ref] = useClickOutside(onClickOutside, ownRef || newRef);
    return <>{passRef ? cloneElement(children, {ref}) : children}</>;
  },
);

ClickOutside.propTypes = {
  passRef: PropTypes.bool,
  children: PropTypes.element.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

export default ClickOutside;
