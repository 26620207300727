import React from 'react';

import {Text, Image} from 'rebass';

import {lightGray, gray} from '@renofi/utils/src/colors';

import {IconSocialProof} from './styled';
import {AsSeenOnIconsSetTwo} from './images';

function AsSeenOn() {
  return (
    <IconSocialProof>
      <Text width="100%" fontWeight="bold" color={gray}>
        Over $1 billion funded
      </Text>
      <Text width="100%" mb="30px" color={lightGray}>
        And counting.
      </Text>
      <Image src={AsSeenOnIconsSetTwo} width="60%" />
    </IconSocialProof>
  );
}

export default AsSeenOn;
