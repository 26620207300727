import React, {useEffect, useRef, useState} from 'react';

import {noop} from 'lodash';
import PropTypes from 'prop-types';
import {PatternFormat} from 'react-number-format';

import {useAutofocus} from '@renofi/utils';
import {red} from '@renofi/utils/src/colors';

import {
  IconWrapper,
  Input,
  InputWrapper,
  TopMessage,
} from '../TextField/styled';
import {Message} from '../TextField';

const PhoneField = ({
  onChange = noop,
  onBlur = noop,
  onClickIcon = noop,
  value,
  autofocus,
  icon,
  iconCss,
  iconPosition,
  topMessage,
  topMessageColor,
  error,
  required,
  isDirty = false,
  stripCountryCode = false,
  css,
  ...props
}) => {
  const [dirty, setDirty] = useState(isDirty);
  const ref = useRef();
  const showRequiredMessage = dirty && required && !value;
  const showError = error || showRequiredMessage;

  useEffect(() => {
    let number = value;
    if (stripCountryCode) {
      number = String(value).replace(/^\+1/, '');
    }
    const numbersOnly = String(number).replace(/\D/g, '');
    if (numbersOnly.length > 10) {
      onChangeValue({target: {value: ''}});
    } else if (stripCountryCode) {
      onChangeValue({target: {value: numbersOnly}});
    }
  }, [value]);

  useAutofocus(autofocus, ref, value);

  function onChangeValue(event) {
    setDirty(true);
    const {value} = event.target;
    const numbersOnly = value.replace(/\D/g, '');
    onChange(numbersOnly.slice(0, 10));
  }

  return (
    <InputWrapper
      css={css}
      error={error}
      xLarge={props.xLarge}
      iconPosition={iconPosition}>
      {Boolean(icon) ? (
        <IconWrapper
          error={error}
          icon="phone"
          iconCss={iconCss}
          iconPosition={iconPosition}
          onClick={onClickIcon}
          small={props.small}
          xLarge={props.xLarge}
        />
      ) : null}

      <PatternFormat
        getInputRef={ref}
        data-testid="phone-field"
        value={value}
        onChange={onChangeValue}
        onBlur={onBlur}
        format="(###) ###-####"
        thousandSeparator={true}
        mask="_"
        icon={icon}
        allowEmptyFormatting
        customInput={Input}
        skipValidation
        {...props}
        error={error || showRequiredMessage || null}
      />
      {topMessage ? (
        <TopMessage color={topMessageColor}>{topMessage}</TopMessage>
      ) : null}
      {showError && (
        <Message color={red}>{error || 'This is required'}</Message>
      )}
    </InputWrapper>
  );
};

PhoneField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  icon: PropTypes.bool,
  iconCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  iconPosition: PropTypes.string,
  value: PropTypes.string,
  autofocus: PropTypes.bool,
  topMessage: PropTypes.string,
  topMessageColor: PropTypes.string,
  xLarge: PropTypes.bool,
  required: PropTypes.bool,
  isDirty: PropTypes.bool,
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default PhoneField;
