import React from 'react';

import PropTypes from 'prop-types';

import {useHandleEnterPress} from '@renofi/utils';
import {Button} from '@renofi/components';

const NextButton = ({children, handleEnter, ...props}) => {
  const shouldHandleEnter = handleEnter && !props.disabled;
  useHandleEnterPress(shouldHandleEnter, props.onClick);
  return (
    <Button width="auto" {...props}>
      {children}
    </Button>
  );
};

NextButton.propTypes = {
  children: PropTypes.node,
  handleEnter: PropTypes.bool,
};

export default NextButton;
