import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';
import {useFeatureFlagVariantEnabled} from '@renofi/utils';
import {useFlag} from '@renofi/utils/src/feature-flags';
import {isFakeLead, isIntroducedByContractor} from '@renofi/utils/src/lead';

import {useNext} from '../../hooks';
import {Heading} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

function ContractorSecured({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {subcontractorsSecured} = lead;
  const isFindContractorEnabled = useFeatureFlagVariantEnabled(
    'pos-find-contractor',
  );

  const isDashboardSignUpEnabled = useFlag('dashboard-sign-up');

  const gcMatchEnabled =
    isFindContractorEnabled &&
    !isDashboardSignUpEnabled &&
    !isFakeLead(lead) &&
    !isIntroducedByContractor(lead);

  useEffect(() => sendEvent('POS/Contractor-Secured-Page'), []);

  function onSubmit(newValue) {
    setLead({subcontractorsSecured: newValue});
    updateScenario({subcontractorsSecured: newValue});

    sendEvent('POS/Contractor-Secured-Entered', {
      subcontractorsSecured: newValue,
    });

    const path = gcMatchEnabled ? '/contractors/find' : '/plans';
    next(path);
  }

  return (
    <>
      <Heading>Have you secured a contractor to do the work?</Heading>
      <YesNoSelect value={subcontractorsSecured} onSubmit={onSubmit} />
    </>
  );
}

ContractorSecured.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default ContractorSecured;
