import {useEffect} from 'react';

import {
  useUTMTags,
  useDebounce,
  constants,
  calculatePeakCLTV,
  getLeadCreditScore,
} from '@renofi/utils';

const useUpdateUnleashContext = (updateContext, lead = {}, lazy = true) => {
  const {
    id,
    isLowCreditScore,
    stars,
    loanAmount,
    loanAmountDesiredForRenovation,
    maxLoanAmount,
    haveMatches,
    loanSubject,
    inLicensedState,
    subcontractorsSecured,
    renovationCost,
    hasRenovationEstimate,
    state,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
    experimentalId,
  } = lead;

  let utmTags;
  const {utmTags: browserUtmTags} = useUTMTags(
    constants.UTM_COOKIE_KEYS.renofi,
  );

  if (utmSource || utmMedium) {
    utmTags = {
      source: utmSource,
      medium: utmMedium,
      campaign: utmCampaign,
      content: utmContent,
      term: utmTerm,
    };
  } else {
    utmTags = {...browserUtmTags};
  }
  const debouncedLoanAmount = useDebounce(loanAmount, 1000);
  const debouncedLoanAmountDesiredForRenovation = useDebounce(
    loanAmountDesiredForRenovation,
    1000,
  );
  const debouncedPeakCltv = useDebounce(calculatePeakCLTV(lead), 1000);
  const debouncedCreditScore = useDebounce(getLeadCreditScore(lead), 1000);
  const debouncedRenovationCost = useDebounce(renovationCost, 1000);

  const performUpdate = async (fields = {}) => {
    await updateContext({
      utmSource: utmTags.source,
      utmMedium: utmTags.medium,
      utmCampaign: utmTags.campaign,
      utmContent: utmTags.content,
      utmTerm: utmTags.term,
      userId: id,
      loanAmount,
      loanAmountDesiredForRenovation,
      peakCltv: calculatePeakCLTV(lead),
      creditScore: getLeadCreditScore(lead),
      renovationCost,
      hasRenovationEstimate,
      isLowCreditScore,
      stars,
      maxLoanAmount,
      haveMatches,
      loanSubject,
      inLicensedState,
      subcontractorsSecured,
      state,
      posCreditScoreVisited: Boolean(getLeadCreditScore(lead)),
      experimentalId,
      ...fields,
    });
  };

  useEffect(() => {
    if (lazy) {
      performUpdate();
    }
  }, [
    utmTags?.source,
    utmTags?.medium,
    utmTags?.campaign,
    utmTags?.content,
    utmTags?.term,
    id,
    debouncedLoanAmount,
    debouncedLoanAmountDesiredForRenovation,
    debouncedPeakCltv,
    debouncedCreditScore,
    debouncedRenovationCost,
    isLowCreditScore,
    stars,
    maxLoanAmount,
    haveMatches,
    loanSubject,
    inLicensedState,
    subcontractorsSecured,
    state,
    hasRenovationEstimate,
    experimentalId,
  ]);

  return performUpdate;
};

export default useUpdateUnleashContext;
