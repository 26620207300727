import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {setAnalyticsIdentify} from '@renofi/analytics';

import {UPDATE_SCENARIO} from '../mutations/updateScenario';

export default function useUpdateScenario({onCompleted = noop} = {}) {
  const [updateScenario, {data, loading, error}] = useMutation(
    UPDATE_SCENARIO,
    {
      onCompleted: ({updateScenario}) => {
        if (updateScenario.lead) {
          setAnalyticsIdentify(updateScenario.lead, {
            loanProducts: updateScenario.loanProductMatches,
          });
        }
        onCompleted(updateScenario);
      },
    },
  );
  const response = data?.updateScenario;
  return {updateScenario, response, loading, error};
}
