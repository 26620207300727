import {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import {redirectToLogin, useFeatureFlagVariantEnabled} from '@renofi/utils';
import {
  Label,
  TextField,
  SubscribeCheckbox,
  PrivacyCheckbox,
  LockIcon,
  CloudflareIcon,
} from '@renofi/components';
import {isEmail} from '@renofi/utils/src/validate';
import {gray} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';
import {useEmailValidation} from '@renofi/api';
import {
  isLeadValidForCreation,
  savePersonalLeadData,
} from '@renofi/utils/src/lead';

import {
  Heading,
  Buttons,
  Narrow,
  TooltipContent,
  TextFieldTooltip,
  IconsSocialProofWrapper,
  GoogleReview,
  FeatureReview,
  AsSeenOn,
  FundingSmall,
  SubHeading,
  AvailableOptionsAlert,
  AvailableOptionsAlertV2,
} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext, useCreateLead} from '../hooks';

import {CloudFlareWrapper} from './styled';

function Email({updateScenario}) {
  const [loading, setLoading] = useState(false);
  const [onlineValidationSuccess, setOnlineValidationSuccess] = useState(null);
  const lead = useReactiveVar(leadVar);
  const createLead = useCreateLead();
  const history = useHistory();
  const next = useNext();
  const {email, hasNewsletterConsent, terms} = lead;
  const validate = useEmailValidation();

  const piiScreenAvailableOptionsV1Enabled = useFeatureFlagVariantEnabled(
    'pos-pii-screen-available-options',
    'v1-loanpass-data',
  );

  const piiScreenAvailableOptionsV2Enabled = useFeatureFlagVariantEnabled(
    'pos-pii-screen-available-options',
    'v2-hardcoded-random',
  );

  useEffect(() => sendEvent('POS/Lead-Email-Page'), []);

  function onChange(key, value) {
    setLead({[key]: value});
    setOnlineValidationSuccess(null);
  }

  function redirect() {
    sendEvent('POS/Redirect-To-Login');
    redirectToLogin();
  }

  async function onSubmit() {
    if (!isLeadValidForCreation(lead)) {
      history.push('/loan-type');
      return;
    }
    setLoading(true);

    try {
      const {isEmailValid, isOpenOpportunity, errors} = await validate(email);

      if (!errors.validationError && !isEmailValid) {
        setLoading(false);
        setOnlineValidationSuccess(false);
        return;
      }

      if (isOpenOpportunity) return redirect();
    } catch (error) {
      Sentry.captureException(new Error('Lead submission error'), {
        extra: {error, lead},
      });
      onSubmit(); // try again
    }

    const rsp = await createLead();
    const id = rsp?.lead?.id || lead?.id;

    savePersonalLeadData(lead, id);
    updateScenario({...lead, id});

    sendEvent('POS/Lead-Email-Entered', {email});
    setLoading(false);

    next('/phone');
  }

  return (
    <>
      {(piiScreenAvailableOptionsV1Enabled ||
        piiScreenAvailableOptionsV2Enabled) && (
        <>
          {piiScreenAvailableOptionsV1Enabled && <AvailableOptionsAlert />}
          {piiScreenAvailableOptionsV2Enabled && <AvailableOptionsAlertV2 />}
          <Heading>Save your progress</Heading>
          <SubHeading>
            We need your email address to save your progress.
          </SubHeading>
        </>
      )}
      {!piiScreenAvailableOptionsV1Enabled &&
        !piiScreenAvailableOptionsV2Enabled && (
          <>
            <Heading>My email address is</Heading>
            <SubHeading>
              We never share your email address and we don't send spam.
            </SubHeading>
          </>
        )}
      <Narrow>
        <Label small htmlFor="email">
          Email address
        </Label>

        <Box css={{position: 'relative'}}>
          <TextField
            topMessage="Never shared."
            topMessageColor={gray}
            xLarge
            css={{boxShadow: '0px 0px 7px 0px #00A0FF1A'}}
            required
            email
            error={
              onlineValidationSuccess === false
                ? 'Please enter a valid email address.'
                : ''
            }
            autofocus
            mt="5px"
            value={email}
            onChange={(value, err) => onChange('email', value, err)}
            id="email"
            name="email"
            type="text"
          />
          <TextFieldTooltip css={{maxWidth: '100%!important'}}>
            <TooltipContent icon={LockIcon}>
              We value your privacy. We are committed to securely storing all
              customer data and will never share it with unauthorized parties.
              <CloudFlareWrapper>
                <CloudflareIcon />
              </CloudFlareWrapper>
            </TooltipContent>
          </TextFieldTooltip>
        </Box>

        <Box width={[1, 1]} mt={36}>
          <SubscribeCheckbox
            color={gray}
            onChange={onChange}
            value={hasNewsletterConsent}
          />
          <PrivacyCheckbox
            color={gray}
            onChange={(value) => onChange('terms', value)}
            value={Boolean(terms)}
          />
        </Box>

        <Buttons
          loading={loading}
          fullWidth
          hideBack
          onNext={onSubmit}
          disableNext={!email || !terms || !isEmail(email) || loading}
        />
      </Narrow>
      <IconsSocialProofWrapper>
        <GoogleReview
          reviewerName="Nick Veronick"
          review="I was able to do the project at least 12-18 months sooner thanks to their offerings."
        />
        <FeatureReview />
        <FundingSmall />
        <AsSeenOn />
      </IconsSocialProofWrapper>
    </>
  );
}

Email.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default Email;
