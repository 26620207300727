import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export default styled(Text)(({css}) =>
  mediaQuery({
    textAlign: 'left',
    fontStyle: 'italic',
    color: gray,
    ...css,
  }),
);
