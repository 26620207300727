import styled from '@emotion/styled/macro';
import {Image as RebassImage, Button as RebassButton} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Image = styled(RebassImage)({}, ({left}) => ({
  transform: left ? 'scaleX(-1)' : 'none',
}));

export const Button = styled(RebassButton)(
  mediaQuery({
    background: 'white',
    boxShadow: '0px 2px 4px #00000030',
    position: 'absolute',
    top: 'calc(50% - 20px)',
    display: ['none', 'flex'],
    borderRadius: 100,
    width: 40,
    height: 40,
    cursor: 'pointer',
    alignItems: 'center',
    paddingLeft: 14,
    ':hover': {
      background: '#F9F9F9',
    },
    ':focus': {
      outline: 'none',
    },
    ':active': {
      boxShadow: '0px 1px 2px #00000030',
    },
  }),
  ({left}) => ({
    left: left ? '-70px' : 'none',
    right: left ? 'none' : '-70px',
  }),
);
