import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {isNil, noop} from 'lodash';

import {IconsWrapper} from './styled';
import IconSelectItem from './IconSelectItem';

const IconSelect = ({
  options,
  onClick = noop,
  isSelected,
  value,
  vertical,
  css,
}) => {
  const [selected, setSelected] = useState();

  if (!options.length) return null;

  function onSelect(value) {
    if (selected) return;

    let newValues = value;
    setSelected(newValues);
    onClick(newValues);
  }

  function getSelected(option) {
    if (isNil(selected)) return compare(option, value);
    return compare(option, selected);
  }

  function compare(option, comparator) {
    if (typeof value === 'string') return option.value.includes(comparator);
    return option.value === comparator;
  }

  return (
    <IconsWrapper vertical={vertical}>
      {options.map((option) => (
        <div key={String(option.value)}>
          <IconSelectItem
            css={css}
            vertical={vertical}
            selected={
              isSelected ? isSelected(option, compare) : getSelected(option)
            }
            {...option}
            onClick={onSelect}
          />
        </div>
      ))}
    </IconsWrapper>
  );
};

IconSelect.propTypes = {
  vertical: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.func,
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default IconSelect;
