import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as ClockIcon} from './img/clock.svg';
import {ReactComponent as SchedulesIcon} from './img/schedules-blue.svg';
import {ReactComponent as PlusIcon} from './img/schedules-plus.svg';

const options = [
  {
    title: 'Immediately after closing',
    value: 'immediately',
    icon: ClockIcon,
  },
  {
    title: 'Within a few months after close',
    value: 'a_few_months',
    icon: SchedulesIcon,
  },
  {
    title: 'After a few months after close',
    value: 'more',
    icon: PlusIcon,
  },
];

function RenovationStartAfterClosing() {
  const next = useNext();
  const {renovationIdealStartAfterPurchase} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Renovation-Start-After-Closing-Page'), []);

  function onSubmit(renovationIdealStartAfterPurchase) {
    setLead({renovationIdealStartAfterPurchase});

    sendEvent('POS/Renovation-Start-After-Closing-Entered', {
      renovationIdealStartAfterPurchase,
    });
    next();
  }

  return (
    <>
      <Heading>
        When do you plan to renovate after closing the home purchase?
      </Heading>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={renovationIdealStartAfterPurchase}
      />
      <Buttons
        disableNext={isNil(renovationIdealStartAfterPurchase)}
        onNext={() => onSubmit(renovationIdealStartAfterPurchase)}
        value={renovationIdealStartAfterPurchase}
      />
    </>
  );
}

export default RenovationStartAfterClosing;
