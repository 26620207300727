import {useQuery} from '@apollo/client';

import {VALIDATE_PHONE_NUMBER} from '../queries/validatePhoneNumber';

export default function useValidatePhoneNumber() {
  const {refetch} = useQuery(VALIDATE_PHONE_NUMBER, {skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
