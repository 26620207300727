import React, {useEffect, useRef, useState} from 'react';

import {noop} from 'lodash';
import PropTypes from 'prop-types';
import {NumericFormat} from 'react-number-format';

import {useAutofocus} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {red} from '@renofi/utils/src/colors';

import {
  IconWrapper,
  Input,
  InputWrapper,
  Message,
  TopMessage,
} from '../TextField/styled';

const CurrencyField = ({
  onChange = noop,
  onBlur = noop,
  onClickIcon = noop,
  value,
  autofocus,
  topMessage,
  errMessages,
  errorCss,
  topMessageColor,
  icon,
  iconPosition,
  min,
  max,
  iconCss,
  analyticsEnabled,
  analyticsPrefix,
  name,
  wrapperCss,
  prefix,
  disabled,
  ...props
}) => {
  const [stateError, setStateError] = useState();
  const ref = useRef();
  const [dirty, setDirty] = useState(Boolean(value));

  useAutofocus(autofocus, ref);

  useEffect(() => {
    if (value === 0) return;
    const {err, validatedValue} = validate(value);
    onChange(validatedValue, err);
  }, [dirty, value]);

  function validate(newValue) {
    let err = undefined;

    if (min && newValue < min) {
      err = errMessages?.min || `Must be greater or equal to ${min}.`;
    }

    if (max && newValue > max) {
      err = errMessages?.max || `Must be smaller or equal to ${max}.`;
    }

    setStateError(err);

    if (err && analyticsEnabled) {
      sendEvent(`${analyticsPrefix}/Currency-Warning`, {name, warning: err});
    }
    return {err, validatedValue: newValue};
  }

  function onChangeValue(event) {
    const {value} = event.target;
    const numberValue =
      value === '' ? null : Number(value.replace(prefix, '').replace(/,/g, ''));
    const {validatedValue, err} = validate(numberValue);

    onChange(validatedValue, err);
  }

  function onBlurValue(event) {
    const {value} = event.target;
    setDirty(true);
    onBlur(value);
  }

  return (
    <InputWrapper
      small={props.small}
      xLarge={props.xLarge}
      iconPosition={iconPosition}
      disabled={disabled}
      css={wrapperCss}>
      {Boolean(icon) ? (
        <IconWrapper
          error={stateError || props?.error}
          icon="dollar"
          iconCss={iconCss}
          iconPosition={iconPosition}
          onClick={onClickIcon}
          small={props.small}
          xLarge={props.xLarge}
        />
      ) : null}
      <NumericFormat
        value={value}
        thousandsGroupStyle="thousand"
        decimalSeparator="."
        decimalScale={0}
        thousandSeparator={true}
        getInputRef={ref}
        data-testid="currency-field"
        onChange={onChangeValue}
        onBlur={onBlurValue}
        icon={icon}
        customInput={Input}
        skipValidation
        inputmode="numeric"
        pattern={'[0-9]*'}
        prefix={prefix}
        allowNegative={false}
        disabled={disabled}
        {...props}
      />
      {topMessage ? (
        <TopMessage color={topMessageColor}>{topMessage}</TopMessage>
      ) : null}
      {stateError ? (
        <Message css={errorCss} color={red}>
          {stateError}
        </Message>
      ) : null}
    </InputWrapper>
  );
};

CurrencyField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  topMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  topMessageColor: PropTypes.string,
  icon: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autofocus: PropTypes.bool,
  xLarge: PropTypes.bool,
  iconCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  iconPosition: PropTypes.string,
  errorCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  errMessages: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  prefix: PropTypes.string,
  analyticsEnabled: PropTypes.bool,
  analyticsPrefix: PropTypes.string,
};

export default CurrencyField;
