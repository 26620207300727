import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {setAnalyticsIdentify} from '@renofi/analytics';
import {saveLeadJwt} from '@renofi/utils/src/lead';

import {SUBMIT_SCENARIO} from '../mutations/submitScenario';

export default function useSubmitScenario({onCompleted = noop} = {}) {
  const [submitScenario, {data, loading, error}] = useMutation(
    SUBMIT_SCENARIO,
    {
      onCompleted: ({submitScenario}) => {
        if (submitScenario?.lead) {
          setAnalyticsIdentify(submitScenario.lead);
          saveLeadJwt(submitScenario.jwt, submitScenario?.lead?.id);
        }
        onCompleted(submitScenario);
      },
    },
  );
  const response = data?.submitScenario;
  return {submitScenario, response, loading, error};
}
