import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {land as enums} from '@renofi/api/src/enums';
import {OwnedIcon, PurchaseIcon} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

const {owned, to_be_purchased} = enums;
const options = [
  {
    title: 'On property I currently own',
    value: owned,
    icon: OwnedIcon,
  },
  {
    title: 'On property I am looking to purchase and add an ADU to',
    value: to_be_purchased,
    icon: PurchaseIcon,
  },
];

function Adu({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/ADU-Project-Page'), []);

  function onSubmit(propertyOwnershipStatus) {
    setLead({propertyOwnershipStatus});

    updateScenario({propertyOwnershipStatus});

    sendEvent('POS/ADU-Project-Entered', {propertyOwnershipStatus});

    next(
      propertyOwnershipStatus === owned ? '/loan-amount' : '/purchase-stage',
    );
  }

  return (
    <>
      <Heading>Where is the ADU project happening?</Heading>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={lead.propertyOwnershipStatus}
      />
      <Buttons
        disableNext={isNil(lead.propertyOwnershipStatus)}
        onNext={() => onSubmit(lead.propertyOwnershipStatus)}
        value={lead.propertyOwnershipStatus}
      />
    </>
  );
}

export default Adu;
