import {reduce} from 'lodash';

import {constants} from '@renofi/utils';
import {land as landEnums} from '@renofi/api/src/enums';

import {PAYMENTS_MAP} from './constants';

export const shouldCallPrequalCompleted = (nextPath) => {
  const completionPaths = [
    'loan-options',
    'personal/splash',
    'personal/sofi',
    'investment/vontive',
    'hei/point',
  ];

  return completionPaths.some((path) => nextPath.includes(path));
};

export const getMonthlyDebt = (lead, paymentSelections) => {
  return reduce(
    PAYMENTS_MAP,
    (result, value) => {
      if (
        paymentSelections[value.group] &&
        paymentSelections[value.selection] === value.selectionImpactOn &&
        lead[value.payment]
      ) {
        return result + lead[value.payment];
      }
      return result;
    },
    0,
  );
};

export const calculateDti = (lead, paymentSelections) => {
  const monthlyDebt = getMonthlyDebt(lead, paymentSelections);
  const monthlyIncome = lead.annualIncomePretax / 12;
  return monthlyDebt / monthlyIncome;
};

export function canShowRatesPage(lead) {
  const {HOME_BUILD, HOME_PURCHASED} = constants.LOAN_SUBJECT;

  if (
    lead.loanSubject === HOME_BUILD &&
    lead.propertyOwnershipStatus === landEnums.to_be_purchased
  ) {
    return false;
  }

  if (lead.loanSubject === HOME_PURCHASED) {
    return false;
  }

  return lead.firstMortgageBalance > 0;
}
