import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {OptionWrapper} from '../SearchField/styled';

const AddressOption = ({
  children,
  disabled,
  noRadius,
  place,
  xLarge,
  onClick,
}) => {
  return (
    <OptionWrapper
      disabled={disabled}
      noRadius={noRadius}
      onClick={() => onClick && onClick(place?.placeId)}>
      <Flex justifyContent="space-between" textAlign="left">
        <Text fontSize={xLarge ? 18 : 16} css={{fontWeight: 300}}>
          {place?.description || children}
        </Text>
      </Flex>
    </OptionWrapper>
  );
};

AddressOption.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  noRadius: PropTypes.bool,
  place: PropTypes.object,
  xLarge: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default AddressOption;
