import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../../api/cache';
import {useNext} from '../../hooks';
import {Buttons, Heading} from '../../components';
import IconSelect from '../../components/IconSelect';

import {ReactComponent as HiringIcon} from './img/hiring.svg';
import {ReactComponent as AloneIcon} from './img/alone.svg';
import {ReactComponent as SubcontractingIcon} from './img/subcontracting.svg';

const options = [
  {
    title: 'I’m doing everything myself',
    value: 'alone',
    icon: AloneIcon,
  },
  {
    title: 'I’m hiring a licensed & insured contractor',
    value: 'hiring',
    icon: HiringIcon,
  },
  {
    title:
      'I’m acting as the general contractor and hiring licensed & insured subcontractors',
    value: 'subcontracting',
    icon: SubcontractingIcon,
  },
];

function WhoPerforming({updateScenario}) {
  const next = useNext();
  const {renovationPerformedByType} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Who-Performing-Page'), []);

  function onSubmit(newValue) {
    let path = '/contractors/secured';
    if (newValue === 'subcontracting') path = '/contractors/subcontractors';
    if (newValue === 'alone') path = '/contractors/licensed';

    setLead({renovationPerformedByType: newValue});
    updateScenario({renovationPerformedByType: newValue});

    sendEvent('POS/Who-Performing-Entered', {
      renovationPerformedByType: newValue,
    });
    next(path);
  }

  return (
    <>
      <Heading>Who is performing the renovation?</Heading>
      <IconSelect
        css={{minHeight: ['100%', 202]}}
        onClick={onSubmit}
        options={options}
        value={renovationPerformedByType}
      />
      <Buttons
        disableNext={!renovationPerformedByType}
        onNext={() => onSubmit(renovationPerformedByType)}
        value={renovationPerformedByType}
      />
    </>
  );
}

WhoPerforming.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default WhoPerforming;
