import styled from '@emotion/styled';
import {Box, Flex, Text} from 'rebass';

import {darkBlue, mediaQuery} from '@renofi/utils';

import houseBlue from '../imgs/house-blue.svg';
import houseOlive from '../imgs/house-olive.svg';

import {DESKTOP_HEIGHT, MOBILE_HEIGHT} from './BottomBanner';

export const LeftPromptWrapper = styled.div(
  mediaQuery({
    display: ['none', 'block'],
    position: 'absolute',
    zIndex: 3,
    // width: 160,
    top: 100,
    left: -175,

    '> div': {
      width: 135,
      textAlign: 'center',

      '> img': {
        position: 'relative',
        top: 5,
        left: 32,
      },
    },
  }),
);

export const RightPromptWrapper = styled.div(
  mediaQuery({
    display: ['none', 'block'],
    position: 'absolute',
    zIndex: 3,
    top: 8,
    left: 'calc(100% + 32px)',
    img: {
      position: 'relative',
      top: 8,
      left: -32,
    },

    '> div': {
      width: 135,
      textAlign: 'center',
    },
  }),
);

export const LoanChartsWrapper = styled(Flex)(
  mediaQuery({
    marginLeft: [16, 87],
    flexDirection: ['row-reverse', 'row'],
    alignItems: 'flex-end',
    justifyContent: 'center',
    alignSelf: 'stretch',
    gap: [0, 64],
    position: 'relative',
    bottom: [MOBILE_HEIGHT, DESKTOP_HEIGHT],
    zIndex: 2,
  }),
);

export const ChartSuffix = styled(Text)(
  mediaQuery({
    padding: [4, 8],
    fontWeight: 300,
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    textAlign: 'center',
    lineHeight: 'normal',
    fontSize: [9, 16],
  }),
);

const animatedHouse = mediaQuery({
  position: 'relative',
  width: [90, 152],
  // maxWidth: [90, 'auto'],
  transition: 'height 500ms ease',
  fontSize: [23, 42],
  textAlign: 'center',
  // paddingTop: 64,
  ':before': {
    position: 'absolute',
    top: -64,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    backgroundSize: 'cover',
    width: '100%',
    height: 64,
    content: '""',
    zIndex: 0,
  },
  strong: {
    zIndex: 2,
    position: 'relative',
  },
});

export const EquityHouse = styled(Box)(
  animatedHouse,
  mediaQuery({
    ':before': {
      backgroundImage: `url(${houseOlive})`,
    },
    backgroundColor: '#e4e5d7',
    left: [-16, 0],
    zIndex: [1, 'auto'],
  }),
);

export const RenofiHouse = styled(Box)(
  animatedHouse,
  mediaQuery({
    ':before': {
      backgroundImage: `url(${houseBlue})`,
    },
    backgroundColor: darkBlue,
  }),
);
