import React from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import Copyright from '../Copyright';

import {Box, Link, LicensingLink, TextRight} from './styled';
import FooterLegalMessage from './FooterLegalMessage';

const FooterLinks = ({center}) => {
  return (
    <Box center={center}>
      <TextRight center={center}>
        <Copyright />
        <LicensingLink>
          <Link target="_blank" href="https://www.renofi.com/notices/licenses/">
            Licensing - NMLS # 1802847
          </Link>
        </LicensingLink>

        <Flex justifyContent="center">
          <FooterLegalMessage center={center} />
        </Flex>
      </TextRight>

      <TextRight fontSize={14} center={center}>
        <Link href="#!" data-acsb-custom-trigger="true">
          Accessibility
        </Link>
        <span> | </span>
        <Link target="_blank" href="https://www.renofi.com/contact/">
          Contact
        </Link>
        <span> | </span>
        <Link target="_blank" href="https://www.renofi.com/notices/privacy/">
          Privacy Policy
        </Link>
        <span> | </span>
        <Link target="_blank" href="https://www.renofi.com/notices/legal/">
          Terms of Service
        </Link>
      </TextRight>
    </Box>
  );
};

FooterLinks.propTypes = {
  center: PropTypes.bool,
};

export default FooterLinks;
