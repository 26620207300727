import React, {useEffect} from 'react';

import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {useRenovationUtils} from '@renofi/utils';
import {land as landEnums} from '@renofi/api/src/enums';
import {Alert, Toggle} from '@renofi/components';

import {leadVar, setLead} from '../api/cache';
import {Buttons, Heading, Slider} from '../components';
import {useNext} from '../hooks';

const MIN_RENOVATION_COST = 1000;
const MAX_RENOVATION_COST = 550000;

const {owned, to_be_purchased} = landEnums;

function RenovationCost({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {isPurchase, isHomeBuild} = useRenovationUtils(lead);
  const defaultRenovationCost = isHomeBuild ? 100000 : 10000;
  const {loanAmountDesiredForRenovation, propertyOwnershipStatus} = lead;
  const renovationCost =
    lead.renovationCost ||
    loanAmountDesiredForRenovation ||
    defaultRenovationCost;

  useEffect(() => sendEvent('POS/Renovation-Cost-Page'), []);

  function onChange(value) {
    setLead({
      renovationCost: value,
      ...(isPurchase ? {loanAmountDesiredForRenovation: value} : {}),
    });
  }

  function onSubmit() {
    onChange(renovationCost);

    updateScenario({
      renovationCost,
      ...(isPurchase ? {loanAmountDesiredForRenovation: renovationCost} : {}),
    });

    sendEvent('POS/Renovation-Cost-Entered', {renovationCost});
    let path = '/address';
    if (isPurchase) {
      path = '/after-renovation-value';
    }
    if (isHomeBuild && propertyOwnershipStatus === owned) {
      path = '/owned/loan-amount-for-build';
    }
    if (isHomeBuild && propertyOwnershipStatus === to_be_purchased) {
      path = '/loan-amount-for-build';
    }

    next(path);
  }

  const calcDecreaseStep = () => {
    if (renovationCost === 0) {
      return 0;
    }

    if (renovationCost <= 50000) {
      return 1000;
    }

    return 10000;
  };

  return (
    <>
      <Heading>
        {isHomeBuild
          ? 'Estimated costs to build the new home'
          : 'The estimated renovation costs are about'}
      </Heading>
      <Slider
        infinite
        noTooltip
        min={MIN_RENOVATION_COST}
        max={isHomeBuild ? 2000000 : MAX_RENOVATION_COST}
        step={renovationCost < 50000 ? 1000 : 10000}
        decreaseStep={calcDecreaseStep()}
        onChange={onChange}
        value={renovationCost}
      />
      <Buttons onNext={onSubmit} />

      <Toggle show={isHomeBuild}>
        <Flex justifyContent="center" mt={['8px', 24]}>
          <Alert
            icon
            warning
            border="none"
            css={{
              textAlign: 'left',
              width: 'auto',
              opacity: renovationCost < 100000 ? 1 : 0,
            }}>
            You have indicated that the cost to build the new home is below
            $100,000. If this is incorrect, please update before proceeding.
          </Alert>
        </Flex>
      </Toggle>
    </>
  );
}

export default RenovationCost;
