import {useEffect, useState} from 'react';

import {useReactiveVar} from '@apollo/client';

import {creditScore} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {
  HappyIcon,
  NotSoHappyIcon,
  SupperHappyIcon,
  Loader,
} from '@renofi/components';
import {getFakeDetails, isAuthenticated} from '@renofi/utils/src/lead';
import {
  useFeatureFlagVariantEnabled,
  useUpdateUnleashContext,
} from '@renofi/utils';
import {useUnleashContext} from '@renofi/utils/src/feature-flags';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

const OPTIONS = Object.entries(creditScore).map(([key, value], index) => ({
  title: value,
  value: key,
  icon: index < 3 ? SupperHappyIcon : index < 6 ? HappyIcon : NotSoHappyIcon,
}));

function CreditScore({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const dashboardSignupEnabled = useFeatureFlagVariantEnabled(
    'dashboard-sign-up',
    'challenger',
  );

  const [contextReady, setContextReady] = useState(false);
  const updateContext = useUnleashContext();
  const performContextUpdate = useUpdateUnleashContext(
    updateContext,
    lead,
    false,
  );

  useEffect(() => {
    const effect = async () => {
      await performContextUpdate({posCreditScoreVisited: true});
      setContextReady(true);
    };
    effect();
  }, []);

  useEffect(() => {
    if (contextReady && !isAuthenticated(lead.id) && dashboardSignupEnabled) {
      setLead(getFakeDetails());
    }
  }, [dashboardSignupEnabled, contextReady]);

  useEffect(() => sendEvent('POS/Credit-Score-Page'), []);

  function onSubmit(selfReportedCreditScore) {
    setLead({selfReportedCreditScore});

    updateScenario({selfReportedCreditScore});

    sendEvent('POS/Credit-Score-Entered', {selfReportedCreditScore});
    next(lead.zipCode ? '/name' : '/zip');
  }

  if (!contextReady) return <Loader />;

  return (
    <>
      <Heading>My estimated credit score is</Heading>
      <IconSelect
        vertical
        onClick={onSubmit}
        options={OPTIONS}
        value={lead.selfReportedCreditScore}
      />
      <Buttons
        disableNext={!lead.selfReportedCreditScore}
        onNext={() => onSubmit(lead.selfReportedCreditScore)}
      />
    </>
  );
}

export default CreditScore;
