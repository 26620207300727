import {useEffect} from 'react';

import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';
import {isNull} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {webToCase} from '@renofi/utils';

import {useNext} from '../../hooks';
import {Heading, Narrow, SubHeading} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';
import {Small} from '../../components';

import CheckItem from './CheckItem';

function RenofiContractor({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {subcontractorsSecured, contractorRequestedAt} = lead;

  const value =
    Boolean(contractorRequestedAt) || isNull(contractorRequestedAt)
      ? Boolean(contractorRequestedAt)
      : undefined;

  useEffect(() => sendEvent('POS/Renofi-Contractor-Page'), []);

  function onSubmit(newValue) {
    sendEvent('POS/Renofi-Contractor-Entered', {
      renofiContractorRequested: newValue,
    });

    if (newValue && !contractorRequestedAt) {
      sendEvent('POS/Renofi-Contractor-Requested');
      webToCase(lead);

      const contractorRequestedAt = new Date().toISOString();
      setLead({contractorRequestedAt});
      updateScenario({contractorRequestedAt});
    } else if (
      !newValue &&
      (contractorRequestedAt || !isNull(contractorRequestedAt))
    ) {
      setLead({contractorRequestedAt: null});
      updateScenario({contractorRequestedAt: null});
    }

    next('/plans');
  }

  return (
    <>
      <Heading>
        {subcontractorsSecured
          ? 'Are you interested in receiving RenoFi preferred contractor recommendations?'
          : 'Do you need help finding a contractor?'}
      </Heading>
      {!subcontractorsSecured && (
        <SubHeading>
          We'll introduce you to a contractor from our network.
        </SubHeading>
      )}

      <Narrow maxWidth={[420, '100%']}>
        <Flex
          mt={['-8px', 0]}
          mb={[24, 36]}
          width="100%"
          justifyContent={['space-between', 'center']}
          alignItems="center">
          <CheckItem>Free service</CheckItem>
          <CheckItem>No commitment</CheckItem>
          <CheckItem>Vetted by RenoFi</CheckItem>
        </Flex>
      </Narrow>
      <YesNoSelect value={value} onSubmit={onSubmit} />
      <Small mt={[24, 36]}>
        By answering “yes” you agree that we may share your contact information
        (name, email and phone number) with up to 3 contractors in your area.
      </Small>
    </>
  );
}

RenofiContractor.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default RenofiContractor;
