import {getDomain, isRenofiPreview, setItem} from '@renofi/utils';

const isSharedCookie = !isRenofiPreview();

export default (key, value) => {
  setItem({
    key,
    value,
    ...(isSharedCookie ? {domain: getDomain()} : {}),
  });
};
