import {useMutation} from '@apollo/client';

import {getItem} from '@renofi/utils';
import {getLeadJwt, savePersonalLeadData} from '@renofi/utils/src/lead';
import {setAnalyticsIdentify} from '@renofi/analytics';

import {UPDATE_SCENARIO_PERSONAL} from '../mutations/updateScenarioPersonal';

export default function useUpdateScenarioPersonal({advisorApp, id} = {}) {
  const [updateScenarioPersonal, {data, loading, error}] = useMutation(
    UPDATE_SCENARIO_PERSONAL,
    {
      context: {
        headers: {
          authorization: advisorApp ? getItem('jwt') : getLeadJwt(id),
        },
      },
      onCompleted: (data, {variables}) => {
        if (advisorApp) return;
        setAnalyticsIdentify(variables);
        savePersonalLeadData(variables, variables?.id);
      },
    },
  );
  const response = data?.updateScenarioPersonal;
  return {updateScenarioPersonal, response, loading, error};
}
