import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {land as enums} from '@renofi/api/src/enums';
import {OwnedIcon, PurchaseIcon} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading, Narrow} from '../components';
import IconSelect from '../components/IconSelect';

const {owned, to_be_purchased} = enums;
const options = [
  {
    title: 'I own the land',
    value: owned,
    icon: OwnedIcon,
  },
  {
    title: 'I am looking to purchase',
    value: to_be_purchased,
    icon: PurchaseIcon,
  },
];

function Land({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Land-Page'), []);

  function onSubmit(propertyOwnershipStatus) {
    setLead({propertyOwnershipStatus});

    updateScenario({propertyOwnershipStatus});

    sendEvent('POS/Land-Entered', {propertyOwnershipStatus});

    next(
      propertyOwnershipStatus === owned
        ? '/construction-purchase/home-value'
        : '/purchase-price',
    );
  }

  return (
    <>
      <Narrow maxWidth={700}>
        <Heading>
          Are you building on land you own or looking to purchase?
        </Heading>
      </Narrow>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={lead.propertyOwnershipStatus}
      />
      <Buttons
        disableNext={isNil(lead.propertyOwnershipStatus)}
        onNext={() => onSubmit(lead.propertyOwnershipStatus)}
        value={lead.propertyOwnershipStatus}
      />
    </>
  );
}

export default Land;
