/*
 * for this to work it requires to include a script in a template:
 * https://maps.googleapis.com/maps/api/js?key=API_KEY&libraries=places
 */
import {isNil} from 'lodash';

import logger from './logger';

export const DEFAULT_TYPES = ['address'];
export const DEFAULT_PLACE_FIELDS = [
  'address_components',
  'formatted_address',
  'geometry.location',
  'photos',
  'name',
];

let autocompleteInstance;
let placesInstance;

const getAutocompleteService = () => {
  if (!autocompleteInstance) {
    autocompleteInstance = new google.maps.places.AutocompleteService();
  }
  return autocompleteInstance;
};
const getPlacesService = () => {
  if (!placesInstance) {
    placesInstance = new google.maps.places.PlacesService(
      document.createElement('div'),
    );
  }
  return placesInstance;
};

export const getPlacePredictions = async (search, types = DEFAULT_TYPES) => {
  if (!google?.maps) {
    return Promise.reject(new Error('Google maps not available'));
  }
  return new Promise((resolve, reject) => {
    try {
      getAutocompleteService().getPlacePredictions(
        {
          input: search,
          componentRestrictions: {country: 'us'},
          types,
        },
        (predictions, status) => {
          if (
            [
              google.maps.places.PlacesServiceStatus.OK,
              google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
            ].includes(status)
          ) {
            resolve(predictions || []);
          } else {
            reject(new Error(status));
            return;
          }
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlaceDetails = (placeId, fields = DEFAULT_PLACE_FIELDS) => {
  return new Promise((resolve, reject) => {
    if (!google?.maps) {
      return Promise.reject(new Error('Google maps not available'));
    }
    try {
      getPlacesService().getDetails(
        {
          fields,
          placeId,
        },
        (place, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(new Error(status));
          } else {
            resolve(place);
          }
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const mapPlaceToAddress = (components, geometry) => {
  let component,
    streetNumber,
    streetName,
    streetNameShort,
    city,
    county,
    stateCode,
    state,
    lat,
    lng,
    zipCode;
  component = components.find(({types}) => types.includes('street_number'));
  if (component) {
    streetNumber = component.long_name;
  }
  component = components.find(({types}) => types.includes('route'));
  if (component) {
    streetName = component.long_name;
    streetNameShort = component.short_name;
  }
  component = components.find(({types}) => types.includes('locality'));
  if (component) {
    city = component.long_name;
  }
  component = components.find(({types}) =>
    types.includes('administrative_area_level_1'),
  );
  if (component) {
    stateCode = component.short_name;
    state = component.long_name;
  }
  component = components.find(({types}) =>
    types.includes('administrative_area_level_2'),
  );
  if (component) {
    county = component.short_name?.replace(' County', '');
  }
  if (geometry?.location) {
    lat = geometry.location.lat();
    lng = geometry.location.lng();
  }
  component = components.find(({types}) => types.includes('postal_code'));
  if (component) {
    zipCode = component.long_name;
  }
  const streetAddress = [streetNumber, streetName]
    .filter((item) => !isNil(item))
    .join(' ');
  const streetAddressShort = [streetNumber, streetNameShort]
    .filter((item) => !isNil(item))
    .join(' ');
  return {
    streetAddress,
    streetAddressShort,
    city,
    county,
    stateCode,
    state,
    zipCode,
    lat,
    lng,
  };
};

let script;

export const initGooglePlaces = (apiKey) => {
  if (!apiKey) return logger.debug('No Google Places API key given');
  if (script) return script;

  script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&loading=async&callback=Function.prototype`;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
};
