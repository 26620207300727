import React from 'react';

import {Text, Image} from 'rebass';

import {lightGray} from '@renofi/utils/src/colors';

import {IconSocialProof} from './styled';
import reviewAvatar from './images/review-avatar.png';

function FeatureReview() {
  return (
    <IconSocialProof>
      <Text color={lightGray} lineHeight="18px">
        <Image src={reviewAvatar} />
        <Text fontWeight="bold" mb="10px">
          “We picked our home for the awesome neighborhood but knew we’d want to
          renovate.”
        </Text>
        <Text>
          Three years after moving in, Michael & Jenna completed their entire
          renovation wish list with a RenoFi Loan 💪🏻.
        </Text>
      </Text>
    </IconSocialProof>
  );
}

export default FeatureReview;
