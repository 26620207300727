import React from 'react';

import {Text} from 'rebass';
import PropTypes from 'prop-types';

import {gray, darkBlue} from '@renofi/utils/src/colors';
import {useScreenSize} from '@renofi/utils';

import {
  Background,
  BarWrapper,
  ProgressWrapper,
  Bar,
  Title,
  IconWrapper,
} from './styled';
import {ReactComponent as CheckIcon} from './img/check.svg';

const ProgressBar = ({
  progress = 0,
  label,
  title,
  bgColor,
  height = 10,
  useIcon = true,
}) => {
  const {isMobile} = useScreenSize();
  const progressValue =
    isMobile && progress > 0 && progress < 12 ? 12 : progress;

  function getTitleColor() {
    return !progress ? '#A2A2A2' : darkBlue;
  }

  return (
    <ProgressWrapper pt={title ? 30 : 0}>
      {useIcon && title && progress === 100 ? (
        <IconWrapper>
          <CheckIcon />
        </IconWrapper>
      ) : null}
      {title ? <Title color={getTitleColor()}>{title}</Title> : null}
      <BarWrapper progress={progressValue}>
        {label ? (
          <Text color={gray} fontSize={12}>
            {label}
          </Text>
        ) : null}
        <Bar height={height} />
      </BarWrapper>
      <Background bgColor={bgColor} height={height} />
    </ProgressWrapper>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  label: PropTypes.string,
  title: PropTypes.string,
  bgColor: PropTypes.string,
  height: PropTypes.number,
};

export default ProgressBar;
