import {useEffect, useState} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {useFlag} from '@renofi/utils/src/feature-flags';
import {sendEvent} from '@renofi/analytics';

import {useNext} from '../../hooks';
import {Heading} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

function Blueprints({updateScenario}) {
  const next = useNext();
  const [loading, setLoading] = useState(false);
  const {hasRenovationEstimate} = useReactiveVar(leadVar);
  const vaStatusPageEnabled = useFlag('va-status-page');

  useEffect(() => sendEvent('POS/Blueprints-Page'), []);

  async function onSubmit(hasRenovationEstimate) {
    setLoading(true);
    setLead({hasRenovationEstimate});

    await updateScenario({hasRenovationEstimate});

    sendEvent('POS/Blueprints-Entered', {hasRenovationEstimate});

    next(getNextPath());
  }

  function getNextPath() {
    if (vaStatusPageEnabled) return '/va-status';
    return '/borrower-origin';
  }

  return (
    <>
      <Heading>
        Has a contractor provided you with a detailed budget, project proposal
        or a contract?
      </Heading>
      <YesNoSelect
        loading={loading}
        value={hasRenovationEstimate}
        onSubmit={onSubmit}
      />
    </>
  );
}

Blueprints.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default Blueprints;
