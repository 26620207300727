import React from 'react';

import {Image} from 'rebass';

import {IconSocialProof} from './styled';
import testimonial from './images/funding.png';

function FundingSmall() {
  return (
    <IconSocialProof>
      <Image src={testimonial} />
    </IconSocialProof>
  );
}

export default FundingSmall;
