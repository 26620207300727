import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import {tooltipBgStyles} from './styled';

export default styled(Box)(
  mediaQuery({
    ...tooltipBgStyles,
    position: ['static', 'absolute'],
    zIndex: 10001,
    width: '100%',
    maxWidth: ['100%', 300],
    marginTop: [36, 0],
    left: 'calc(100% + 30px)',
    top: 0,
    ':before': {
      content: ['none', '" "'],
      position: 'absolute',
      left: -10,
      top: 20,
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRight: '10px solid white',
    },
  }),
  ({css}) => mediaQuery(css),
);
