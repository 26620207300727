export default {
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 8,
    retryIf: (error) => !!error,
  },
};
