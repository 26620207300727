import React from 'react';

import PropTypes from 'prop-types';

import {Wrapper, Header, Content} from './styled';

const PanelDark = ({children, title, css, contentCss, ...props}) => {
  return (
    <Wrapper as="div" css={css} {...props}>
      {title && <Header>{title}</Header>}
      <Content padding={15} {...contentCss}>
        {children}
      </Content>
    </Wrapper>
  );
};

PanelDark.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bgColor: PropTypes.string,
};

export default PanelDark;
