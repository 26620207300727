import * as Sentry from '@sentry/react';
import {useReactiveVar} from '@apollo/client';

import {
  getEnvironmentUrl,
  isQueryParamSet,
  useLocalStorage,
  deleteCookie,
} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {usePrequalCompleted} from '@renofi/api';
import {savePersonalLeadData, isFakeLead} from '@renofi/utils/src/lead';

import {disableLocalStoringLead, leadVar} from '../api/cache';

export default function useRedirectToDashboard() {
  const {prequalCompleted} = usePrequalCompleted();
  const [scheduleStorage] = useLocalStorage('renofi:schedule');
  const scheduleParam = isQueryParamSet('schedule');
  const lead = useReactiveVar(leadVar);

  function redirectToDashboard(id) {
    const dashboardAppUrl = getEnvironmentUrl('dashboard', '4012');
    sendEvent('POS/Redirect-To-Dashboard');
    const params = scheduleParam || scheduleStorage ? '?schedule=true' : '';

    window.location.assign(`${dashboardAppUrl}/${id}${params}`);
  }

  return async (id, prequalCompletedAt) => {
    if (!id) {
      Sentry.captureException('Bad dashboard redirect without lead id', {
        extra: id,
      });
    }

    if (id && !isFakeLead(lead) && !prequalCompletedAt) {
      await prequalCompleted({variables: {scenarioId: id}});
    }

    savePersonalLeadData(lead, id);
    disableLocalStoringLead();
    deleteCookie('posStep');
    redirectToDashboard(id);
  };
}
