import React, {useEffect, useState} from 'react';

import {Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {Alert, Loader, Toggle, ZipInput} from '@renofi/components';
import {useEligibleZipCode, useUpdateScenarioPersonal} from '@renofi/api';
import {lightBlue} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';
import {isAuthenticated} from '@renofi/utils/src/lead';

import {useNext} from '../hooks';
import {leadVar, setLead} from '../api/cache';
import {Buttons, Heading, Narrow} from '../components';

import {SuccessMessage} from './styled';
import {getNextPathForState} from './utils';

const Zip = () => {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {updateScenarioPersonal} = useUpdateScenarioPersonal({id: lead?.id});
  const queryZipCode = useEligibleZipCode();

  const [zipCode, setZipCode] = useState(lead.zipCode || '');
  const [error, setError] = useState(false);
  const [eligible, setEligible] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sendEvent('POS/Zip-Page');
  }, []);

  async function onComplete(value) {
    setError(!value);
    if (!value) return;
    setZipCode(value);
    await onCheckZip(value);
  }

  async function onCheckZip(zipCode) {
    if (!zipCode || error || loading) return;

    setLoading(true);

    const {data = {}} = await queryZipCode({zipCode});
    const {eligibleZipCode} = data;

    setLoading(false);
    if (!eligibleZipCode) {
      setEligible(false);
      return;
    }
    setEligible(true);

    const {city, state} = eligibleZipCode;
    setLead({zipCode, city, state});
  }

  function onNext() {
    setLoading(true);

    const {id, zipCode} = lead;

    if (isAuthenticated(id)) {
      updateScenarioPersonal({variables: {id, zipCode}});
    }

    sendEvent('POS/Zip-Entered', {zipCode});
    const path = getNextPathForState(lead.state, '/name');

    next(path);
    setLoading(false);
  }

  return (
    <Narrow maxWidth={420}>
      <Heading>My zip code is</Heading>
      <ZipInput
        autofocus
        value={zipCode}
        onChange={() => setError(false)}
        error={!eligible}
        onComplete={onComplete}
      />

      <Box minHeight={24} mt={16}>
        <Toggle show={!eligible}>
          <Alert>
            This doesn&apos;t appear to be a valid zip code. Please double check
            the code and try again.
          </Alert>
        </Toggle>

        <Toggle show={loading}>
          <Loader mb={-10} mt={0} color={lightBlue} size={1} />
        </Toggle>

        <Toggle show={lead.state && eligible && !loading}>
          <SuccessMessage>
            <strong>Hello, {lead.state}!</strong>
          </SuccessMessage>
        </Toggle>
      </Box>

      <p>
        We use location to match you with the most suitable lender & loan
        product available to you.
      </p>
      <Buttons
        onNext={onNext}
        loading={loading}
        disableNext={!eligible || error}
      />
    </Narrow>
  );
};

export default Zip;
