import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {useRenovationUtils} from '@renofi/utils';

import {Buttons, Heading, Slider} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';

function LoanAmount({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {loanAmountDesiredForRenovation = 20000} = lead;

  const {isHomeBuild} = useRenovationUtils(lead);

  const MIN_LOAN_AMOUNT = 1000;
  const MAX_LOAN_AMOUNT = isHomeBuild ? 2000000 : 550000;

  useEffect(
    () => sendEvent(`POS/Loan-Amount${isHomeBuild ? '-For-Build' : ''}-Page`),
    [],
  );

  function onChange(value) {
    setLead({loanAmountDesiredForRenovation: value});
  }

  function onSubmit() {
    onChange(loanAmountDesiredForRenovation);

    updateScenario({loanAmountDesiredForRenovation});

    sendEvent(`POS/Loan-Amount${isHomeBuild ? '-For-Build' : ''}-Entered`, {
      loanAmountDesiredForRenovation,
    });
    let path = '/renovation-cost';
    if (isHomeBuild) path = '/after-renovation-value';
    next(path);
  }

  const calcDecreaseStep = () => {
    if (loanAmountDesiredForRenovation === 0) {
      return 0;
    }

    if (loanAmountDesiredForRenovation <= 50000) {
      return 1000;
    }

    return 10000;
  };

  return (
    <>
      <Heading>
        Loan amount needed for the {isHomeBuild ? 'build' : 'renovation'} is
      </Heading>
      <Slider
        infinite
        noTooltip
        label="You can change this later"
        min={MIN_LOAN_AMOUNT}
        max={MAX_LOAN_AMOUNT}
        step={loanAmountDesiredForRenovation < 50000 ? 1000 : 10000}
        decreaseStep={calcDecreaseStep()}
        onChange={onChange}
        value={loanAmountDesiredForRenovation}
      />
      <Buttons onNext={onSubmit} />
    </>
  );
}

export default LoanAmount;
