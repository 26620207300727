import {useState} from 'react';

import {getItem, setItem} from '../cookies';

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
export default function useCookies(key, defaultValue) {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = ({value, numberOfDays, domain}) => {
    setCookie(value);
    setItem({key, value, numberOfDays, domain});
  };
  return {cookie, updateCookie};
}
