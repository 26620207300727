import {useEffect} from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {useNext} from '../../hooks';
import {Heading} from '../../components';
import {setLead} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

function ContractorLicensedConfirmation({updateScenario}) {
  const next = useNext();

  useEffect(() => sendEvent('POS/Contractor-Licensed-Confirmation-Page'), []);

  function onSubmit(confirmed) {
    let renovationPerformedByType;
    let path;

    if (confirmed) {
      renovationPerformedByType = 'subcontracting';
      path = '/contractors/subcontractors';
    } else {
      renovationPerformedByType = 'alone';
      path = '/plans';
    }

    setLead({renovationPerformedByType});
    updateScenario({renovationPerformedByType});

    sendEvent('POS/Contractor-Licensed-Confirmation-Entered', {
      renovationPerformedByType,
    });
    next(path);
  }

  return (
    <>
      <Heading>
        {`We love that you're handy around the house, but lenders don't love it when
        you're the only one doing the job. `}
        <br />
        <b>Will you hire a licensed contractor to do any of the work?</b>
      </Heading>
      <YesNoSelect onSubmit={onSubmit} />
    </>
  );
}

ContractorLicensedConfirmation.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default ContractorLicensedConfirmation;
