import React from 'react';

import PropTypes from 'prop-types';
import {noop} from 'lodash';

import ZipInput from '../ZipInput';
import {PercentIcon} from '../Icons';

const RateInput = ({onComplete = noop, value, autofocus}) => {
  const suffix = <PercentIcon style={{width: 70, height: 70}} />;
  return (
    <ZipInput
      autofocus={autofocus}
      count={3}
      value={value}
      onComplete={onComplete}
      float
      suffix={suffix}
    />
  );
};

RateInput.propTypes = {
  onComplete: PropTypes.func,
  autofocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default RateInput;
