import {useSubmitTCPAConsent} from '@renofi/api';
import {setAnalyticsIdentify} from '@renofi/analytics';

import useApi from '../api/useApi';

const CONSENT_MESSAGE =
  'I consent to Electronic Transactions/Signatures and to receive sales and marketing calls, text messages, artificial/prerecorded messages, and voicemails by or on behalf of RenoFi using an autodialer or any Regulated Equipment to the number(s) provided, even if included on any Do-Not-Call list, regarding home renovation financing options. Message frequency varies. Msg and data rates may apply. Reply to texts with HELP for help or support and STOP to stop receiving texts. I’m not required to provide this consent as a condition of purchasing any products or services. I’m authorized to provide this consent. I have read, understand, and agree to RenoFi’s SMS Terms and Conditions and Privacy Policy.';

const useGiveTcpaConsent = () => {
  const {queryScenario} = useApi();
  const {submitTcpaConsent} = useSubmitTCPAConsent();

  return async (lead) => {
    await submitTcpaConsent({
      variables: {
        scenarioId: lead.id,
        phone: lead.phone,
        consentMedium: 'pos',
        consentMessage: CONSENT_MESSAGE,
      },
    });
    const updatedScenario = await queryScenario(lead.id, {updateCache: false});
    const OPT_IN = [
      {
        channel: 'sms',
        subscribed: true,
        timestamp: updatedScenario?.lead?.tcpaConsentAt,
        source: 'pos',
        text: CONSENT_MESSAGE,
      },
      {
        channel: 'voice',
        subscribed: true,
        timestamp: updatedScenario?.lead?.tcpaConsentAt,
        source: 'pos',
        text: CONSENT_MESSAGE,
      },
    ];
    setAnalyticsIdentify(lead, {
      phone: lead.phone,
      optIn: OPT_IN,
    });
  };
};

export default useGiveTcpaConsent;
