import {useEffect} from 'react';

import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {
  stageOfPurchase as stageOfPurchaseЕnums,
  land as landEnums,
} from '@renofi/api/src/enums';
import {constants} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';

import {leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading, Narrow} from '../components';

function BankruptcyMessage() {
  const {to_be_purchased} = landEnums;
  const {LOAN_SUBJECT} = constants;
  const {stageOfPurchase, propertyOwnershipStatus, loanSubject} =
    useReactiveVar(leadVar);
  const next = useNext();

  useEffect(() => sendEvent('POS/Bankruptcy-Failure-Page'), []);

  function onSubmit() {
    let path = '/renovation-start';
    if (
      [LOAN_SUBJECT.HOME_PURCHASED, LOAN_SUBJECT.ADU].includes(loanSubject) &&
      propertyOwnershipStatus === to_be_purchased &&
      stageOfPurchase === stageOfPurchaseЕnums.offer_accepted
    )
      path = '/purchase-closing';
    if (
      [LOAN_SUBJECT.HOME_PURCHASED, LOAN_SUBJECT.ADU].includes(loanSubject) &&
      [
        stageOfPurchaseЕnums.researching,
        stageOfPurchaseЕnums.making_offers,
      ].includes(stageOfPurchase)
    ) {
      path = '/combined-income';
    }
    next(path);
  }

  return (
    <Narrow maxWidth={720}>
      <Heading css={{lineHeight: ['38px', '60px']}}>
        Lenders are particularly sensitive to applicants that may have had
        recent mortgage performance challenges.
      </Heading>

      <Text fontSize={24} color={gray} fontWeight={300} lineHeight="36px">
        Everyone is welcome to apply although you should address this with the
        Lender to ensure realistic expectations.
      </Text>
      <Buttons onNext={onSubmit} />
    </Narrow>
  );
}

export default BankruptcyMessage;
