import {useHistory} from 'react-router-dom';
import {Box} from 'rebass';

import {Panel} from '@renofi/components';

import {Heading} from '../components';
import {Wrapper} from '../App/styled';

import './styled.css';

function Docs() {
  const history = useHistory();

  if (process.env.REACT_APP_ENV === 'production') {
    return history.push('/');
  }

  return (
    <Wrapper width={1140}>
      <Panel background="white" style={{boxShadow: 'none'}}>
        <Heading mt={20}>Url params documentation</Heading>
        <table className="styled-table" style={{width: '100%'}}>
          <thead>
            <tr>
              <th>Param</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>stars</strong>
              </td>
              <td>
                Control UI based on lead quality (stars). Use values from 1 to 5
                <br />
                Example:{' '}
                <Box p={2} style={{background: '#eeeeee'}}>
                  /loan-options/{'<lead uuid>'}/?
                  <strong>stars</strong>=4
                </Box>
              </td>
            </tr>
            <tr>
              <td>
                <strong>options</strong>
              </td>
              <td>
                Control UI based on the number of loan options. Use values from
                1 to 3.
                <br />
                Example:{' '}
                <Box p={2} style={{background: '#eeeeee'}}>
                  /loan-options/{'<lead uuid>'}/?
                  <strong>options</strong>=3
                </Box>
              </td>
            </tr>
            <tr>
              <td>
                <strong>loanAmount</strong>
              </td>
              <td>
                Force custom loan amount. This is useful to test how loan
                options screen looks like if loan amount is bigger than max loan
                option is. Please make sure you use at leas 20% more than max
                option is.
                <br />
                Example:{' '}
                <Box p={2} style={{background: '#eeeeee'}}>
                  /loan-options/{'<lead uuid>'}/?
                  <strong>loanAmount</strong>=700000
                </Box>
              </td>
            </tr>

            <tr>
              <td>Combined examples</td>
              <td>
                <Box p={2} style={{background: '#eeeeee'}}>
                  /loan-options/{'<lead uuid>'}/?
                  <strong>loanAmount</strong>=700000&<strong>options</strong>=3&
                  <strong>stars</strong>=2
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </Wrapper>
  );
}

export default Docs;
