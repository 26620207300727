import {useState, useEffect} from 'react';

import useUpdateUnleashContext from './useUpdateUnleashContext';

const usePrepareUnleashContext = (updateContext, lead) => {
  const [contextReady, setContextReady] = useState(false);
  const update = useUpdateUnleashContext(updateContext, lead, false);

  useEffect(() => {
    const effect = async () => {
      await update();
      setContextReady(true);
    };

    if (lead?.id) {
      effect();
    }
  }, [lead?.id]);

  return contextReady;
};

export default usePrepareUnleashContext;
