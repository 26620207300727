import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import Checkbox from '../Checkbox';
import Label, {CheckboxLabel} from '../Label';

const SubscribeCheckbox = ({onChange, value, color}) => {
  const [renderEmpty, setRenderEmpty] = useState(false);
  useEffect(() => {
    setRenderEmpty(value);
  }, []);

  if (renderEmpty) return null;

  return (
    <Flex alignItems="center" mb="5px">
      <Label css={{cursor: 'pointer'}}>
        <Box minWidth={32}>
          <Checkbox
            checked={value}
            id="newsletter"
            name="newsletter"
            onChange={() => onChange('hasNewsletterConsent', !value)}
          />
        </Box>
        <CheckboxLabel color={color}>
          Subscribe to the RenoFi Rundown newsletter (optional).{' '}
        </CheckboxLabel>
      </Label>
    </Flex>
  );
};

SubscribeCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  color: PropTypes.string,
};

export default SubscribeCheckbox;
