import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {Button} from '@renofi/components';

const BackButton = ({path, text = 'Previous', onClick, ...props}) => {
  const history = useHistory();

  function onBack() {
    onClick && onClick();
    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  }

  return (
    <Button
      secondary
      css={{fontSize: 14}}
      width={168}
      onClick={onBack}
      {...props}>
      {text}
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  path: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BackButton;
