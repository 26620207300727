import {useEffect} from 'react';

import {isEmpty} from 'lodash';
import {useReactiveVar} from '@apollo/client';
import * as Sentry from '@sentry/react';

import {sendEvent} from '@renofi/analytics';
import {
  useUTMTags,
  useRaasSourceLender,
  constants,
  isAddressValid,
} from '@renofi/utils';
import useLeadExternalOriginType from '@renofi/utils/src/hooks/useLeadExternalOriginType';
import {getPersonalLeadData, isAuthenticated} from '@renofi/utils/src/lead';
import {useUpdateScenarioPersonal} from '@renofi/api';

import useApi from '../api/useApi';
import {leadVar, resetLocation} from '../api/cache';

const useCreateLead = () => {
  const lead = useReactiveVar(leadVar);
  const {createScenario} = useApi();
  const {updateScenarioPersonal} = useUpdateScenarioPersonal({id: lead?.id});
  const {utmTags: renofiUtmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {utmTags: sloUtmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.slo);

  const utmTags = isEmpty(sloUtmTags) ? renofiUtmTags : sloUtmTags;

  const raasSourceLender = useRaasSourceLender(utmTags);
  const leadExternalOriginType = useLeadExternalOriginType(utmTags);
  const {email: storedEmail} = getPersonalLeadData(lead?.id);
  const isEmailChanged = storedEmail !== lead.email;

  useEffect(() => {
    // In case if for some reason address is not completed better to
    // remove instead failing submitting scenario
    if (!isEmpty(lead.location) && !isAddressValid(lead.location)) {
      Sentry.captureException(
        new Error('Attempt to submit scenario with invalid location'),
        {extra: {lead}},
      );
      resetLocation();
    }
  }, []);

  return async () => {
    sendEvent('POS/User-Details-Submitting');
    let rsp;
    if (lead && !lead.id) {
      rsp = await createScenario({
        ...lead,
        utmTags: {
          utmCampaign: utmTags.campaign,
          utmContent: utmTags.content,
          utmMedium: utmTags.medium,
          utmSource: utmTags.source,
          utmTerm: utmTags.term,
        },
        sourceLenderCode: raasSourceLender,
        leadExternalOriginType,
      });

      sendEvent('POS/Lead-Created', {email: lead.email});
      if (lead.hasNewsletterConsent) {
        sendEvent('POS/Newsletter-Signup');
      }
    }

    if (isAuthenticated(lead.id) && isEmailChanged) {
      try {
        rsp = await updateScenarioPersonal({variables: lead});
      } catch (error) {
        Sentry.captureException(new Error('Lead email update error'), {
          extra: {error, lead},
        });
      }
    }

    return rsp;
  };
};

export default useCreateLead;
