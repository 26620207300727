import React, {useEffect, useState} from 'react';

import * as PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import camelcaseKeys from 'camelcase-keys';
import {noop} from 'lodash';

import {
  DEFAULT_PLACE_FIELDS,
  DEFAULT_TYPES,
  getPlaceDetails,
  getPlacePredictions,
  initGooglePlaces,
  mapPlaceToAddress,
} from '@renofi/utils/src/places';
import {useScreenSize} from '@renofi/utils';
import {lightGray} from '@renofi/utils/src/colors';

import {Label, Loader, SearchField} from '../';

import AddressOption from './AddressOption';
import NoneFound from './NoneFound';

function AddressField({
  autocomplete = true,
  autofocus = true,
  loading,
  value,
  onChange,
  onClickManual = noop,
  onSelect,
  xLarge,
  label = 'Address of the property',
  placeholder = '',
  placeFields = DEFAULT_PLACE_FIELDS,
  placeTypes = DEFAULT_TYPES,
  ...props
}) {
  const [status, setStatus] = useState('idle');
  const [options, setOptions] = useState([]);
  const {isDesktop} = useScreenSize();

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (apiKey) initGooglePlaces(apiKey);
  }, []);

  async function onSearch(searchKeys) {
    setStatus('loading');
    const predictions = await getPlacePredictions(searchKeys, placeTypes);
    setOptions(
      predictions.map(
        ({
          description,
          place_id: placeId,
          structured_formatting: {
            main_text: primary,
            secondary_text: secondary,
          },
        }) => ({
          placeId,
          primary,
          secondary,
          description,
        }),
      ),
    );
    setStatus('idle');
  }

  return (
    <>
      <Label small htmlFor="address">
        {label}
      </Label>
      <Flex alignItems="center">
        <SearchField
          disabled={loading}
          css={{minWidth: '100%', width: '100%'}}
          placeholder={placeholder}
          autocomplete={autocomplete}
          autofocus={autofocus}
          floating={isDesktop}
          loading={status === 'loading'}
          onSearch={onSearch}
          onChange={onChange}
          onSelect={onSelect}
          xLarge={xLarge}
          value={value}
          id="address"
          name="address"
          options={options}
          {...props}>
          {({onClose, onSelect}) => {
            return (
              <div>
                {options?.map((place) => {
                  return (
                    <AddressOption
                      onClick={async (placeId) => {
                        const place = await getPlaceDetails(
                          placeId,
                          placeFields,
                        );
                        const geometry = place?.geometry;
                        const components = place?.address_components;
                        const address = mapPlaceToAddress(components, geometry);
                        onSelect(address, camelcaseKeys(place, {deep: true}));
                      }}
                      xLarge={xLarge}
                      key={place?.placeId}
                      place={place}
                    />
                  );
                })}
                <AddressOption disabled noRadius>
                  <NoneFound
                    onClick={(e) => {
                      onClose();
                      onClickManual(e);
                    }}
                  />
                </AddressOption>
              </div>
            );
          }}
        </SearchField>
        {loading ? (
          <Box show={loading} ml={16}>
            <Loader color={lightGray} />
          </Box>
        ) : null}
      </Flex>
    </>
  );
}

AddressField.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  xLarge: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeFields: PropTypes.arrayOf(PropTypes.string),
  placeTypes: PropTypes.arrayOf(PropTypes.string),
};

export default AddressField;
