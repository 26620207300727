import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Box, Text, Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {
  Label,
  InfoBlueIcon,
  InfoIcon,
  IncomeSteps,
  Button,
} from '@renofi/components';
import {useScreenSize, padWithZeros} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import CurrencyField from '@renofi/components/src/CurrencyField';

import {
  Heading,
  Buttons,
  Narrow,
  TooltipContent,
  TooltipBox,
  BulbIcon,
} from '../components';
import {leadVar, setLead} from '../api/cache';
import {Tooltip} from '../components';
import {useNext} from '../hooks';

function CombinedIncome({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {annualIncomePretax} = lead;
  const [clicked, setClicked] = useState(false);
  const [suggestedValue, setSuggestedValue] = useState(0);
  const {isMobile} = useScreenSize();

  useEffect(() => sendEvent('POS/Combined-Income-Page'), []);

  function onChange(annualIncomePretax) {
    const suggestedLength = String(annualIncomePretax).length < 4 ? 5 : 6;
    setSuggestedValue(padWithZeros(annualIncomePretax, suggestedLength));
    setLead({annualIncomePretax});
  }

  function onErrorClick() {
    const newValue = suggestedValue;
    onChange(newValue);
  }

  function onSubmit() {
    updateScenario({annualIncomePretax});

    sendEvent('POS/Combined-Income-Entered', {annualIncomePretax});
    next('/payments');
  }

  return (
    <>
      <Narrow maxWidth={700}>
        <Heading>Annual gross household income before tax</Heading>
      </Narrow>

      <Narrow maxWidth={['100%', 295]}>
        <Label small htmlFor="combined-income">
          Total <strong>&nbsp;annual&nbsp;</strong> income before tax
        </Label>
        <Flex css={{position: 'relative'}} mt="5px">
          <Box css={{flex: 1}}>
            <CurrencyField
              errMessages={{
                min: (
                  <a onClick={onErrorClick}>
                    Did you mean <strong>${toCurrency(suggestedValue)}</strong>?
                  </a>
                ),
              }}
              autofocus
              required
              icon
              value={annualIncomePretax}
              onChange={onChange}
              min={suggestedValue ? 50000 : undefined}
              id="combined-income"
              name="combined-income"
              analyticsEnabled
              analyticsPrefix="POS"
            />
          </Box>

          <Button
            css={{maxWidth: 50}}
            transparent
            tabIndex={-1}
            clicked={isMobile ? clicked : null}
            data-tip
            data-tooltip-id="income-tip">
            <InfoBlueIcon />
          </Button>

          <Tooltip
            place={isMobile ? 'left' : 'right'}
            event={isMobile ? 'click' : null}
            afterHide={() => setClicked(false)}
            afterShow={() => setClicked(true)}
            delayHide={100}
            id="income-tip">
            <TooltipContent>
              <strong>TIP</strong> Include income for <strong>everyone</strong>{' '}
              who will be on the loan application as this will increase your
              chances of success.
            </TooltipContent>
            <Box
              css={{
                borderBottom: `solid 1px #DEDEDE`,
                margin: '15px -20px 15px -12px',
              }}
            />
            <TooltipContent icon={InfoIcon}>
              <strong>Do not</strong> include income from child support or
              alimony payments here.
            </TooltipContent>
          </Tooltip>
        </Flex>
      </Narrow>

      <Buttons onNext={onSubmit} disableNext={!annualIncomePretax} />

      <TooltipBox
        css={{filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1))'}}
        width="100%"
        mt={40}
        p={[0, 20]}
        bg={['transparent', 'white']}>
        <Flex>
          <BulbIcon />
          <Text ml="10px" mt="3px">
            <strong>How is the household income calculated?</strong>
          </Text>
        </Flex>
        <IncomeSteps blue css={{marginBottom: [0, 0], marginTop: [20, 65]}} />
      </TooltipBox>
    </>
  );
}

CombinedIncome.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default CombinedIncome;
