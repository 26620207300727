import {useEffect, useState} from 'react';

import {Flex} from 'rebass';
import {useParams} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {Loader} from '@renofi/components';
import {red} from '@renofi/utils/src/colors';
import {getDomain, useCookies} from '@renofi/utils';
import {LEAD_SESSION_DAYS} from '@renofi/utils/src/lead';

import {useNext} from '../hooks';
import {Heading} from '../components';
import {Wrapper} from '../App/styled';
import {useFetchLead} from '../LoanOptions/hooks';

function Redirection() {
  const [initedAt] = useState(new Date().getTime());
  const next = useNext();
  const {id} = useParams();
  const {_, updateCookie} = useCookies('renofi_id');
  const {fetchLead} = useFetchLead();

  useEffect(() => {
    (async () => {
      updateCookie({
        value: id,
        numberOfDays: LEAD_SESSION_DAYS,
        domain: getDomain(),
      });
      await fetchLead(id);
      const now = new Date().getTime();

      if (now >= initedAt + 5000) {
        onNext();
      } else {
        const diff = now - initedAt;
        setTimeout(onNext, 5000 - diff);
      }
    })();
  }, [id]);

  useEffect(() => sendEvent('POS/Redirection-Intro-Page'), []);

  function onNext() {
    next('/loan-type');
  }

  return (
    <Wrapper width={1140}>
      <Flex>
        <Loader color={red} />
        <Heading mt="-2px">
          You are being redirected to the RenoFi app to update your project
          scenario
        </Heading>
      </Flex>
    </Wrapper>
  );
}

export default Redirection;
