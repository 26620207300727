import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {Radio as RebassRadio} from '@rebass/forms';

import {CheckboxWrapper} from '../Checkbox/styled';

const Radio = ({onChange, m, checked = false, disabled, ...props}) => {
  function onChangeValue(event) {
    onChange(event.target.value);
  }

  return (
    <Box width={16} m={m} height={16} bg="white" marginTop="4px">
      <CheckboxWrapper disabled={disabled}>
        <RebassRadio
          minWidth={30}
          onChange={onChangeValue}
          checked={checked}
          {...props}
        />
      </CheckboxWrapper>
    </Box>
  );
};

Radio.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  m: PropTypes.string,
};

export default Radio;
