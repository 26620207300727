import {useEffect} from 'react';

import {sendEvent} from '@renofi/analytics';

import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import {Wrapper} from '../App/styled';

function Intro() {
  const next = useNext('/loan-type');

  useEffect(() => sendEvent('POS/Schedule-Intro-Page'), []);

  function onNext() {
    next('/loan-type');
  }

  return (
    <Wrapper width={1140}>
      <Heading>
        Tell us a little bit about your project before scheduling a call with a
        RenoFi Advisor.
      </Heading>
      <Buttons disableBack hideBack onNext={onNext} />
    </Wrapper>
  );
}

export default Intro;
