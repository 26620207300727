import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {useNext} from '../../hooks';
import {Heading} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

function HomeTypeMultiUnit({updateScenario}) {
  const next = useNext();
  const {propertyStructureTypeMultiUnit} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Home-Type-Multi-Unit-Page'), []);

  function getInvestmentValue(isOwnerOccupied) {
    return isOwnerOccupied ? null : 'investment_property';
  }

  function onSubmit(isOwnerOccupied) {
    const propertyStructureType = isOwnerOccupied
      ? 'multi_unit_owner_occupied'
      : 'multi_unit';
    setLead({
      propertyStructureType,
      propertyStructureTypeMultiUnit: propertyStructureType,
      propertyUseType: getInvestmentValue(isOwnerOccupied),
    });

    updateScenario({
      propertyStructureType,
      propertyUseType: getInvestmentValue(isOwnerOccupied),
    });

    sendEvent('POS/Home-Type-Multi-Unit-Entered', {
      propertyStructureType,
    });

    next(isOwnerOccupied ? '/property-use' : '/bankruptcy');
  }

  function getValue() {
    if (propertyStructureTypeMultiUnit === 'multi_unit_owner_occupied')
      return true;
    if (propertyStructureTypeMultiUnit === 'multi_unit') return false;
  }

  return (
    <>
      <Heading>Do you reside in one of the units?</Heading>
      <YesNoSelect value={getValue()} onSubmit={onSubmit} />
    </>
  );
}

HomeTypeMultiUnit.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default HomeTypeMultiUnit;
