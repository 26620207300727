import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {CurrencyField, ButtonSelect, CheckboxPanel} from '@renofi/components';

import {Info} from '../../components';
import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

const options = {
  Yes: true,
  No: false,
};

function VehicleLoan({onChange, onToggle, onPaymentSelectionsChange, noDebt}) {
  const {vehiclePayment} = useReactiveVar(leadVar);
  const {isVehicleLoanGroupOpen, isMoreThan10MVehicleLoanLeft} =
    useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Vehicle loan payment"
      onToggle={onToggle}
      value={isVehicleLoanGroupOpen}
      greyOut={noDebt}>
      <Flex mb={30}>
        <Box width={1 / 2}>
          <SideLabel>
            Will the payments continue for more than 10 months from now?
          </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <ButtonSelect
            value={isMoreThan10MVehicleLoanLeft}
            inline
            internal
            onClick={(value) =>
              onPaymentSelectionsChange('isMoreThan10MVehicleLoanLeft', value)
            }
            options={options}
          />
        </Box>
      </Flex>
      {isMoreThan10MVehicleLoanLeft && (
        <Flex>
          <Box width={1 / 2}>
            <SideLabel>
              <strong>Monthly</strong> vehicle loan payments for all applicants
            </SideLabel>
          </Box>
          <Box width={1 / 2}>
            <CurrencyField
              errMessages={{
                max: `You have entered $${toCurrency(
                  vehiclePayment,
                )} as your monthly vehicle loan payment. If that's incorrect, please update, otherwise continue.`,
              }}
              errorCss={{position: 'static', width: 220}}
              max={5000}
              icon
              name="vehicle-payment"
              value={vehiclePayment}
              onChange={(value) => onChange('vehiclePayment', value)}
              analyticsEnabled
              analyticsPrefix="POS"
            />
          </Box>
        </Flex>
      )}
      {isMoreThan10MVehicleLoanLeft === false && (
        <Info>
          Balances that will be paid off in the next 10 months are excluded from
          your DTI calculation.
        </Info>
      )}
    </CheckboxPanel>
  );
}

VehicleLoan.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPaymentSelectionsChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default VehicleLoan;
