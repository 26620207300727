import styled from '@emotion/styled/macro';

import {red} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const Dot = styled.div(
  mediaQuery({
    width: 9,
    height: 9,
    borderRadius: 10,
    margin: '0 5px',
    display: ['block', 'none'],
  }),
  ({active}) => ({
    background: active ? red : '#D8D8D8',
    opacity: active ? 1 : '.7',
  }),
);
