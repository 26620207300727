import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {useParams} from 'react-router-dom';

import {useTurndownMatches} from '@renofi/utils/src/turndowns';
import {useUnleashContext} from '@renofi/utils/src/feature-flags';
import {useCookies, useNoFooter, usePrepareUnleashContext} from '@renofi/utils';

import {PageLoader} from '../components';
import {loanProductsVar, leadVar} from '../api/cache';
import {useRedirectToDashboard} from '../hooks';

import {useFetchLead} from './hooks';

function LoanOptions() {
  const loanProducts = useReactiveVar(loanProductsVar) || [];
  const lead = useReactiveVar(leadVar);
  const redirectToDashboard = useRedirectToDashboard();

  const {cookie} = useCookies('renofi_id');
  const id = useParams()?.id || cookie;

  const updateContext = useUnleashContext();
  const contextReady = usePrepareUnleashContext(updateContext, lead);

  const {fetchLead, fetchStatus} = useFetchLead();
  const loading = fetchStatus === 'loading' || !contextReady;
  const {checking: checkingTurndowns} = useTurndownMatches(
    lead,
    loanProducts,
    loading,
  );

  useEffect(() => {
    fetchLead(id);
  }, [id]);

  useNoFooter();

  if (!lead?.id || loading || checkingTurndowns) {
    return <PageLoader />;
  }

  redirectToDashboard(lead.id, lead.prequalCompletedAt);
  return <PageLoader />;
}

export default LoanOptions;
