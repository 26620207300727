import {useQuery} from '@apollo/client';

import {ELIGIBLE_ZIP_CODE} from '../queries/eligibleZipCode';

export default function useEligibleZipCode() {
  const {refetch} = useQuery(ELIGIBLE_ZIP_CODE, {skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
