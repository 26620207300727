import {makeVar} from '@apollo/client';

import {formatName} from '@renofi/utils/src/format';

const getBorrowerNames = (gcddReviews = []) => {
  return gcddReviews
    .filter((gcddReview) => gcddReview?.borrowerName !== null)
    .map((gcddReview) => gcddReview?.borrowerName)
    .join(', ');
};

// Reactive variables
export const borrowersVar = makeVar('');

// Setter
export const setBorrowers = ({project, questionnaire}) => {
  const gcddReviews = questionnaire?.gcddReviews || [];
  if (Boolean(gcddReviews?.length)) {
    return borrowersVar(getBorrowerNames(gcddReviews));
  }

  if (Boolean(project)) {
    const {borrowersAndPropertyInformation} = project;
    return borrowersVar(formatName(borrowersAndPropertyInformation));
  }

  borrowersVar('N/A');
};

// Apollo field policy
export const borrowersPolicy = {
  read() {
    return borrowersVar();
  },
};

export default {
  borrowersPolicy,
  setBorrowers,
};
