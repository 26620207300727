import {useState, useEffect} from 'react';

import {getEnvironmentUrl} from '@renofi/utils';
import {isFakeLead, isIntroducedByContractor} from '@renofi/utils/src/lead';

import {isLeadVontiveMatch} from './turndowns';
import useSplashOrSoFi from './useSplashOrSoFi';

const useTurndownMatches = (lead, loanProducts, loading) => {
  const [checking, setChecking] = useState(true);
  const url = getEnvironmentUrl('qualify', 4010);

  const getSplashOrSoFi = useSplashOrSoFi(lead);

  useEffect(() => {
    if (lead?.id && !loading) {
      if (isFakeLead(lead) || isIntroducedByContractor(lead)) {
        setChecking(false);
        return;
      }

      const splashOrSoFi = getSplashOrSoFi();

      if (Boolean(splashOrSoFi)) {
        window.location.assign(`${url}${splashOrSoFi}`);
        return;
      }
      if (isLeadVontiveMatch(lead, loanProducts)) {
        window.location.assign(`${url}/investment/vontive/${lead.id}`);
        return;
      }
      setChecking(false);
    }
  }, [loading]);

  return {checking};
};

export default useTurndownMatches;
