import React from 'react';

import PropTypes from 'prop-types';

import {useLongButtonPress} from '@renofi/utils';

import {SquareButton} from '../styled';

const ProgressiveButton = ({onChange, children}) => {
  const {multiplier, longPressHandlers} = useLongButtonPress(onChange);

  return (
    <SquareButton
      secondary
      {...longPressHandlers}
      onClick={() => onChange(multiplier)}>
      {children}
    </SquareButton>
  );
};

ProgressiveButton.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default ProgressiveButton;
