import {useEffect, useState} from 'react';

import {Modal, Toggle} from '@renofi/components';
import {useCookies, constants} from '@renofi/utils';
import {hideSurvey} from '@renofi/analytics';

import Feedback from './Feedback';
import ThankYou from './ThankYou';

const {FEEDBACK_PROVIDED_COOKIE_KEY} = constants;

const FeedbackPopup = ({
  title,
  subtitle,
  optionsList,
  visible,
  onFeedbackClose,
  onFeedbackSubmit,
}) => {
  const [showFeedback, setShowFeedback] = useState(true);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const {cookie, updateCookie} = useCookies(FEEDBACK_PROVIDED_COOKIE_KEY, null);

  useEffect(() => {
    if (visible) {
      hideSurvey();
    }
  }, [visible]);

  const handleFeedbackSubmit = (feedback, feedbackDetails) => {
    setShowFeedback(false);
    onFeedbackSubmit(feedback, feedbackDetails);
    setIsFeedbackSubmitted(true);
    updateCookie({value: true, numberOfDays: 1});
  };

  const handleFeedbackClose = () => {
    setShowFeedback(true);
    onFeedbackClose(isFeedbackSubmitted);
  };

  if (cookie && showFeedback) return null;

  return (
    <Modal
      contentCss={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      light
      show={visible}
      onClose={handleFeedbackClose}>
      <Toggle show={showFeedback}>
        <Feedback
          title={title}
          subtitle={subtitle}
          optionsList={optionsList}
          onFeedbackSubmit={handleFeedbackSubmit}
        />
      </Toggle>

      <Toggle show={!showFeedback}>
        <ThankYou onDoneClick={handleFeedbackClose} />
      </Toggle>
    </Modal>
  );
};

export default FeedbackPopup;
