import {makeVarPersisted} from '@renofi/utils';

// Reactive variables
export const [leadVar, disableLocalStoringLead, enableLocalStoringLead] =
  makeVarPersisted({}, 'renofi-lead');

// Setter
export const setLead = (lead) => {
  leadVar({...leadVar(), ...lead});
};

export const resetLead = () => {
  leadVar({});
};

export const resetAllAddressFields = () => {
  const lead = {...leadVar()};
  delete lead.location;
  delete lead.state;
  delete lead.city;
  delete lead.streetAddress;
  delete lead.streetAddressShort;
  delete lead.county;
  delete lead.zipCode;
  delete lead.lat;
  delete lead.lng;
  leadVar(lead);
};

export const resetLocation = () => {
  const lead = {...leadVar()};
  delete lead.location;
  leadVar(lead);
};

// Apollo field policy
export const leadPolicy = {
  read() {
    return leadVar();
  },
};
