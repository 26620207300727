import {useState, useRef} from 'react';

import {uniqBy} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {leadVar} from '../../api/cache';

import ModalsContext from './ModalsContext';
import {MODAL_TYPE_NONE} from './constants';

const ModalsProvider = ({children}) => {
  const modalsMemo = useRef([]);
  const [modals, setModals] = useState([]);
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);
  const lead = useReactiveVar(leadVar);

  const handleSetModals = (incomingModals) => {
    modalsMemo.current = uniqBy(
      [...modalsMemo.current, ...incomingModals],
      ({type}) => type,
    );

    setModals(modalsMemo.current);
  };

  return (
    <ModalsContext.Provider
      value={{
        setModals: handleSetModals,
        setModalType,
      }}>
      {modals.map(({type, Modal}, index) => (
        <Modal key={index} visible={type === modalType} lead={lead} />
      ))}
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;
