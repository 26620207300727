import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {Header} from '@renofi/components/src/Modal/styled';

export const modalCustomStyles = {
  [Header]: {
    background: 'white',
    padding: '20px 25px 20px 25px',
  },
};

export const Wrapper = styled(Box)(
  mediaQuery({
    position: 'absolute',
    right: 15,
    top: 20,
  }),
);
