import PropTypes from 'prop-types';
import {Text} from 'rebass';

import {toCurrency} from '@renofi/utils/src/format';

function TotalDebt({monthlyTotal = 0, align = 'left', ...props}) {
  return (
    <Text fontSize={18} mt={16} textAlign={align} {...props}>
      Total: <strong>${toCurrency(monthlyTotal)}</strong>
    </Text>
  );
}

TotalDebt.propTypes = {
  monthlyTotal: PropTypes.number,
  align: PropTypes.string,
};

export default TotalDebt;
