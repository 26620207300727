import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';
import {isNil} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {constants} from '@renofi/utils';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as PrimaryIcon} from './img/primary.svg';
import {ReactComponent as InvestmentIcon} from './img/investment.svg';
import {ReactComponent as VacationIcon} from './img/vacation.svg';

const options = [
  {
    title: 'Primary residence',
    aduTitle: 'On the same property with primary residence',
    value: 'primary_residence',
    icon: PrimaryIcon,
  },
  {
    title: 'Second home or Vacation home',
    aduTitle: 'On a second home or vacation home',
    value: 'secondary_residence',
    icon: VacationIcon,
  },
  {
    title: 'Investment property',
    aduTitle: 'On an investment property',
    value: 'investment_property',
    icon: InvestmentIcon,
  },
];

function PropertyUse({updateScenario}) {
  const next = useNext();
  const {propertyUseType, loanSubject} = useReactiveVar(leadVar);
  const isADU = loanSubject === constants.LOAN_SUBJECT.ADU;

  useEffect(() => sendEvent('POS/Property-Use-Page'), []);

  function onSubmit(propertyUseType) {
    setLead({propertyUseType});
    updateScenario({propertyUseType});

    sendEvent('POS/Property-Use-Entered', {propertyUseType});
    next('/bankruptcy');
  }

  return (
    <>
      <Heading>
        {isADU
          ? 'Where will the ADU be located?'
          : 'How do you plan to use the property?'}
      </Heading>
      <IconSelect
        onClick={onSubmit}
        options={options.map((option) => ({
          ...option,
          title: isADU ? option.aduTitle : option.title,
        }))}
        value={propertyUseType}
      />
      <Buttons
        disableNext={isNil(propertyUseType)}
        onNext={() => onSubmit(propertyUseType)}
        value={propertyUseType}
      />
    </>
  );
}

PropertyUse.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default PropertyUse;
