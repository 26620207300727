import React from 'react';

import {Button} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

const VONTIVE_URL = 'https://renofi.vontive.app/signup';

const VontiveCTA = () => {
  const handleCTAClick = () => {
    sendEvent('POS/Vontive-Investment-Button-Click');
    window.open(VONTIVE_URL, '_blank');
  };

  return <Button onClick={handleCTAClick}>Get Started</Button>;
};

export default VontiveCTA;
