import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {darkBlue, gray} from '@renofi/utils/src/colors';

export const Wrapper = styled(Box)(
  {
    textAlign: 'left',
    background: 'white',
    borderRadius: 10,
    boxShadow: '0 6px 10px rgba(0,0,0,0.2)',
  },
  ({css}) => css,
);

export const Content = styled(Box)({
  color: gray,
});

export const Header = styled.div({
  background: darkBlue,
  color: 'white',
  padding: '10px 15px',
  fontSize: 18,
  textAlign: 'left',
  borderRadius: '6px 6px 0 0',
});
