import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {CurrencyField, ButtonSelect, CheckboxPanel} from '@renofi/components';

import {Info} from '../../components';
import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

const options = {
  Yes: true,
  No: false,
};
function Alimony({onChange, onToggle, onPaymentSelectionsChange, noDebt}) {
  const {alimonyPayment} = useReactiveVar(leadVar);
  const {isAlimonyGroupOpen, isMoreThan10MAlimonyLeft} =
    useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Alimony payment"
      onToggle={onToggle}
      value={isAlimonyGroupOpen}
      greyOut={noDebt}>
      <Flex mb={30}>
        <Box width={1 / 2}>
          <SideLabel>
            Will the payments continue for more than 10 months from now?
          </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <ButtonSelect
            value={isMoreThan10MAlimonyLeft}
            inline
            internal
            onClick={(value) =>
              onPaymentSelectionsChange('isMoreThan10MAlimonyLeft', value)
            }
            options={options}
          />
        </Box>
      </Flex>
      {isMoreThan10MAlimonyLeft && (
        <Flex width={1}>
          <Box width={1 / 2}>
            <SideLabel>
              <strong>Monthly</strong> alimony
            </SideLabel>
          </Box>
          <Box width={1 / 2}>
            <CurrencyField
              errMessages={{
                max: `You have entered $${toCurrency(
                  alimonyPayment,
                )} as your monthly alimony payment. If that's incorrect, please update, otherwise continue.`,
              }}
              errorCss={{position: 'static'}}
              max={5000}
              icon
              name="alimony-payment"
              value={alimonyPayment}
              onChange={(value) => onChange('alimonyPayment', value)}
              analyticsEnabled
              analyticsPrefix="POS"
            />
          </Box>
        </Flex>
      )}
      {isMoreThan10MAlimonyLeft === false && (
        <Info>
          Balances that will be paid off in the next 10 months are excluded from
          your DTI calculation.
        </Info>
      )}
    </CheckboxPanel>
  );
}

Alimony.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPaymentSelectionsChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default Alimony;
