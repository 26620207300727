import React from 'react';

import PropTypes from 'prop-types';

import Step from './Step';
import {StepsWrapper, StepTitle} from './styled';
import {ReactComponent as MarriedIcon} from './img/1.svg';
import {ReactComponent as JoeIcon} from './img/2.svg';
import {ReactComponent as MarryIcon} from './img/3.svg';
import {ReactComponent as CombinedIcon} from './img/4.svg';

const IncomeSteps = (props) => {
  return (
    <StepsWrapper {...props}>
      <Step icon={MarriedIcon} flex={1.4} {...props}>
        <StepTitle>Mary & Joe are married</StepTitle>
      </Step>

      <Step icon={JoeIcon} flex={1.7} {...props}>
        <StepTitle>
          Mary earns <strong>$50,000</strong> per year before tax
        </StepTitle>
      </Step>

      <Step icon={MarryIcon} flex={1.7} {...props}>
        <StepTitle>
          Joe earns <strong>$45,000</strong> per year before tax
        </StepTitle>
      </Step>

      <Step icon={CombinedIcon} flex={2.4} {...props}>
        <StepTitle>
          Their combined pre-tax household income is <strong>$95,000</strong>
        </StepTitle>
      </Step>
    </StepsWrapper>
  );
};

IncomeSteps.propTypes = {
  section: PropTypes.string,
  progress: PropTypes.number,
  blue: PropTypes.bool,
};

export default IncomeSteps;
