import React from 'react';

import {Flex} from 'rebass';
import PropTypes from 'prop-types';

import {DescriptionContainer, SectionHeading} from './styled';

const DescriptionSection = ({heading, children}) => (
  <DescriptionContainer>
    <SectionHeading small>{heading}</SectionHeading>
    <Flex
      flexWrap={'wrap'}
      justifyContent={'space-around'}
      alignItems={'flex-start'}>
      {children}
    </Flex>
  </DescriptionContainer>
);

DescriptionSection.propTypes = {
  heading: PropTypes.object,
  children: PropTypes.any,
};

export default DescriptionSection;
