import {useEffect} from 'react';

import {sendEvent} from '@renofi/analytics';
import {useContractorBySlug} from '@renofi/api';
import {constants, useUTMTags} from '@renofi/utils';

import {setLead} from '../api/cache';
import {useNext} from '../hooks';
import {Heading, YesNoSelect} from '../components';
import {getIsGCPartnerSource} from '../util';
import {Wrapper} from '../App/styled';

const GcIntro = ({updateScenario}) => {
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const next = useNext('/loan-type');

  const isGcPartner = getIsGCPartnerSource(utmTags);
  const {contractor} = useContractorBySlug({
    skip: !isGcPartner,
    variables: {urlSlug: utmTags.source},
  });
  const businessName = contractor?.businessName || 'the contractor';

  useEffect(() => {
    sendEvent('POS/GC-Splash-Page', utmTags);
  }, []);

  const onSubmitYesNo = (yes) => {
    setLead({isWorkingWithCobrandingSourcedContractor: yes});
    updateScenario({isWorkingWithCobrandingSourcedContractor: yes});

    next(yes ? '/loan-type' : 'gc-consent');
    if (yes) {
      sendEvent('POS/GC-Splash-Page-Clicked', {
        ...utmTags,
        isWorkingWithCobrandingSourcedContractor: yes,
      });
    }
  };

  return (
    <Wrapper width={1140}>
      <Heading>
        Before we begin, are you working with {businessName} already?
      </Heading>
      <YesNoSelect onSubmit={onSubmitYesNo} showButtons={false} />
    </Wrapper>
  );
};

export default GcIntro;
