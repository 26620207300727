import React, {useEffect, useState} from 'react';

import {forEach, map, some} from 'lodash';
import PropTypes from 'prop-types';
import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {useRenovationUtils, useScreenSize} from '@renofi/utils';
import {Toggle, CheckboxTitle} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import {Narrow, Heading, SubHeading, Buttons} from '../components';
import {PAYMENTS_MAP} from '../util/constants';
import {
  leadVar,
  setLead,
  paymentSelectionsVar,
  setPaymentSelections,
} from '../api/cache';
import {useNext} from '../hooks';
import {getMonthlyDebt, calculateDti, canShowRatesPage} from '../util';

import Mortgage from './Mortgage';
import VehicleLoan from './VehicleLoan';
import CreditCard from './CreditCard';
import PersonalLoan from './PersonalLoan';
import StudentLoan from './StudentLoan';
import HomeEquity from './HomeEquity';
import ChildSupport from './ChildSupport';
import Alimony from './Alimony';
import TotalDebt from './TotalDebt';

function Debt({updateScenario}) {
  const {isMobile, isDesktop} = useScreenSize();
  const [showStickyTotal, setShowStickyTotal] = useState(true);
  const lead = useReactiveVar(leadVar);
  const paymentSelections = useReactiveVar(paymentSelectionsVar);

  const {noDebt, firstMortgageBalance} = lead;
  const {isFinalizedPurchase} = useRenovationUtils(lead);
  const isMortgageRequired = Boolean(
    firstMortgageBalance || isFinalizedPurchase,
  );
  const next = useNext();
  const groups = map(PAYMENTS_MAP, 'group');

  useEffect(() => sendEvent('POS/Dept-Page'), []);

  useEffect(() => {
    document.body.classList.add('extra-footer-space');
    return () => document.body.classList.remove('extra-footer-space');
  }, []);

  useEffect(() => {
    if (isMortgageRequired) {
      setPaymentSelections({[PAYMENTS_MAP.mortgage.group]: true});
    }
  }, [isMortgageRequired]);

  function onChange(key, value) {
    setLead({[key]: value});
  }

  function onPaymentSelectionsChange(key, value) {
    setPaymentSelections({[key]: value});
  }

  function getPath(calculatedDti) {
    if (calculatedDti >= 0.6) return '/dti';
    return canShowRatesPage(lead) ? '/rate' : '/contractors';
  }

  function onSubmit() {
    hideStickyTotal();

    forEach(PAYMENTS_MAP, (value) => {
      if (
        (!paymentSelections[value.group] ||
          paymentSelections[value.selection] !== value.selectionImpactOn) &&
        lead[value.payment]
      )
        setLead({[value.payment]: 0});
    });

    updateScenario(lead);

    const monthlyPayments = getMonthlyDebt(lead, paymentSelections);
    const calculatedDti = calculateDti(lead, paymentSelections);
    const path = getPath(calculatedDti);

    setLead({calculatedDti, monthlyPayments});

    sendEvent('POS/Dept-Entered', {calculatedDti, monthlyPayments});

    next(path);
  }

  function hideStickyTotal() {
    setShowStickyTotal(false);
    document.body.classList.remove('extra-footer-space');
  }

  function onToggle(key, value) {
    if (value) setLead({noDebt: false});
    setPaymentSelections({[key]: value});
  }

  function onToggleAll() {
    setLead({noDebt: !noDebt});
    groups.forEach((group) => onToggle(group, false));
  }

  function isInvalid() {
    const isAnyOpenWithoutValue = some(
      PAYMENTS_MAP,
      (value) =>
        paymentSelections[value.group] &&
        paymentSelections[value.selection] === value.selectionImpactOn &&
        !lead[value.payment],
    );
    const isMortgageRequiredAndEmpty =
      isMortgageRequired && !lead.primaryMortgagePayment;
    return (
      isAnyOpenWithoutValue ||
      (!getMonthlyDebt(lead, paymentSelections) && !noDebt) ||
      isMortgageRequiredAndEmpty
    );
  }

  function props(key) {
    return {
      onChange: onChange,
      onPaymentSelectionsChange: onPaymentSelectionsChange,
      onToggle: (value) => onToggle(key, value),
      noDebt: noDebt,
    };
  }

  return (
    <>
      <Heading>What monthly payments are you making?</Heading>
      <SubHeading>
        Needed to calculate your debt-to-income ratio (DTI) and your loan
        options.
      </SubHeading>
      <Narrow maxWidth={500}>
        <Mortgage
          {...props('isMortgageGroupOpen')}
          required={isMortgageRequired}
        />
        <VehicleLoan {...props('isVehicleLoanGroupOpen')} />
        <CreditCard {...props('isCreditCardGroupOpen')} />
        <PersonalLoan {...props('isPersonalLoanGroupOpen')} />
        <StudentLoan {...props('isStudentLoanGroupOpen')} />
        <HomeEquity {...props('isSecondaryMortgageGroupOpen')} />
        <ChildSupport {...props('isChildSupportGroupOpen')} />
        <Alimony {...props('isAlimonyGroupOpen')} />
        {!isMortgageRequired && (
          <CheckboxTitle
            onToggle={onToggleAll}
            label="I am not making any monthly payments"
            value={noDebt}
          />
        )}

        <Toggle show={isDesktop}>
          <TotalDebt monthlyTotal={getMonthlyDebt(lead, paymentSelections)} />
        </Toggle>
      </Narrow>

      <Buttons
        onNext={onSubmit}
        disableNext={isInvalid()}
        onBack={hideStickyTotal}>
        <Toggle show={isMobile && showStickyTotal}>
          <Flex justifyContent="flex-end">
            <TotalDebt
              align="center"
              monthlyTotal={getMonthlyDebt(lead, paymentSelections)}
              mb="-8px"
              mt={0}
              width={160}
              mr={15}
              pt={10}
            />
          </Flex>
        </Toggle>
      </Buttons>
    </>
  );
}

Debt.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default Debt;
