import React from 'react';

import {Text, Image} from 'rebass';

import {lightGray} from '@renofi/utils/src/colors';

import {IconSocialProof} from './styled';
import {AsSeenOnIcons} from './images';

function AsSeenOn() {
  return (
    <IconSocialProof>
      <Text width="100%" fontWeight="bold" color={lightGray} mb="10px">
        As seen on
      </Text>
      <Image src={AsSeenOnIcons} width="60%" />
    </IconSocialProof>
  );
}

export default AsSeenOn;
