import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {blue} from '@renofi/utils/src/colors';
import {useScreenSize} from '@renofi/utils';
import {InfoTooltip, Loader, PanelDark, Toggle} from '@renofi/components';

import {IconSelectWrapper, ButtonText, IconWrapper} from './styled';

const IconSelectItem = ({
  onClick,
  icon,
  selected,
  title,
  loading,
  tooltip,
  value,
  vertical,
  css,
}) => {
  const Icon = icon || null;

  const {isDesktop} = useScreenSize();

  return (
    <Box css={{position: 'relative'}} height="100%">
      <IconSelectWrapper
        isLoading={loading}
        selected={selected}
        onClick={() => onClick(value)}
        css={css}
        vertical={vertical}>
        <Toggle show={loading}>
          <Flex css={{position: 'absolute'}} mb={vertical ? '2px' : '6px'}>
            <Loader size={vertical ? 1.5 : 2} color={blue} />
          </Flex>
        </Toggle>

        <Box
          opacity={loading ? 0 : 1}
          css={{
            display: vertical ? 'flex' : 'block',
          }}
          data-tip={Boolean(tooltip) && isDesktop}
          data-for={tooltip && isDesktop ? `debt-tip-${tooltip}` : false}>
          <IconWrapper vertical={vertical}>
            <Icon />
          </IconWrapper>
          <ButtonText vertical={vertical}>{title}</ButtonText>
        </Box>

        {tooltip ? (
          <InfoTooltip id={value} css={{padding: 10}} stopPropagation>
            <PanelDark title={title}>{tooltip}</PanelDark>
          </InfoTooltip>
        ) : null}
      </IconSelectWrapper>
    </Box>
  );
};

IconSelectItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.string,
  icon: PropTypes.object,
  loading: PropTypes.bool,
  vertical: PropTypes.bool,
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default IconSelectItem;
