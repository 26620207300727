import React from 'react';

import PropTypes from 'prop-types';

import {StepWrapper, IconWrapper} from './styled';

const Step = ({children, icon, ...props}) => {
  const Icon = icon;
  return (
    <StepWrapper {...props}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      {children}
    </StepWrapper>
  );
};

Step.propTypes = {
  icon: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  blue: PropTypes.bool,
};

export default Step;
