import styled from '@emotion/styled/macro';
import {Flex, Box, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const DetailsWrapper = styled(Flex)(
  mediaQuery({
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export const DetailsLabel = styled(Text)(
  mediaQuery({
    fontSize: '16px',
    lineHeight: '19.2px',
    color: '#737373',
    textAlign: 'left',
    flex: 1,
    marginRight: '5px',
  }),
);

export const DetailsFieldWrapper = styled(Box)(
  mediaQuery({
    flex: 1,
    marginLeft: '5px',
  }),
);
