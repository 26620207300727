import {makeVar} from '@apollo/client';

// Reactive variables
export const earlyOptionsVar = makeVar([]);

// Setters
export const setEarlyOptions = (earlyOptions) => {
  earlyOptionsVar(earlyOptions);
};

// Apollo field policy
export const earlyOptionsPolicy = {
  read() {
    return earlyOptionsVar();
  },
};
