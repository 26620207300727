import React from 'react';

import {Box} from 'rebass';

import {fadeGreen, mediaQuery} from '@renofi/utils';

export const MOBILE_HEIGHT = 98;
export const DESKTOP_HEIGHT = 216;

const BottomBanner = () => {
  return (
    <Box
      as="div"
      css={mediaQuery({
        position: 'absolute',
        display: ['flex', 'grid'],
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        width: '100%',
        height: [MOBILE_HEIGHT, DESKTOP_HEIGHT],
        bottom: 0,
        left: 0,
        background: fadeGreen,
      })}
    />
  );
};

export default BottomBanner;
