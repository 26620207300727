import React, {useEffect} from 'react';

import {useParams} from 'react-router-dom';
import {Flex, Box, Image} from 'rebass';

import {useSetPresentedTurndownOption} from '@renofi/utils/src/turndowns';
import {sendEvent} from '@renofi/analytics';
import {useNoFooter, constants} from '@renofi/utils';
import {Button} from '@renofi/components';

import HeroSection from '../Components/HeroSection/HeroSection';
import DescriptionSection from '../Components/DescriptionSection/DescriptionSection';
import HowItWorksSection from '../Components/HowItWorksSection/HowItWorksSection';
import Offer from '../Components/Offer/Offer';

import {
  OfferWrapper,
  OfferHeading,
  TestimonialContainer,
  DescriptionText,
  CallToActionSection,
  OfferProvider,
  OfferedByText,
} from './styled';
import splash from './img/splash.jpg';
import splashAward from './img/splash-award.svg';

const SPLASH_URL = 'https://splash-financial.j48ltb.net/c/3719258/1496853/9516';

const STEPS = [
  {
    title: 'Easy process to check rates',
    description: 'Check multiple offers from our network of lenders.',
  },
  {
    title: 'Submit your application',
    description: 'Easily complete your application online.',
  },
  {
    title: 'Finalize your loan',
    description: 'One monthly payment and use funds as needed.',
  },
];

const BENEFIT_ITEMS = [
  'Funds in as little as one business day²',
  'Rates from as low as 8.99% APR*',
  'Compare offers from multiple lenders',
  'Checking rates will not impact your credit score³',
];

const Disclaimer = () => (
  <Flex flexDirection="column" alignItems="center" textAlign="left">
    <strong>Splash Disclosure</strong>
    <div>
      <p>
        *Terms and Conditions apply. Splash reserves the right to modify or
        discontinue products and benefits at any time without notice. Rates and
        terms are subject to change at any point prior to application
        submission. The information you provide is an inquiry to determine
        whether Splash’s lending partners can make you a loan offer.
      </p>
      <p>
        Splash marketplace loans offer fixed rates between 8.99% to 35.99% APR
        as of 02/01/2023. Personal loans have an origination fee of 0% to 8%
        which may be deducted from the loan proceeds. Lowest rates are reserved
        for the highest qualified borrowers. Lowest rates may require autopay
        and may require paying off a portion of existing debt directly. The
        autopay reduction will not be applied if autopay is not in effect. Not
        all rates and amounts available in all states. Not all applicants will
        qualify for the full amount. Residents of Massachusetts have a minimum
        loan amount of $6,000.
      </p>
      <p>
        To qualify, a borrower must be a U.S. citizen or other eligible status
        and meet lender underwriting requirements. Splash does not guarantee
        that you will receive any loan offers or that your loan application will
        be approved. If approved, your actual rate will be within a range of
        rates and will depend on a variety of factors, including term of loan,
        creditworthiness, income and other factors.
      </p>
      <p>
        ² While funding may be as soon as one to three business days, at times
        it could take up to two weeks.
      </p>
      <p>
        ³ To check the rates and terms you qualify for, Splash Financial
        conducts a soft credit pull that will not affect your credit score.
        However, if you choose a product and continue your application, the
        lender will request your full credit report from one or more consumer
        reporting agencies, which is considered a hard credit pull and may
        affect your credit. Loans feature repayment terms of 12 to 84 months
        depending on the lender and your qualifications. For example, if you are
        approved for a $10,000 loan with a 36-month term and a fixed APR of
        17.98% (which includes a 14.32% yearly interest rate and a 5% one-time
        origination fee), you would receive $9,500 in your account and would
        have a monthly payment of $343.33.
      </p>
      <p>
        Splash Financial, Inc. (NMLS #1630038), NMLS Consumer Access. Equal
        Housing Lender. Splash Financial, Inc. is licensed by the Department of
        Financial Protection & Innovation under the California Financing Law,
        license number 60DBO-102545. Splash® is a registered trademark of
        Splash Financial, Inc.
      </p>
    </div>
  </Flex>
);

const Heading = () => (
  <>
    Based on the information you've provided, it seems that a{' '}
    <strong>Personal Loan offered through Splash</strong> might be the best
    option for your renovation project!
  </>
);

const OfferHeader = () => (
  <>
    <OfferHeading>Personal Loan</OfferHeading>
    <OfferProvider>
      <OfferedByText>offered through</OfferedByText>
      <Image src={splash} />
    </OfferProvider>
  </>
);

const Testimonial = () => (
  <TestimonialContainer>
    <Image src={splashAward} margin={'15px'} />
  </TestimonialContainer>
);

const DescriptionSectionHeading = () => (
  <>
    <strong>
      <mark>Up to $100,000</mark>
    </strong>
    &nbsp;for&nbsp;
    <strong>qualified borrowers</strong>
  </>
);

const CallToAction = ({leadId}) => (
  <CallToActionSection>
    <Button
      onClick={() => {
        sendEvent('POS/Splash-Page-Button-Click');
        window.open(`${SPLASH_URL}?sharedId=${leadId}`, '_blank');
      }}>
      Check my rate
    </Button>
  </CallToActionSection>
);

const SplashTurndown = () => {
  const {id} = useParams();
  const {TURNDOWN_PARTNERS} = constants;

  useSetPresentedTurndownOption(TURNDOWN_PARTNERS.SPLASH);

  useNoFooter();
  useEffect(() => sendEvent('POS/Splash-Page'), []);

  return (
    <>
      <HeroSection heading={<Heading />}>
        <OfferWrapper>
          <Offer
            benefitItems={BENEFIT_ITEMS}
            header={<OfferHeader />}
            description={'Get the money you need for your renovation, fast! '}
            testimonial={<Testimonial />}
            callToAction={<CallToAction leadId={id} />}
          />
        </OfferWrapper>
      </HeroSection>
      <DescriptionSection heading={<DescriptionSectionHeading />}>
        <Box width={[1, 1]}>
          <DescriptionText>
            Splash partners with banks, credit unions and other lending partners
            to bring you competitive rates. Getting a personal loan through
            Splash allows you to compare rates and save time, choose the amount
            you need, and enjoy a single monthly payment.
          </DescriptionText>
        </Box>
      </DescriptionSection>
      <HowItWorksSection
        title={
          <>
            Get a Personal Loan in <mark>3 easy steps:</mark>
          </>
        }
        steps={STEPS}
        callToAction={<CallToAction leadId={id} />}
        disclaimer={<Disclaimer />}
      />
    </>
  );
};

export default SplashTurndown;
