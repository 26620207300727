import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {PanelWhite} from '../Panel';
import Radio from '../Radio';
import Checkbox from '../Checkbox';

function CheckboxTitle({
  label,
  onToggle,
  value = false,
  keepHover,
  disabled,
  radio,
  greyOut,
}) {
  const commonProps = {
    checked: value,
    disabled: disabled,
    onChange: () => {},
    value: value,
  };

  return (
    <PanelWhite
      disabled={disabled}
      onClick={disabled ? () => {} : onToggle}
      mt="8px"
      padding={24}
      noHover={keepHover === true ? false : value}
      css={{position: 'relative', zIndex: 13}}>
      <Flex alignItems="center" opacity={!value && greyOut ? '0.3' : '1'}>
        {radio ? <Radio {...commonProps} /> : <Checkbox {...commonProps} />}
        <Text fontSize={18} fontWeight={400} mb="-6px" ml={18} textAlign="left">
          {label}
        </Text>
      </Flex>
    </PanelWhite>
  );
}

CheckboxTitle.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  onToggle: PropTypes.func,
  value: PropTypes.bool,
  keepHover: PropTypes.bool,
  disabled: PropTypes.bool,
  radio: PropTypes.bool,
  greyOut: PropTypes.bool,
};

export default CheckboxTitle;
