import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Text} from 'rebass';
import {every, isNil} from 'lodash';

import {
  Form,
  Label,
  Panel,
  TextField,
  Button,
  Toggle,
  PhoneField,
} from '@renofi/components';
import {useNoFooter} from '@renofi/utils';

const Chilipiper = ({onReady, lead, embed, title, formTitle, buttonTitle}) => {
  const [showForm, setShowForm] = useState(!isLeadValid());
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isLeadValid()) return;
    let timeout = setTimeout(() => {
      onReady(lead);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useNoFooter();

  function isLeadValid() {
    return lead.email && lead.firstName && lead.lastName;
  }

  function onInputChange(key, value, err) {
    setFormData({...formData, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function onSubmit() {
    setShowForm(false);
    onReady(formData);
  }

  function isFormValid() {
    return (
      every(errors, isNil) &&
      formData.firstName &&
      formData.lastName &&
      formData.phone &&
      formData.phone.length === 10 &&
      formData.email
    );
  }

  if (embed)
    return (
      <Box mx={[0, -100]} mt={[20, 0]} height={660}>
        <div
          id="chilipiper-wrapper"
          style={{height: '100%', position: 'relative'}}></div>
      </Box>
    );

  return (
    <Panel
      white
      css={{width: ['100%', 1030], margin: ['0 auto', '20px auto 0 auto']}}>
      <Box px={[0, 0]} height={[720, 690]} width="100%">
        <Text my={20} fontSize={[24, 28]} lineHeight={['28px', '36px']}>
          {showForm
            ? formTitle ||
              'Before scheduling a call please provide some basic details'
            : title ||
              'Select a date and time for the call using the calendar below'}
        </Text>
        <div
          id="chilipiper-wrapper"
          style={{position: 'relative', minWidth: 320}}
          data-processed="true"></div>
        <Toggle show={showForm}>
          <Form onSubmit={onSubmit} id="chilipiper-lead-form">
            <Flex
              flexDirection="column"
              width={['100%', 400]}
              mx="auto"
              py={40}>
              <Box mb={20}>
                <Label small css={{fontSize: 16}} htmlFor="first-name">
                  First name
                </Label>
                <TextField
                  required
                  noSpecialCharacters
                  name="first-name"
                  id="first-name"
                  value={formData.firstName}
                  onChange={(value, err) =>
                    onInputChange('firstName', value, err)
                  }
                />
              </Box>

              <Box mb={20}>
                <Label small css={{fontSize: 16}} htmlFor="last-name">
                  Last name
                </Label>
                <TextField
                  required
                  noSpecialCharacters
                  name="last-name"
                  id="last-name"
                  value={formData.lastName}
                  onChange={(value, err) =>
                    onInputChange('lastName', value, err)
                  }
                />
              </Box>

              <Box mb={20}>
                <Label small css={{fontSize: 16}} htmlFor="email">
                  Email
                </Label>
                <TextField
                  required
                  email
                  icon="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={(value, err) => onInputChange('email', value, err)}
                />
              </Box>

              <Box mb={40}>
                <Label small css={{fontSize: 16}} htmlFor="email">
                  Phone
                </Label>
                <PhoneField
                  required
                  value={formData.phone}
                  onChange={(value, err) => onInputChange('phone', value, err)}
                  id="phone"
                  name="phone"
                  type="tel"
                />
              </Box>

              <Button disabled={!isFormValid()}>
                {buttonTitle || 'Schedule a call'}
              </Button>
            </Flex>
          </Form>
        </Toggle>
      </Box>
    </Panel>
  );
};

Chilipiper.propTypes = {
  onReady: PropTypes.func.isRequired,
  lead: PropTypes.object,
  embed: PropTypes.bool,
  title: PropTypes.string,
  formTitle: PropTypes.string,
  buttonTitle: PropTypes.string,
};

export default Chilipiper;
