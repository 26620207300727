import {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {
  teardown as teardownEnums,
  land as landEnums,
} from '@renofi/api/src/enums';
import {sendEvent} from '@renofi/analytics';
import {
  TeardownIcon,
  PartialTeardownIcon,
  NoStructureIcon,
} from '@renofi/components';
import {useRenovationUtils} from '@renofi/utils';

import {useNext} from '../../hooks';
import {Buttons, Heading, Narrow} from '../../components';
import IconSelect from '../../components/IconSelect';
import {setLead, leadVar} from '../../api/cache';

import TeardownInfo from './TeardownInfo';

const {teardown, partial_teardown, no_structure} = teardownEnums;
const {owned, to_be_purchased} = landEnums;

const options = [
  {
    title: `I'm tearing down everything`,
    value: teardown,
    icon: TeardownIcon,
  },
  {
    title: 'Parts of the structure will remain (eg. foundation)',
    value: partial_teardown,
    icon: PartialTeardownIcon,
  },
  {
    title: 'There are currently no structures on the land',
    value: no_structure,
    icon: NoStructureIcon,
  },
];

function CompleteTearDown({updateScenario}) {
  const next = useNext();
  const {existingStructuresPlan, propertyOwnershipStatus} =
    useReactiveVar(leadVar);
  const lead = useReactiveVar(leadVar);
  const {isHomeBuild} = useRenovationUtils(lead);

  useEffect(() => sendEvent('POS/Tear-Down-Page'), []);

  function onSubmit(existingStructuresPlan) {
    setLead({existingStructuresPlan});

    updateScenario({existingStructuresPlan});

    sendEvent('POS/Tear-Down-Entered', {
      existingStructuresPlan,
    });

    let path = '/renovation-type';
    if (isHomeBuild && propertyOwnershipStatus === owned)
      path = '/renovation-cost';
    if (isHomeBuild && propertyOwnershipStatus === to_be_purchased) {
      path = '/purchase-renovation-cost';
    }

    next(path);
  }

  return (
    <>
      <Narrow maxWidth={700}>
        <Heading>
          Are you demolishing any existing structures?
          <TeardownInfo />
        </Heading>
      </Narrow>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={existingStructuresPlan}
      />
      <Buttons
        disableNext={isNil(existingStructuresPlan)}
        onNext={() => onSubmit(existingStructuresPlan)}
        value={existingStructuresPlan}
      />
    </>
  );
}

CompleteTearDown.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default CompleteTearDown;
