import {makeVar} from '@apollo/client';

// Reactive variables
export const eligibilityVar = makeVar({
  propertyState: '',
  hasLicense: null,
  contractorLicense: null,
  noLicenseReasons: [],
  noLicenseReasonsOther: '',

  contractorGeneralLiabilityInsurance: null,

  contractorWorkersCompInsurance: null,
  hasWorkersCompInsurance: null,
  workersCompIncludedInGlid: false,
  noInsuranceReasons: [],
  noInsuranceReasonsOther: '',
});

// Setter
export const setEligibility = (eligibility) => {
  eligibilityVar({...eligibilityVar(), ...eligibility});
};

// Apollo field policy
export const eligibilityPolicy = {
  read() {
    return eligibilityVar();
  },
};

export default {
  eligibilityPolicy,
  setEligibility,
};
