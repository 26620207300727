import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Link, Box} from 'rebass';

import {Checkbox, Label, CheckboxLabel} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

const TCPAConsentCheckbox = ({onChange, checked}) => {
  return (
    <Flex alignItems="center" mb="5px">
      <Label css={{cursor: 'pointer'}}>
        <Box minWidth={32}>
          <Checkbox
            checked={checked}
            id="TCPCconsent"
            name="TCPCconsent"
            onChange={() => onChange({tcpaConsent: !checked})}
          />
        </Box>
        <CheckboxLabel fontSize={12} color={gray}>
          I consent to{' '}
          <Link
            fontSize={12}
            target="_blank"
            href="https://www.renofi.com/notices/electronic-records-and-signatures">
            Electronic Transactions/Signatures{' '}
          </Link>
          and to receive sales and marketing calls, text messages,
          artificial/prerecorded messages, and voicemails by or on behalf of
          RenoFi using an autodialer or any{' '}
          <Link
            fontSize={12}
            target="_blank"
            href="https://www.renofi.com/notices/sms-terms-and-conditions/">
            Regulated Equipment
          </Link>{' '}
          to the number(s) provided, even if included on any Do-Not-Call list,
          regarding home renovation financing options. Message frequency varies.
          Msg and data rates may apply. Reply to texts with HELP for help or
          support and STOP to stop receiving texts. I’m not required to provide
          this consent as a condition of purchasing any products or services.
          I’m authorized to provide this consent. I have read, understand, and
          agree to RenoFi’s{' '}
          <Link
            fontSize={12}
            target="_blank"
            href="https://www.renofi.com/notices/sms-terms-and-conditions/">
            SMS Terms and Conditions{' '}
          </Link>
          and{' '}
          <Link
            fontSize={12}
            target="_blank"
            href="https://www.renofi.com/notices/privacy/">
            Privacy Policy.{' '}
          </Link>
        </CheckboxLabel>
      </Label>
    </Flex>
  );
};

TCPAConsentCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  color: PropTypes.string,
};

export default TCPAConsentCheckbox;
