import {useState} from 'react';

import {useLongPress} from 'use-long-press';

import {useInterval} from '@renofi/utils';

const CHANGE_MULTIPLIER_LIMIT = 8;

const useLongButtonPress = ({onChange}) => {
  const [delay, setDelay] = useState(null);
  const [multiplier, setMultiplier] = useState(1);

  function onFinish() {
    onChange(multiplier);
    setDelay(null);
    setMultiplier(1);
  }

  function onLongPress() {
    setDelay(100);
  }

  useInterval(() => {
    setMultiplier(
      multiplier < CHANGE_MULTIPLIER_LIMIT ? multiplier + 1 : multiplier,
    );
    return onChange(multiplier);
  }, delay);

  const longPressHandlers = useLongPress(onLongPress, {
    onFinish: onFinish,
  });

  return {multiplier, longPressHandlers};
};
export default useLongButtonPress;
