import {Flex} from 'rebass';

import {lightGray} from '@renofi/utils/src/colors';
import {Loader} from '@renofi/components';

const PageLoader = () => (
  <Flex height="100%" pt={130} justifyContent="center" alignItems="top">
    <Loader color={lightGray} size={1.5} />
  </Flex>
);

export default PageLoader;
