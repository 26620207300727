import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {fadeBlue} from '@renofi/utils/src/colors';

export const OptionsContainer = styled.div(
  mediaQuery({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    boxShadow: ['0px 2px 8px rgba(0, 0, 0, 0.2)', 'none'],
    marginBottom: [24, 0],
  }),
);

export const CheckboxWrapper = styled(Flex)(
  mediaQuery({
    display: 'flex',
    marginBottom: [0, 16],
    alignItems: 'center',
    height: [52, 'auto'],
    padding: ['0 16px', 0],
  }),
  ({checked, numberOfColumns}) =>
    mediaQuery({
      background: [checked ? fadeBlue : 'transparent', 'transparent'],
      width: ['100%', 100 / numberOfColumns + '%'],
    }),
);
