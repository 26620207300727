import PropTypes from 'prop-types';

const Form = ({children, onSubmit, ...props}) => {
  const getSubmitHandler = (onSubmit) => (event) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <form
      style={{display: 'block', width: '100%'}}
      onSubmit={getSubmitHandler(onSubmit)}
      {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
