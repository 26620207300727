const InfoIcon = ({size = 20, color = '#CCCCCC'}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.1667C15.0626 19.1667 19.1667 15.0627 19.1667 10.0001C19.1667 4.93747 15.0626 0.833416 10 0.833416C4.93743 0.833416 0.833374 4.93747 0.833374 10.0001C0.833374 15.0627 4.93743 19.1667 10 19.1667ZM10 17.7917C5.69682 17.7917 2.20837 14.3033 2.20837 10.0001C2.20837 5.69686 5.69682 2.20842 10 2.20842C14.3033 2.20842 17.7917 5.69686 17.7917 10.0001C17.7917 14.3033 14.3033 17.7917 10 17.7917ZM9.08339 5.41675H10.9167V7.25008H9.08339V5.41675ZM9.08339 9.08344H10.9167V14.5834H9.08339V9.08344Z"
      fill={color}
    />
    <path d="M10.9167 9.08344H9.08339V14.5834H10.9167V9.08344Z" fill={color} />
    <path d="M10.9167 5.41675H9.08339V7.25008H10.9167V5.41675Z" fill={color} />
  </svg>
);

export default InfoIcon;
