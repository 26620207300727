import styled from '@emotion/styled/macro';
import {
  Text as RebassText,
  Image as RebassImage,
  Flex as RebassFlex,
} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

export const Wrapper = styled(RebassFlex)(
  mediaQuery({
    position: 'relative',
    flexDirection: 'column',
    borderRadius: 10,
    margin: '0 7px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 30px 0 30px',
    marginBottom: [20, 0],
    width: ['95%', 263],
    height: 217,
  }),
);

export const Image = styled(RebassImage)(
  mediaQuery({
    maxHeight: 100,
    maxWidth: 180,
  }),
);

export const Text = styled(RebassText)({
  lineHeight: '23px',
  fontSize: 18,
  color: darkBlue,
  textDecoration: 'none',
});
