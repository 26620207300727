import styled from '@emotion/styled';
import {Box, Flex} from 'rebass';

import {Button} from '@renofi/components';
import {mediaQuery} from '@renofi/utils';

import {MOBILE_HEIGHT} from './components/BottomBanner';

export const TOP_PADDING_OFFSET = 95;

export const Wrapper = styled(Flex)({
  position: 'relative',
  paddingTop: TOP_PADDING_OFFSET,
  // paddingBottom: 115,
});

export const CalculatorContainer = styled(Box)(
  mediaQuery({
    width: [390, 512],
    position: 'relative',
    top: [MOBILE_HEIGHT * -1, 'auto'],
    marginLeft: [0, 135],
    marginTop: [MOBILE_HEIGHT, 'inherit'],

    background: '#fff',
    border: '8px solid #e9eff2',
    borderRadius: '16px 16px 0 0',
  }),
);

export const CtaButton = styled(Button)(
  mediaQuery({
    position: 'absolute',

    bottom: [0, 'auto'],
    left: [16, 'auto'],
    width: ['calc(100vw - 32px)', 'auto'],

    right: ['auto', 290],
    top: ['auto', `calc(100% - ${MOBILE_HEIGHT + 30}px)`],
  }),
);
