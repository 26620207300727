import React from 'react';

import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {getItem} from '@renofi/utils/src/cookies';
import {Button, renofiLogoDarkIconSrc} from '@renofi/components';
import {getEnvironmentUrl} from '@renofi/utils';
import {DASHBOARD_REDIRECT_READY_KEY} from '@renofi/utils/src/lead';
import {sendEvent} from '@renofi/analytics';

import {leadVar} from '../../api/cache';
import {useRedirectToDashboard} from '../../hooks';

import PartnerLogo from './PartnerLogo';
import {Container, Logo, LogoContainer} from './styled';

const Header = () => {
  const lead = useReactiveVar(leadVar);
  const dashboardRedirectReady = getItem(DASHBOARD_REDIRECT_READY_KEY);
  const redirectToDashboard = useRedirectToDashboard();

  function onDashboardClick() {
    sendEvent('POS/My-Dashboard-Button-Clicked');
    redirectToDashboard(lead.id, lead.prequalCompletedAt);
  }

  function redirectToLogin() {
    const loginAppUrl = getEnvironmentUrl('login', '4004');
    sendEvent('POS/Sign-In-Link-Clicked');
    window.location.assign(loginAppUrl);
  }

  return (
    <Container>
      <LogoContainer>
        <Logo
          src={renofiLogoDarkIconSrc}
          alt="RenoFi"
          center
          width={120}
          height={32}
        />

        <PartnerLogo />
      </LogoContainer>
      <Flex alignItems="center">
        {dashboardRedirectReady ? (
          <Button
            id="dashboard-button"
            width="auto"
            xSmall
            onClick={onDashboardClick}>
            My dashboard
          </Button>
        ) : (
          <Button
            noCaps
            transparent
            onClick={redirectToLogin}
            css={{color: '#000', fontSize: 16}}>
            Sign in
          </Button>
        )}
      </Flex>
    </Container>
  );
};

export default Header;
