import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

import {Heading} from '../../../components';

import clouds from './img/clouds.svg';

export const DescriptionContainer = styled.div(
  mediaQuery({
    backgroundColor: '#E7F9E9',
    backgroundImage: `url(${clouds})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom',
    padding: ['24px', '48px 0 102px 0'],
  }),
);

export const SectionHeading = styled(Heading)({
  textAlign: 'center',
  '& mark': {
    backgroundColor: 'transparent',
    color: '#FF5253',
  },
});
