/* eslint-disable no-useless-escape */
import {filterEmptyValues, mapObjectValues} from './object';

const phoneRegex = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/i; // ruby email regex converted to js
//const emailRegex = /^[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+$/i; // old one used in acquisition
const renofiEmailRegex =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@renofi.com$/;

const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/)?$/;

export const isEmail = (value) => emailRegex.test(value);
export const isRenofiEmail = (value) => renofiEmailRegex.test(value);

export const isPhone = (value) => phoneRegex.test(value);

export function validateRequired(formData) {
  const invalidValues = filterEmptyValues(formData);
  return mapObjectValues(invalidValues, () => true);
}

export const hasNumbers = (string) => /\d/.test(string);

export const isUrl = (value) => urlRegex.test(value);

export const hasUrlProtocol = (value) =>
  value?.match(/^(ftp|http|https):\/\//i);

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
