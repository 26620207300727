import React, {useEffect} from 'react';

import {Box} from 'rebass';

import {useSetPresentedTurndownOption} from '@renofi/utils/src/turndowns';
import {useNoFooter, constants} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import HeroSection from '../Components/HeroSection/HeroSection';
import Offer from '../Components/Offer/Offer';
import DescriptionSection from '../Components/DescriptionSection/DescriptionSection';
import HowItWorksSection from '../Components/HowItWorksSection/HowItWorksSection';

import {
  TestimonialContainer,
  TestimonialText,
  BurbankImage,
  PointImage,
  HomeEquityText,
  OfferedByText,
  OfferProvider,
  DescriptionText,
  OfferWrapper,
} from './styled';
import pointIcon from './img/point.svg';
import burbankIcon from './img/burbank.svg';
import PointCTA from './PointCTA';

const STEPS = [
  {
    title: 'Get pre-approved',
    description: 'Find out if you qualify in just a few steps',
  },
  {
    title: 'Submit your application',
    description: 'And attach the required documents',
  },
  {
    title: 'Meet your Account Manager',
    description: 'To review and accept your offer',
  },
  {
    title: 'Schedule an appraisal',
    description: 'To find out how much your home is worth',
  },
  {
    title: 'Get your money',
    description: 'Close the HEI and receive your money!',
  },
];

const BENEFIT_ITEMS = [
  'No monthly payments',
  'No interest rates',
  'No income requirements',
  'No need for perfect credit',
  'No prepayment penalty',
  '30 year term',
];

const DISCLAIMER =
  'Home equity investment products offered by Point. RenoFi is not affiliated with Point and is not responsible for Point’s products or services, the content or operation of Point’s website, or how Point handles or uses your information. Information you provide to Point is subject to its privacy policy and website terms of use. RenoFi receives compensation through Point for investment projects funded through this program. Point’s products not available in all states or situations, and not all homeowners will qualify.';

const Heading = () => (
  <>
    Based on the information you&apos;ve provided, it seems that a{' '}
    <strong>Home Equity Investment</strong> might be the best option for your
    renovation project!
  </>
);

const OfferHeader = () => (
  <>
    <HomeEquityText>Home Equity Investment</HomeEquityText>
    <OfferProvider>
      <OfferedByText>offered by</OfferedByText>
      <PointImage src={pointIcon} />
    </OfferProvider>
  </>
);

const Testimonial = () => (
  <>
    <TestimonialContainer>
      <TestimonialText>
        &quot;The banks required a lot more to qualify for their loans. Point
        came in and said, here&apos;s an option for you&quot;
      </TestimonialText>
      <BurbankImage src={burbankIcon} />
    </TestimonialContainer>
    <PointCTA />
  </>
);

const DescriptionSectionHeading = () => (
  <>
    <strong>
      <mark>What is</mark>
      &nbsp;Point
    </strong>
    &nbsp;and <strong>Home Equity Investment</strong>
  </>
);

const PointTurndown = () => {
  const {TURNDOWN_PARTNERS} = constants;

  useSetPresentedTurndownOption(TURNDOWN_PARTNERS.POINT);

  useNoFooter();
  useEffect(() => sendEvent('POS/Point-Page'), []);

  return (
    <>
      <HeroSection heading={<Heading />}>
        <OfferWrapper>
          <Offer
            benefitItems={BENEFIT_ITEMS}
            header={<OfferHeader />}
            description={
              'Exchange the equity in your house for the cash you need to renovate'
            }
            testimonial={<Testimonial />}
          />
        </OfferWrapper>
      </HeroSection>
      <DescriptionSection heading={<DescriptionSectionHeading />}>
        <Box width={[1, 1 / 2]}>
          <DescriptionText>
            <strong>Point</strong> provides an entirely new way to unlock your
            home&apos;s wealth&nbsp;
            <strong>without taking in additional debt</strong> and worrying
            about monthly payments.
          </DescriptionText>
        </Box>
        <Box width={[1, 1 / 2]}>
          <DescriptionText>
            A <strong>HEI</strong> is an investment in a slice of your home
            equity, allowing you&nbsp;
            <strong>
              to get up to $500,000 in cash. Point doesn&apos;t get added to the
              title of your property!
            </strong>
          </DescriptionText>
        </Box>
      </DescriptionSection>
      <HowItWorksSection
        steps={STEPS}
        callToAction={<PointCTA />}
        disclaimer={DISCLAIMER}
      />
    </>
  );
};

export default PointTurndown;
