import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {AUTHENTICATE_BY_TOKEN} from '../mutations/authenticateByToken';

export default function useAuthenticateByToken({onCompleted = noop} = {}) {
  const [authenticateByToken, {data, loading, error}] = useMutation(
    AUTHENTICATE_BY_TOKEN,
    {onCompleted},
  );
  const response = data?.authenticateByToken;
  return {authenticateByToken, response, loading, error};
}
