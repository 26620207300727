import React from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';
import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {Button, MODAL_ZINDEX} from '@renofi/components';

const Footer = styled(Flex)(
  mediaQuery({
    background: '#f8f8f8',
    boxShadow: '0px 40px 40px rgba(68, 68, 68, 0.11)',
    padding: '18px 16px',
    margin: 0,
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: MODAL_ZINDEX,
    width: '100%',
  }),
);

export const MobileFooter = ({onSubmit}) => {
  return (
    <Footer justifyContent="flex-end">
      <Button css={{width: 160}} onClick={onSubmit}>
        Let&apos;s Begin
      </Button>
    </Footer>
  );
};

MobileFooter.propTypes = {
  onSubmit: PropTypes.func,
};
