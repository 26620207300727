import styled from '@emotion/styled/macro';
import {Text, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import landscape from './img/landscape.svg';

export const HeroContainer = styled.div(
  mediaQuery({
    backgroundImage: `url(${landscape})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom',
    padding: '56px 24px 160px 24px',
  }),
);

export const HeroSectionWrapper = styled(Flex)(() =>
  mediaQuery({
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const HeroSectionHeading = styled(Text)(() =>
  mediaQuery({
    padding: [0, '0 10%'],
    fontSize: ['24px', '32px'],
    lineHeight: ['29px', '48px'],
    textAlign: 'center',
    marginBottom: ['32px', '48px'],
  }),
);
