import {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {stageOfPurchase as enums} from '@renofi/api/src/enums';
import {sendEvent} from '@renofi/analytics';
import {useRenovationUtils} from '@renofi/utils';

import {setLead, leadVar, loanProductsVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';
import {ReactComponent as SingleIcon} from '../HomeType/img/single.svg';
import {ReactComponent as TownhouseIcon} from '../HomeType/img/townhouse.svg';
import {ReactComponent as RowIcon} from '../HomeType/img/row-home.svg';
import {ReactComponent as MultiIcon} from '../HomeType/img/multi.svg';
import {ReactComponent as CoopIcon} from '../HomeType/img/coop.svg';
import {ReactComponent as CondoIcon} from '../HomeType/img/condo.svg';
import {ReactComponent as MixedIcon} from '../HomeType/img/mixed.svg';

const options = [
  {
    title: 'Single-family home',
    value: 'single_family',
    icon: SingleIcon,
    tooltip:
      'A single-family home is a free-standing home on its own property. Most suburban homes fit this description.',
  },
  {
    title: 'Townhouse',
    value: 'townhouse',
    icon: TownhouseIcon,
    tooltip:
      'Townhouses are a style of multi-floor home that share one to two ' +
      'walls with adjacent properties but have their own entrances.',
  },
  {
    title: 'Row home',
    value: 'row_home',
    icon: RowIcon,
    tooltip:
      'Row homes are two or more identical or nearly identical units that ' +
      'share a common wall on one or both sides of the unit.',
  },
  {
    title: 'Condo',
    value: 'condo',
    icon: CondoIcon,
    tooltip:
      'Condos are privately-owned individual units within a community of other ' +
      'units. Most are within high rise buildings, but some are detached.',
  },
  {
    title: 'Co-op',
    value: 'co_op',
    icon: CoopIcon,
    tooltip:
      'A co-op is residential housing by a corporation whereby the ' +
      'owners do not own their units outright and rather own a share of the building.',
  },
  {
    title: 'Multi-unit home',
    value: 'multi_unit',
    icon: MultiIcon,
    tooltip:
      'A multi-unit home (also known as multi family or duplex) is part of ' +
      'multiple separate housing units for residential inhabitants that are ' +
      'contained within one building or several buildings within one complex.',
  },
  {
    title: 'Mixed use',
    value: 'mixed_use',
    icon: MixedIcon,
    tooltip:
      'A mixed use property is a property which has multiple use classes for ' +
      'different floors or areas of the building, like commercial and residential.',
  },
];

function HomeType({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {propertyStructureType, stageOfPurchase, demolishExistingStructure} =
    lead;
  const {isPurchase, isHomeBuild} = useRenovationUtils(lead);
  const loanProducts = useReactiveVar(loanProductsVar) || [];
  useEffect(() => sendEvent('POS/Home-Type-Page'), []);

  function valueIsNotSuppoerted(value) {
    return loanProducts.length < 1 && ['mixed_use', 'co_op'].includes(value);
  }

  function valueHasDedicatedScreen(value) {
    return value?.includes('condo') || value?.includes('multi_unit');
  }

  function isSelected(option, compare) {
    if (option.value === 'condo')
      return propertyStructureType?.includes('condo');
    if (option.value === 'multi_unit')
      return propertyStructureType?.includes('multi_unit');
    return compare(option, propertyStructureType);
  }

  function getPath(value) {
    if (value.includes('multi_unit')) return '/renovation-type/multi-unit';
    if (valueIsNotSuppoerted(value)) return '/renovation-type/message';
    return '/property-use';
  }

  function onSubmit(propertyStructureType) {
    if (!valueHasDedicatedScreen(propertyStructureType)) {
      updateScenario({propertyStructureType});
    }
    setLead({propertyStructureType});
    sendEvent('POS/Home-Type-Entered', {propertyStructureType});
    const path = getPath(propertyStructureType);
    next(path);
  }

  function title() {
    if (isPurchase && stageOfPurchase !== enums.closed_not_moved_in)
      return 'I’m purchasing and renovating a';
    if (isHomeBuild && demolishExistingStructure !== false)
      return 'What type of home are you building?';

    return 'What type of home are you renovating?';
  }

  return (
    <>
      <Heading>{title()}</Heading>
      <IconSelect
        isSelected={isSelected}
        onClick={onSubmit}
        options={options}
        value={propertyStructureType}
      />
      <Buttons
        disableNext={isNil(propertyStructureType)}
        onNext={() => onSubmit(propertyStructureType)}
        value={propertyStructureType}
      />
    </>
  );
}

HomeType.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default HomeType;
