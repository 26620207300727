import React from 'react';
import {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {useCookies} from '@renofi/utils';

import {LegalMessage} from './styled';

const getTimeZone = () => Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

const NEW_YORK_TIMEZONE = 'America/New_York';

const FooterLegalMessage = ({center}) => {
  const {cookie, updateCookie} = useCookies('timezone', '');
  const [timeZone, setTimeZone] = useState(''); // Use state to store the region

  useEffect(() => {
    if (!cookie) {
      const timeZone = getTimeZone();

      if (timeZone === NEW_YORK_TIMEZONE) {
        updateCookie({value: NEW_YORK_TIMEZONE});
        setTimeZone(NEW_YORK_TIMEZONE);
      }
    }
  }, [cookie]);

  return (
    <LegalMessage center={center}>
      <p>
        RenoFi operates as Renovation Technologies Holdings Inc. in California
        (NMLS # 2412747), Renovation Technologies LLC in Nebraska, and RenoFi
        LLC in New Mexico.
      </p>
      {(cookie || timeZone) === NEW_YORK_TIMEZONE && (
        <p>
          <strong>
            This site is not authorized by the New York State Department of
            Financial Services. No mortgage solicitation activity or loan
            applications for properties located in the State of New York can be
            facilitated through this site.
          </strong>
        </p>
      )}
    </LegalMessage>
  );
};

FooterLegalMessage.propTypes = {
  center: PropTypes.bool,
};

export default FooterLegalMessage;
