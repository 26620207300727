import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {red} from '@renofi/utils/src/colors';
import {CurrencyField, CheckboxPanel} from '@renofi/components';

import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

function Mortgage({onChange, onToggle, required, noDebt}) {
  const {primaryMortgagePayment} = useReactiveVar(leadVar);
  const {isMortgageGroupOpen} = useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Primary mortgage payment"
      onToggle={onToggle}
      value={isMortgageGroupOpen}
      disabled={required}
      greyOut={noDebt}>
      <Flex>
        <Box width={1 / 2} mt={required ? 16 : '8px'}>
          <SideLabel>
            Monthly mortgage payments on the property, including taxes and
            insurance
          </SideLabel>
        </Box>
        <Box width={1 / 2} mt={required ? 16 : '8px'}>
          <CurrencyField
            topMessage={required && !primaryMortgagePayment ? '* required' : ''}
            topMessageColor={red}
            aria-label="Total monthly mortgage payments"
            errMessages={{
              max: `You have entered $${toCurrency(
                primaryMortgagePayment,
              )} as your monthly primary mortgage payment. If that's incorrect, please update, otherwise continue.`,
            }}
            errorCss={{position: 'static'}}
            max={10000}
            icon
            value={primaryMortgagePayment}
            onChange={(value) => onChange('primaryMortgagePayment', value)}
            analyticsEnabled
            analyticsPrefix="POS"
          />
        </Box>
      </Flex>
    </CheckboxPanel>
  );
}

Mortgage.propTypes = {
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isPurchase: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default Mortgage;
