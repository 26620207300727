import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export default styled(Box)(
  mediaQuery({
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  ({maxWidth, css}) =>
    mediaQuery({
      maxWidth: maxWidth || 366,
      ...css,
    }),
);
