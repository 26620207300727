import {useQuery} from '@apollo/client';

import {HOME_VALUATION_ESTIMATE} from '../queries/homeValuationEstimate';

export default function useHomeValuationEstimate() {
  const {refetch} = useQuery(HOME_VALUATION_ESTIMATE, {
    skip: true,
    fetchPolicy: 'network-only',
  });
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
