import {InMemoryCache} from '@apollo/client';

import {leadPolicy as lead} from './lead';
import {paymentSelectionsPolicy as paymentSelections} from './paymentSelections';
import {loanProductsPolicy as loanProducts} from './loanProducts';
import {progressPolicy as progress} from './progress';
import {earlyOptionsV2Policy as earlyOptionsV2} from './earlyOptionsV2';
import {earlyOptionsPolicy as earlyOptions} from './earlyOptions';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        lead,
        paymentSelections,
        progress,
        loanProducts,
        earlyOptions,
        earlyOptionsV2,
      },
    },
  },
});
