import {throttle} from '@renofi/utils';

const useSliderActions = ({
  infinite = false,
  min,
  max,
  step,
  decreaseStep,
  value,
  onChange,
}) => {
  function throttleChange(newValue) {
    if (newValue < min) return onChange(min);
    throttle(() => onChange(newValue));
  }

  function onIncrease(index) {
    const newValue = value + step * index;
    onChange(newValue >= max && !infinite ? max : newValue);
  }

  function onDecrease(index) {
    const newValue = value - (decreaseStep || step) * index;
    onChange(value <= min ? min : newValue);
  }

  return {
    throttleChange,
    onIncrease,
    onDecrease,
  };
};

export default useSliderActions;
