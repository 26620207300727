import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const ButtonSelectWrapper = styled(Flex)(
  {
    width: '100%',
  },
  ({inline, internal, css, buttonCss}) => {
    const radius = internal ? 4 : 8;
    return mediaQuery({
      flexDirection: inline ? 'row' : 'column',
      justifyContent: 'center',
      ...css,
      Button: {
        marginTop: [10, inline ? 0 : 10],
        ...(inline ? {borderRadius: 0} : {}),
        ':not(:first-of-type)': {
          ...(inline ? {borderLeft: 'none'} : {}),
        },
        ':first-of-type': {
          ...(!inline ? {marginTop: 0} : {}),
          ...(inline ? {borderRadius: `${radius}px 0 0 ${radius}px`} : {}),
        },
        ':last-of-type': {
          ...(inline ? {borderLeft: 'none'} : {}),
          ...(inline ? {borderRadius: `0 ${radius}px ${radius}px 0`} : {}),
        },
        ...buttonCss,
      },
    });
  },
);
