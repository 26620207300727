import {useEffect} from 'react';

import {constants} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

const useFeatureFlagEvents = (client, prefix) => {
  const reduceFlags = () => {
    return client.getAllToggles().reduce((acc, current) => {
      // 'disabled' is a default variant in Unleash returned if no variants are defined
      if (current.variant.name === 'disabled') {
        return {...acc, [current.name]: current.enabled && 'enabled'};
      }

      return {...acc, [current.name]: current.variant.name};
    }, {});
  };

  useEffect(() => {
    client.once('ready', () => {
      const enabledToggles = reduceFlags();
      sendEvent(`${prefix}/Initial-Feature-Flags-Enabled`, {...enabledToggles});
    });
  }, []);

  useEffect(() => {
    client.on('update', () => {
      const enabledToggles = reduceFlags();
      sessionStorage.setItem(
        constants.SESSION_FEATURE_FLAGS_STORAGE_KEY,
        JSON.stringify(enabledToggles),
      );
    });
  }, []);
};

export default useFeatureFlagEvents;
