import {logger} from './index';

export function webToCase(lead) {
  const IS_PROD = process.env.REACT_APP_ENV === 'production';
  const formUrl = process.env.REACT_APP_SALESFORCE_WEB_FORM_URL;

  const slo1 = 'madelyn@renofi.com';
  const orgId = IS_PROD ? '00Df4000004Jw0F' : '00D21000000Aezq';
  const scenarioIdField = IS_PROD ? '00N8Y00000HXTvh' : '00NDl00000TfwL5';

  const caseData = {
    orgid: orgId,
    scenarioid: lead.id,
    name: lead.firstName,
    subject: `GC Match Request`,
    description: lead.firstName + ' is looking for a contractor',
  };

  const casePayload = {
    ...caseData,
    '00N2100000NXcgq': slo1,
    [scenarioIdField]: lead.id,
  };

  const requestOptions = {
    method: 'POST',
    mode: 'no-cors',
  };

  fetch(`${formUrl}&${createQueryFromInputs(casePayload)}`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      if (error.toString() !== 'SyntaxError: Unexpected end of input') {
        logger.error(error);
      }
    });
}
function createQueryFromInputs(inputs) {
  return Object.keys(inputs)
    .map(function (key) {
      return `${key}=${encodeURIComponent(inputs[key])}`;
    })
    .join('&');
}
