import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export const SideLabel = styled(Text)(
  mediaQuery({
    color: gray,
    lineHeight: '20px',
    textAlign: 'left',
    marginRight: 24,
    fontWeight: 300,
  }),
);
