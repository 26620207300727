import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {formatAddress, toCurrency} from '@renofi/utils/src/format';
import {getKey} from '@renofi/utils/src/object';
import {states} from '@renofi/utils';
import {Toggle} from '@renofi/components';

import {useNext} from '../hooks';
import {leadVar, setLead} from '../api/cache';
import {Buttons, Heading, Slider, SubHeading} from '../components';

function HomeValueEstimation({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {
    estimatedHomeValue,
    homeValue = estimatedHomeValue || 10000,
    location,
  } = lead;
  const shortStateCode = getKey(location?.state, states);

  useEffect(() => sendEvent('POS/Home-Value-Estimation-Page'), []);

  function onChange(value) {
    setLead({homeValue: value});
  }

  function onSubmit() {
    onChange(homeValue);
    updateScenario({homeValue});

    sendEvent('POS/Home-Value-Entered', {homeValue});
    next('/after-renovation-value');
  }

  return (
    <>
      <Heading>How much do you think your property is worth?</Heading>
      <Toggle show={Boolean(estimatedHomeValue)}>
        <SubHeading small>
          The estimated property valuation for{' '}
          {formatAddress({...location, state: shortStateCode})} is $
          {toCurrency(estimatedHomeValue)}
        </SubHeading>
      </Toggle>
      <Slider
        label="Your home value"
        infinite
        noTooltip
        min={10000}
        max={2000000}
        step={10000}
        onChange={onChange}
        value={homeValue || estimatedHomeValue}
      />
      <Buttons onNext={onSubmit} disableNext={homeValue < 10001} />

      <Toggle show={!estimatedHomeValue}>
        <SubHeading small mt={64}>
          We couldn't automatically find your property value using the address.
        </SubHeading>
      </Toggle>
    </>
  );
}

export default HomeValueEstimation;
