import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {useRenovationUtils} from '@renofi/utils';
import {
  stageOfPurchase as purchaseEnum,
  stageOfPurchase,
} from '@renofi/api/src/enums';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as Search} from './img/search.svg';
import {ReactComponent as Offers} from './img/offers.svg';
import {ReactComponent as OfferClosed} from './img/offer-closed.svg';
import {ReactComponent as OfferAccepted} from './img/offer-accepted.svg';
import {ReactComponent as LandOfferAccepted} from './img/land-offer-accepted.svg';

const {researching, making_offers, offer_accepted, closed_not_moved_in} =
  stageOfPurchase;

function PurchaseStage({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {isLandPurchase} = useRenovationUtils(lead);
  const options = [
    {
      title: `Just researching${
        isLandPurchase ? '' : ' / Going to open houses'
      }`,
      value: researching,
      icon: Search,
    },
    {
      title: 'I’m making offers',
      value: making_offers,
      icon: Offers,
    },
    {
      title: 'Offer accepted, closing soon',
      value: offer_accepted,
      icon: isLandPurchase ? LandOfferAccepted : OfferAccepted,
    },
    ...(isLandPurchase
      ? []
      : [
          {
            title: 'Closed, but not moved in',
            value: closed_not_moved_in,
            icon: OfferClosed,
          },
        ]),
  ];

  useEffect(() => sendEvent('POS/Purchase-Stage-Page'), []);

  function getPath(stageOfPurchase) {
    const isFinalizedPurchase =
      stageOfPurchase === purchaseEnum.offer_accepted ||
      stageOfPurchase === purchaseEnum.closed_not_moved_in;
    if (isLandPurchase) return '/land/downpayment';
    if (isFinalizedPurchase) return '/purchase/address';
    return '/purchase-price';
  }

  function onSubmit(stageOfPurchase) {
    setLead({stageOfPurchase});

    updateScenario({stageOfPurchase});

    sendEvent('POS/Purchase-Stage-Entered', {stageOfPurchase});
    const path = getPath(stageOfPurchase);
    next(path);
  }

  return (
    <>
      <Heading>The current stage of the purchase is</Heading>
      <IconSelect
        onClick={onSubmit}
        options={options}
        value={lead.stageOfPurchase}
      />
      <Buttons
        disableNext={isNil(lead.stageOfPurchase)}
        onNext={() => onSubmit(lead.stageOfPurchase)}
        value={lead.stageOfPurchase}
      />
    </>
  );
}

export default PurchaseStage;
