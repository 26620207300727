import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {green} from '@renofi/utils/src/colors';

export const SuccessMessage = styled(Text)(
  mediaQuery({
    color: green,
    fontSize: 20,
    textTransform: 'capitalize',
  }),
);
