import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {useRenovationUtils} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {Toggle} from '@renofi/components';
import {formatAddress, toCurrency} from '@renofi/utils/src/format';
import {getKey} from '@renofi/utils/src/object';
import {states} from '@renofi/utils';

import {Buttons, Heading, Slider, SubHeading} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';

function PurchasePrice({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {
    estimatedHomeValue,
    location,
    propertyPurchasePrice = estimatedHomeValue || 10000,
  } = lead;
  const {isHomeBuild, isFinalizedPurchase} = useRenovationUtils(lead);
  const shortStateCode = getKey(location?.state, states);

  useEffect(() => sendEvent('POS/Purchase-Price-Page'), []);

  function onChange(value) {
    setLead({
      propertyPurchasePrice: value,
    });
  }

  function onSubmit() {
    onChange(propertyPurchasePrice);

    updateScenario({
      propertyPurchasePrice,
    });

    sendEvent('POS/Purchase-Price-Entered', {propertyPurchasePrice});
    next('/downpayment');
  }

  function title() {
    if (isHomeBuild) return 'Estimated costs to acquire the property';
    if (isFinalizedPurchase) return 'What is the purchase price?';
    return 'The estimated purchase price for the home is around';
  }

  return (
    <>
      <Heading>{title()}</Heading>
      <Toggle show={Boolean(estimatedHomeValue)}>
        <SubHeading small>
          The estimated property valuation for{' '}
          {formatAddress({...location, state: shortStateCode})} is $
          {toCurrency(estimatedHomeValue)}
        </SubHeading>
      </Toggle>
      <Slider
        infinite
        noTooltip
        min={10000}
        max={2000000}
        step={10000}
        onChange={onChange}
        value={propertyPurchasePrice}
      />
      <Buttons onNext={onSubmit} disableNext={propertyPurchasePrice < 10001} />
    </>
  );
}

export default PurchasePrice;
