export default (value, targetLength) => {
  let str = String(value);
  if (str.length >= targetLength) {
    return Number(str);
  }

  const zerosToAdd = targetLength - str.length;

  for (let i = 0; i < zerosToAdd; i++) {
    str += '0';
  }

  return Number(str);
};
