import {makeVar} from '@apollo/client';

// Reactive variables
export const experienceVar = makeVar({
  yearsAsAContractor: null,
  numberOfCompletedRenovations: null,
  percentageCompletedRenovationsAmountRanges: [0, 0, 0, 0, 0],
  percentageBusinessWithResidentialInLast2y: null,
  percentageBusinessWithCommercialInLast2y: null,

  avgNumberOfRenovationsPerYear: 0,
  avgPriceOfProjects: 0,
  typicalRenovationProjects: [],
  typicalRenovationProjectsOtherDetails: '',

  hasBeenInvolvedInLawsuit: null,
  involvedInLawsuitDetails: '',
  hasTaxLiens: null,
  taxLiensDetails: '',
  hasEverFiledForBankruptcy: null,
  bankruptcyDetails: '',
});

// Setter
export const setExperience = (experience) => {
  experienceVar({...experienceVar(), ...experience});
};

// Apollo field policy
export const experiencePolicy = {
  read() {
    return experienceVar();
  },
};

export default {
  experiencePolicy,
  setExperience,
};
