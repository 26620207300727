import useValidateEmail from './useValidateEmail';
import useIsOpenOpportunity from './useIsOpenOpportunity';

const useEmailValidation = () => {
  const {validateEmail} = useValidateEmail();
  const checkIfOpenOpportunity = useIsOpenOpportunity();

  async function validate(email) {
    const validationPromise = validateEmail({variables: {email}});
    const opportunityPromise = checkIfOpenOpportunity({email});

    const [validationRsp, opportunityRsp] = await Promise.all([
      validationPromise,
      opportunityPromise,
    ]);
    const {data: validateEmailData, error: emailValidationError} =
      validationRsp;
    const isEmailValid = validateEmailData?.validateEmail?.valid;

    const {data: opportunityData, error: opportunityError} = opportunityRsp;
    const isOpenOpportunity = opportunityData?.isOpenOpportunity;

    return {
      isEmailValid,
      isOpenOpportunity,
      errors: {emailValidationError, opportunityError},
    };
  }

  return validate;
};

export default useEmailValidation;
