import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {CurrencyField, CheckboxPanel} from '@renofi/components';
import ButtonSelect from '@renofi/components/src/ButtonSelect';

import {Info} from '../../components';
import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

const options = {
  Yes: true,
  No: false,
};

function StudentLoan({onChange, onToggle, onPaymentSelectionsChange, noDebt}) {
  const {studentLoanPayment} = useReactiveVar(leadVar);
  const {isStudentLoanGroupOpen, studentLoanIsPaying} =
    useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Student loan payment"
      onToggle={onToggle}
      value={isStudentLoanGroupOpen}
      greyOut={noDebt}>
      <Flex mb={30}>
        <Box width={1 / 2}>
          <SideLabel>
            Are any of the applicants <strong>currently</strong> making{' '}
            <strong>monthly payments</strong> for student loans?
          </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <ButtonSelect
            value={studentLoanIsPaying}
            inline
            internal
            onClick={(value) =>
              onPaymentSelectionsChange('studentLoanIsPaying', value)
            }
            options={options}
          />
        </Box>
      </Flex>

      {studentLoanIsPaying && (
        <Flex mb={24}>
          <Box width={1 / 2}>
            <SideLabel>
              <strong>Monthly</strong> student loan payments for all applicants
            </SideLabel>
          </Box>
          <Box width={1 / 2}>
            <CurrencyField
              errMessages={{
                max: `You have entered $${toCurrency(
                  studentLoanPayment,
                )} as your monthly student loan payment. If that's incorrect, please update, otherwise continue.`,
              }}
              errorCss={{position: 'static'}}
              max={3000}
              data-testid="student-loan-payment"
              icon
              name="student-loan-payment"
              value={studentLoanPayment}
              onChange={(value) => onChange('studentLoanPayment', value)}
              analyticsEnabled
              analyticsPrefix="POS"
            />
          </Box>
        </Flex>
      )}
      {studentLoanIsPaying === false && (
        <Info>
          If none of the applicants are currently making monthly payments, it is
          excluded from your DTI calculation.
        </Info>
      )}
    </CheckboxPanel>
  );
}

StudentLoan.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPaymentSelectionsChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default StudentLoan;
