import {makeVar} from '@apollo/client';

// Reactive variables
export const earlyOptionsV2Var = makeVar(0);

// Setters
export const setEarlyOptionsV2 = (earlyOptionsCount) => {
  earlyOptionsV2Var(earlyOptionsCount);
};

// Apollo field policy
export const earlyOptionsV2Policy = {
  read() {
    return earlyOptionsV2Var();
  },
};
