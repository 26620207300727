import styled from '@emotion/styled';
import {Flex, Box} from 'rebass';

export const LeadDetailsStatement = styled(Flex)({
  marginTop: '23px',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#737373',
});

export const EditButton = styled(Box)({
  marginLeft: '10px',
  '&:hover': {
    cursor: 'pointer',
  },
});
