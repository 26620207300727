import {constants} from '@renofi/utils';
import {stageOfPurchase as stageOfPurchaseEnum} from '@renofi/api/src/enums';
import calculatePeakCLTV from '@renofi/utils/src/calculatePeakCLTV';

export default function usePostPIIScreen() {
  return (lead) => {
    const peakCLTV = calculatePeakCLTV(lead);
    const {loanSubject, stageOfPurchase} = lead || {};
    const isAdu = loanSubject === constants.LOAN_SUBJECT.ADU;
    const isHomeBuild = loanSubject === constants.LOAN_SUBJECT.HOME_BUILD;

    let path = '/structure/tear-down';
    if (isHomeBuild) path = '/renovation-type';
    if (isAdu) path = '/renovation-type';
    if (peakCLTV < 1.25) path = '/renovation-type';
    if (isHomeBuild && stageOfPurchase === stageOfPurchaseEnum.researching)
      path = '/renovation-type';
    if (isHomeBuild && stageOfPurchase === stageOfPurchaseEnum.making_offers)
      path = '/renovation-type';

    return path;
  };
}
