import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {Header, Content} from '@renofi/components/src/Modal/styled';
import {darkGray} from '@renofi/utils/src/colors';

export const modalCustomStyles = {
  [Header]: {
    background: 'white',
    borderBottom: '1px solid #D8D8D8',
    color: darkGray,
  },
  [Content]: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const Wrapper = styled(Box)(
  mediaQuery({
    position: 'absolute',
    right: 15,
    top: 20,
  }),
);
