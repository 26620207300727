import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useRenovationUtils} from '@renofi/utils';
import {
  stageOfPurchase as enums,
  land as landEnums,
} from '@renofi/api/src/enums';
import {Toggle} from '@renofi/components';
import {yellow} from '@renofi/utils/src/colors';

import {Buttons, Heading, Slider} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';

function Downpayment({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {propertyPurchasePrice, homeValue} = lead;
  const propertyValue = propertyPurchasePrice || homeValue || 0;
  const defaultDownpayment = parseInt((propertyValue || 0) * 0.15);
  const {
    stageOfPurchase,
    estimatedDownpayment = Math.ceil(defaultDownpayment / 1000) * 1000,
    propertyOwnershipStatus,
  } = lead;
  // eslint-disable-next-line prettier/prettier
  const max =
    propertyValue > 0 ? propertyValue: 2000000;
  const {isPurchase, isHomeBuild} = useRenovationUtils(lead);
  const isResearching = stageOfPurchase === enums.researching;
  const isLandToBePurchased =
    propertyOwnershipStatus === landEnums.to_be_purchased;
  const currentPercent = (estimatedDownpayment / propertyValue) * 100;

  useEffect(() => sendEvent('POS/Downpayment-Page'), []);

  const calcMortgageBalance = (downpayment) =>
    (propertyValue || 0) - downpayment;

  function onChange(value) {
    setLead({
      estimatedDownpayment: value,
      ...(isPurchase || isHomeBuild
        ? {}
        : {firstMortgageBalance: calcMortgageBalance(value)}),
    });
  }

  function onSubmit() {
    onChange(estimatedDownpayment);

    updateScenario({
      estimatedDownpayment,
      ...(isPurchase || isHomeBuild
        ? {}
        : {firstMortgageBalance: calcMortgageBalance(estimatedDownpayment)}),
    });

    sendEvent('POS/Downpayment-Entered', {estimatedDownpayment});

    let path = '/purchase-renovation-cost';
    if (isResearching) path = '/project-size';
    if (isHomeBuild && isLandToBePurchased)
      path = '/construction-purchase/structure/tear-down';

    next(path);
  }

  return (
    <>
      <Heading>
        {isHomeBuild
          ? 'Estimated downpayment to acquire the property'
          : 'Expected downpayment is about'}
      </Heading>
      <Slider
        noTooltip
        label={
          isHomeBuild
            ? 'If you’re buying cash, simply drag the slider all the way to the end.'
            : 'You can change this later'
        }
        min={10000}
        max={max}
        step={5000}
        onChange={onChange}
        value={estimatedDownpayment}>
        <Toggle show={currentPercent < 15}>
          <Text mt={-32} mb="8px" color={yellow} height={[42, 'auto']}>
            To increase your loan options, consider at least a 15% downpayment
          </Text>
        </Toggle>
        <Toggle show={currentPercent >= 15}>
          <Text mt={-32} mb="8px" height={[42, 'auto']}>
            <strong>{Math.floor(currentPercent)}%</strong> of property value
          </Text>
        </Toggle>
      </Slider>
      <Buttons onNext={onSubmit} />
    </>
  );
}

export default Downpayment;
