import React from 'react';

import PropTypes from 'prop-types';
import {Text, Image} from 'rebass';

import {ReviewGoogleLogo, ReviewGoogleStars, ReviewGoogleAward} from './images';
import {IconSocialProof} from './styled';

function GoogleReview({review, reviewerName}) {
  return (
    <IconSocialProof>
      <Image src={ReviewGoogleLogo} />
      <Image src={ReviewGoogleStars} margin="10px 0" />
      <Text width="100%" mt="10px" color="gray">
        {review}
        <Text my="17px">{reviewerName}</Text>
      </Text>
      <Image src={ReviewGoogleAward} />
    </IconSocialProof>
  );
}

GoogleReview.propTypes = {
  review: PropTypes.string.isRequired,
  reviewerName: PropTypes.string.isRequired,
};

export default GoogleReview;
