export default async function () {
  if (!Array.prototype.toSorted) {
    import(
      /* webpackChunkName: "array-prototype-tosorted" */ 'array.prototype.tosorted'
    );
  }

  if (!window.ResizeObserver) {
    const value = await import(
      /* webpackChunkName: "resize-observer-polyfill" */ 'resize-observer-polyfill'
    );
    window.ResizeObserver = value.default;
  }

  if (!window.CSS.supports('scroll-behavior', 'smooth')) {
    const value = await import(
      /* webpackChunkName: "resize-observer-polyfill" */ 'smoothscroll-polyfill'
    );
    value.default.polyfill();
  }
}
