import React, {useEffect} from 'react';

import {useParams} from 'react-router-dom';
import {Flex, Box, Image} from 'rebass';

import {useSetPresentedTurndownOption} from '@renofi/utils/src/turndowns';
import {sendEvent} from '@renofi/analytics';
import {useNoFooter, constants} from '@renofi/utils';
import {Button} from '@renofi/components';

import HeroSection from '../Components/HeroSection/HeroSection';
import DescriptionSection from '../Components/DescriptionSection/DescriptionSection';
import HowItWorksSection from '../Components/HowItWorksSection/HowItWorksSection';
import Offer from '../Components/Offer/Offer';

import {
  OfferWrapper,
  OfferHeading,
  TestimonialContainer,
  DescriptionText,
  CallToActionSection,
  OfferProvider,
  OfferedByText,
} from './styled';
import sofi from './img/sofi.png';
import sofiAward from './img/sofi-award.svg';

const SOFI_URL =
  'https://refer.sofi.com/c/3719258/843613/11190?adcampaigngroup=personal&adnetwork=BD';

const STEPS = [
  {
    title: 'View your rate',
    description:
      'Get pre-qualified online with no obligation and no fees required.',
  },
  {
    title: 'Select your loan & apply',
    description:
      'Choose the loan that works for you and complete your application.',
  },
  {
    title: 'Receive your funds',
    description:
      'Sign your documents - funds will be wired to your account, as soon as the same day.',
  },
];

const BENEFIT_ITEMS = [
  'Funds in as little as one business day*',
  'No collateral required',
  'Unemployment Protection*',
  'Low, fixed rates',
];

const Disclaimer = () => (
  <Flex flexDirection="column" alignItems="center" textAlign="left">
    <strong>SoFi Disclosure</strong>
    <div>
      <p>
        To check the rates and terms you qualify for, SoFi conducts a soft
        credit pull that will not affect your credit score. However, if you
        choose a product and continue your application, we will request your
        full credit report from one or more consumer reporting agencies, which
        is considered a hard credit pull and may affect your credit. Terms and
        conditions apply.
      </p>
      <p>
        SOFI RESERVES THE RIGHT TO MODIFY OR DISCONTINUE PRODUCTS AND BENEFITS
        AT ANY TIME WITHOUT NOTICE. To qualify, a borrower must be a U.S.
        citizen or other eligible status, be residing in the U.S., and meet
        SoFi’s underwriting requirements. SoFi Personal Loans can be used for
        any lawful personal, family, or household purposes and may not be used
        for post-secondary education expenses. Minimum loan amount is $5,000.
      </p>
      <p>
        The average of SoFi Personal Loans funded in 2021 was around $33k.
        Information current as of . SoFi Personal Loans originated by SoFi Bank,
        N.A. Member FDIC. NMLS #696891 (www.nmlsconsumeraccess.org). See
        SoFi.com/legal for state-specific license details. See
        SoFi.com/eligibility-criteria for details and state restrictions. Same
        day/1-business day funding subject to receipt of required documentation,
        underwriting guidelines, and processing time by your institution. Loans
        for amounts over $20,000 may require additional underwriting review
        time. Funds are disbursed via ACH as soon as the next business day after
        approval and acceptance of terms.
      </p>
      <p>
        *Same-day Personal Loan Funding: 86% of typical SoFi Personal Loan
        applications, excluding Direct Pay Personal Loans and Personal Loan
        refinance, from January 1, 2021 to December 1, 2021 that were signed
        before 7pm ET on a business day were funded the same day.*Unemployment
        Protection: If you lose your job through no fault of your own, you may
        apply for Unemployment Protection. If your loan(s) is/are in good
        standing at the time you request Unemployment Protection SoFi will, upon
        approval, modify your monthly SoFi loan payments and provide job
        placement assistance during your forbearance period. Interest will
        continue to accrue and will be added to your principal balance at the
        end of each forbearance period, to the extent permitted by applicable
        law. Benefits are offered in three month increments, and capped at 12
        months, in aggregate, over the life of the loan. To be eligible for this
        assistance you must provide proof that you have applied for and are
        eligible for unemployment compensation, and you must actively work with
        our Career Advisory Group to look for new employment.
      </p>
    </div>
  </Flex>
);

const Heading = () => (
  <>
    Based on the information you've provided, it seems that a{' '}
    <strong>Personal Loan offered through SoFi</strong> might be the best option
    for your renovation project!
  </>
);

const OfferHeader = () => (
  <>
    <OfferHeading>Personal Loan</OfferHeading>
    <OfferProvider>
      <OfferedByText>offered through</OfferedByText>
      <Image src={sofi} my={'15px'} />
    </OfferProvider>
  </>
);

const Testimonial = () => (
  <TestimonialContainer>
    <Image src={sofiAward} margin={'15px'} />
  </TestimonialContainer>
);

const DescriptionSectionHeading = () => (
  <>
    <strong>
      <mark>Up to $100,000</mark>
    </strong>
    &nbsp;for&nbsp;
    <strong>your home renovation</strong>
  </>
);

const CallToAction = ({leadId}) => (
  <CallToActionSection>
    <Button
      onClick={() => {
        sendEvent('POS/SoFi-Page-Button-Click');
        window.open(`${SOFI_URL}&sharedId=${leadId}`, '_blank');
      }}>
      Check my rate
    </Button>
    <p>Checking your rate won’t affect your credit score</p>
  </CallToActionSection>
);

const SoFiTurndown = () => {
  const {id} = useParams();
  const {TURNDOWN_PARTNERS} = constants;

  useSetPresentedTurndownOption(TURNDOWN_PARTNERS.SOFI);

  useNoFooter();
  useEffect(() => sendEvent('POS/SoFi-Page'), []);

  return (
    <>
      <HeroSection heading={<Heading />}>
        <OfferWrapper>
          <Offer
            benefitItems={BENEFIT_ITEMS}
            header={<OfferHeader />}
            description={'Get the money you need for your renovation, fast! '}
            testimonial={<Testimonial />}
            callToAction={<CallToAction leadId={id} />}
          />
        </OfferWrapper>
      </HeroSection>
      <DescriptionSection heading={<DescriptionSectionHeading />}>
        <Box width={[1, 1]}>
          <DescriptionText>
            SoFi’s home improvement loans range from $5K-$100K and they’re
            unsecured, which means that your house is not used as collateral to
            secure the loan. a SoFi Home Improvement Loan could get approved—and
            even funded—on the same day, with no appraisal required.
          </DescriptionText>
        </Box>
      </DescriptionSection>
      <HowItWorksSection
        title={
          <>
            Get a Personal Loan in <mark>3 easy steps:</mark>
          </>
        }
        steps={STEPS}
        callToAction={<CallToAction leadId={id} />}
        disclaimer={<Disclaimer />}
      />
    </>
  );
};

export default SoFiTurndown;
