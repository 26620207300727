import {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';
import {isNil} from 'lodash';

import {sendEvent} from '@renofi/analytics';

import {setLead, leadVar} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading, Narrow} from '../components';
import IconSelect from '../components/IconSelect';

import {ReactComponent as ClockIcon} from './img/clock.svg';
import {ReactComponent as Schedule1_2} from './img/start_in_1_2.svg';
import {ReactComponent as Schedule3_6} from './img/start_in_3_6.svg';
import {ReactComponent as Schedule6_12} from './img/start_in_6_12.svg';
import {ReactComponent as ScheduleOver12} from './img/start_in_12plus.svg';

const options = [
  {
    title: 'It has already started',
    value: 'already_started',
    icon: ClockIcon,
  },
  {
    title: 'In next 2 months',
    value: 'in_next_2_months',
    icon: Schedule1_2,
  },
  {
    title: 'In 3-6 months',
    value: 'in_3_6_months',
    icon: Schedule3_6,
  },
  {
    title: 'In 6-12 months',
    value: 'in_6_12_months',
    icon: Schedule6_12,
  },
  {
    title: 'In 12+ months',
    value: 'more_than_one_year',
    icon: ScheduleOver12,
  },
];

function RenovationStart({updateScenario}) {
  const next = useNext();
  const {renovationIdealStart: value} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Renovation-Start-Page'), []);

  function onSubmit(renovationIdealStart) {
    setLead({renovationIdealStart});
    updateScenario({renovationIdealStart});

    sendEvent('POS/Renovation-Start-Entered', {renovationIdealStart});
    next('/combined-income');
  }

  return (
    <>
      <Heading>When is the project starting?</Heading>
      <Narrow maxWidth={800}>
        <IconSelect onClick={onSubmit} options={options} value={value} />
      </Narrow>
      <Buttons
        disableNext={isNil(value)}
        onNext={() => onSubmit(value)}
        value={value}
      />
    </>
  );
}

RenovationStart.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default RenovationStart;
