import PropTypes from 'prop-types';

import {useCookieConsent, useRainforest} from '@renofi/utils';

import {Button} from '../';

import {ModalWrapper, Small} from './styled';

const CookieConsentModal = ({
  initLogAnalytics,
  isInIframe = false,
  isLead,
  isLendingTreeLead = false,
}) => {
  const {cookie, setConsentCookie} = useCookieConsent(initLogAnalytics, isLead);
  const {isRainforest} = useRainforest();

  return !cookie &&
    (!isLead || isLendingTreeLead) &&
    !isRainforest &&
    !isInIframe ? (
    <ModalWrapper>
      <h5>We Use Cookies & Website Tools</h5>
      <Small>
        Cookies, website analytics and web session capture/replay technologies
        (together “Tools”) help us improve and run our website, and allow us to
        view and retain your interactions with it. By continuing, you agree to
        our use of Cookies and Website Tools. Refer to our Privacy Policy.
      </Small>
      <Button xSmall onClick={setConsentCookie}>
        I understand
      </Button>
    </ModalWrapper>
  ) : null;
};

CookieConsentModal.propTypes = {
  initLogAnalytics: PropTypes.func.isRequired,
  isLead: PropTypes.bool,
  isInIframe: PropTypes.bool,
};

export default CookieConsentModal;
