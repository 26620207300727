import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useTransition, animated} from 'react-spring';
import {noop} from 'lodash';
import {Flex, Text, Box} from 'rebass';

import {
  Toggle,
  SliderWrapper,
  Slider as StyledSlider,
  SliderValue,
  SliderLabel,
} from '@renofi/components';
import {useSliderActions} from '@renofi/utils';
import {toCurrency, toShortCurrency} from '@renofi/utils/src/format';
import {superFast} from '@renofi/utils/src/animation';

import ProgressiveButton from '../Buttons/ProgressiveButton';

import {SliderCurrencyField} from './styled';

const TEST_ENV = process.env.NODE_ENV === 'test';

const Slider = ({
  value,
  displayValue,
  onChange = noop,
  suffix = '',
  minLabelSuffix,
  label,
  children,
  infinite,
  minimal,
  isCurrency = true,
  showExactMin,
  min,
  max,
  step,
  decreaseStep,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const transitions = useTransition(showInput, {
    from: {opacity: 0, transform: 'scale(1.1)'},
    enter: {opacity: 1, transform: 'scale(1)'},
    delay: 60,
    config: superFast,
  });
  const {onDecrease, onIncrease, throttleChange} = useSliderActions({
    infinite,
    min,
    max,
    step,
    decreaseStep,
    value,
    onChange,
  });

  useEffect(() => {
    if (value >= max && infinite) {
      setShowInput(true);
    }
    setInputValue(value);
  }, [value, infinite]);

  function onInputChange(newValue) {
    setInputValue(newValue);
    if (newValue === '') return;
    onChange(newValue);
  }

  return transitions((styles, item) => (
    <SliderWrapper minimal={minimal} mx="auto">
      <SliderLabel minimal={minimal}>{label}</SliderLabel>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={minimal ? null : 40}
        height={60}>
        {(!minimal || TEST_ENV) && (
          <ProgressiveButton onChange={onDecrease}>
            <Box pt="2px">-</Box>
          </ProgressiveButton>
        )}

        <Toggle show={!showInput}>
          <SliderValue minimal={minimal}>
            {isCurrency && (
              <>
                ${toCurrency(value)}
                {suffix}
              </>
            )}
            {!isCurrency && <>{displayValue || value}</>}
          </SliderValue>
        </Toggle>

        <Toggle show={showInput}>
          {item && (
            <animated.div style={styles}>
              <SliderCurrencyField
                iconCss={{
                  height: 50,
                  paddingLeft: 0,
                  width: [25, 50],
                  left: [25, 1],
                }}
                xLarge
                autofocus
                icon
                min={min}
                value={inputValue}
                onChange={onInputChange}
              />
            </animated.div>
          )}
        </Toggle>

        {(!minimal || TEST_ENV) && (
          <ProgressiveButton onChange={onIncrease}>+</ProgressiveButton>
        )}
      </Flex>

      {children}

      <StyledSlider
        value={value}
        onChange={throttleChange}
        min={0}
        max={max}
        step={step}
        decreaseStep={decreaseStep}
      />
      {!minimal && (
        <Flex
          css={{userSelect: 'none'}}
          mt="6px"
          justifyContent="space-between">
          <Text>
            ${showExactMin ? toCurrency(min) : toShortCurrency(min)}
            {minLabelSuffix}
          </Text>
          <Text>
            ${toShortCurrency(max)}
            {infinite ? '+' : ''}
          </Text>
        </Flex>
      )}
    </SliderWrapper>
  ));
};

Slider.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  infinite: PropTypes.bool,
  minimal: PropTypes.bool,
  isCurrency: PropTypes.bool,
  showExactMin: PropTypes.bool,
  step: PropTypes.number,
  decreaseStep: PropTypes.number,
  suffix: PropTypes.string,
  minLabelSuffix: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Slider;
