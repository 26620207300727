import {useState, useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {isEmpty} from 'lodash';

import {TextField, CheckboxPanel} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';
import {useUTMTags, constants} from '@renofi/utils';

import {leadVar, setLead} from '../api/cache';
import {useRedirectToDashboard} from '../hooks';
import {Buttons, Heading, Narrow, PageLoader} from '../components';

import {DetailsWrapper, DetailsLabel, DetailsFieldWrapper} from './styled';

const SelfReportedOrigin = ({updateScenario}) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const lead = useReactiveVar(leadVar);
  const [origin, setOrigin] = useState(lead?.selfReportedOriginType || null);
  const [originDetails, setOriginDetails] = useState(
    lead?.selfReportedOriginDetails || null,
  );
  const redirectToDashboard = useRedirectToDashboard();
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {id, prequalCompletedAt} = lead;

  useEffect(() => {
    if (!isEmpty(utmTags) && id) {
      redirectToDashboard(id, prequalCompletedAt);
      return;
    }
    setLoading(false);
    sendEvent('POS/Self-Reported-Origin-Page');
  }, []);

  const handleToggle = (origin) => {
    setOrigin(origin);
    setOriginDetails(null);
  };

  const handeSubmit = () => {
    setSubmitting(true);

    const selfReportedOrigin = {
      selfReportedOriginType: origin,
      selfReportedOriginDetails: originDetails,
    };

    setLead({...selfReportedOrigin});

    updateScenario({selfReportedOrigin});

    sendEvent('POS/Self-Reported-Origin-Entered', {
      selfReportedSource: origin,
      selfReportedSourceDetails: originDetails,
    });

    redirectToDashboard(id, prequalCompletedAt);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Heading>How did you first discover RenoFi?</Heading>
      <Narrow maxWidth={500}>
        <CheckboxPanel
          radio
          keepHover
          label="Online advertisement (e.g. Google, Facebook, Instagram)"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.ONLINE_ADVERT)
          }
          value={origin === constants.SELF_REPORTED_ORIGINS.ONLINE_ADVERT}
        />

        <CheckboxPanel
          radio
          keepHover
          label="A contractor/builder"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.CONTRACTOR)
          }
          value={origin === constants.SELF_REPORTED_ORIGINS.CONTRACTOR}>
          <DetailsWrapper>
            <DetailsLabel>What is the name of the Contractor?</DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                data-testid="origin-contractor-name-test-id"
                id="origin-contractor-name"
                name="origin-contractor-name"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>

        <CheckboxPanel
          radio
          keepHover
          label="A loan officer"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.LOAN_OFFICER)
          }
          value={origin === constants.SELF_REPORTED_ORIGINS.LOAN_OFFICER}>
          <DetailsWrapper>
            <DetailsLabel>
              What is the name of the Loan Officer or Mortgage Company?
            </DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                id="origin-loan-officer"
                name="origin-loan-officer"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>

        <CheckboxPanel
          radio
          keepHover
          label="A realtor"
          onToggle={() => handleToggle(constants.SELF_REPORTED_ORIGINS.REALTOR)}
          value={origin === constants.SELF_REPORTED_ORIGINS.REALTOR}>
          <DetailsWrapper>
            <DetailsLabel>What is the name of the realtor?</DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                id="origin-realtor"
                name="origin-realtor"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>

        <CheckboxPanel
          radio
          keepHover
          label="Other renovation professional (ADU builder, designer, architect)"
          onToggle={() =>
            handleToggle(
              constants.SELF_REPORTED_ORIGINS.CONSTRUCTION_PROFESSIONAL,
            )
          }
          value={
            origin === constants.SELF_REPORTED_ORIGINS.CONSTRUCTION_PROFESSIONAL
          }>
          <DetailsWrapper>
            <DetailsLabel>
              What is the name of the construction professional or company?
            </DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                id="origin-construction-loan-officer"
                name="origin-construction-loan-officer"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>

        <CheckboxPanel
          radio
          keepHover
          label="Online search, article or a link on a website"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.ONLINE_SEARCH)
          }
          value={origin === constants.SELF_REPORTED_ORIGINS.ONLINE_SEARCH}
        />

        <CheckboxPanel
          radio
          keepHover
          label="А RenoFi partner website (e.g. Lending Tree)"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.RENOFI_PARTNER_WEBSITE)
          }
          value={
            origin === constants.SELF_REPORTED_ORIGINS.RENOFI_PARTNER_WEBSITE
          }>
          <DetailsWrapper>
            <DetailsLabel>What is the name of the RenoFi partner?</DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                id="origin-renofi-partner-website"
                name="origin-renofi-partner-website"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>

        <CheckboxPanel
          radio
          keepHover
          label="A financial professional or institution"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.FINANCIAL_INSTITUTION)
          }
          value={
            origin === constants.SELF_REPORTED_ORIGINS.FINANCIAL_INSTITUTION
          }>
          <DetailsWrapper>
            <DetailsLabel>
              What is the name of the Financial Institution or Advisor?
            </DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                id="origin-financial-institution"
                name="origin-financial-institution"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>

        <CheckboxPanel
          radio
          keepHover
          label="Recommendation (e.g. friend, coworker, neighbor)"
          onToggle={() =>
            handleToggle(constants.SELF_REPORTED_ORIGINS.RECOMMENDATION)
          }
          value={origin === constants.SELF_REPORTED_ORIGINS.RECOMMENDATION}
        />

        <CheckboxPanel
          radio
          keepHover
          label="Other"
          onToggle={() => handleToggle(constants.SELF_REPORTED_ORIGINS.OTHER)}
          value={origin === constants.SELF_REPORTED_ORIGINS.OTHER}>
          <DetailsWrapper>
            <DetailsLabel>
              Please specify how you first discovered RenoFi
            </DetailsLabel>
            <DetailsFieldWrapper>
              <TextField
                autofocus
                xLarge
                value={originDetails}
                onChange={setOriginDetails}
                id="origin-other"
                name="origin-other"
                type="text"
              />
            </DetailsFieldWrapper>
          </DetailsWrapper>
        </CheckboxPanel>
      </Narrow>

      <Buttons
        onNext={handeSubmit}
        disableNext={!origin || submitting}
        loading={submitting}
      />
    </>
  );
};

export default SelfReportedOrigin;
