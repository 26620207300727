import {makeVar} from '@apollo/client';
import {v4 as uuidv4} from 'uuid';

import {isEmail} from '@renofi/utils/src/validate';

export const REFERENCE_TYPES = {
  CLIENT: 'client',
  SUBCONTRACTOR: 'subcontractor',
};

export const INITIAL_REFERENCES = [
  {
    id: uuidv4(),
    type: REFERENCE_TYPES.CLIENT,
    name: '',
    phoneNumber: '',
    email: '',
    isEmailValid: false,
  },
  {
    id: uuidv4(),
    type: REFERENCE_TYPES.CLIENT,
    name: '',
    phoneNumber: '',
    email: '',
    isEmailValid: false,
  },
  {
    id: uuidv4(),
    type: REFERENCE_TYPES.SUBCONTRACTOR,
    name: '',
    phoneNumber: '',
    email: '',
    isEmailValid: false,
  },
];

// Reactive variables
export const referencesVar = makeVar(INITIAL_REFERENCES);

// Setter
export const setReferences = (references = INITIAL_REFERENCES) => {
  if (!references?.length) {
    return referencesVar([...INITIAL_REFERENCES]);
  }
  referencesVar(
    references.map((ref) => ({
      ...ref,
      isEmailValid: ref.isEmailValid || isEmail(ref.email),
    })),
  );
};

// Apollo field policy
export const referencesPolicy = {
  read() {
    return referencesVar();
  },
};

export default {
  referencesPolicy,
  setReferences,
};
