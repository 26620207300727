import {useMutation} from '@apollo/client';

import {PREQUAL_COMPLETED} from '../mutations/prequalCompleted';

export default function useRequestPrequalLink() {
  const [prequalCompleted, {data, loading, error}] =
    useMutation(PREQUAL_COMPLETED);
  const response = data?.prequalCompleted;
  return {prequalCompleted, response, loading, error};
}
