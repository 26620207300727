import React from 'react';

import {intersectionWith} from 'lodash';

export function findProductCombinations(option, options) {
  if (!option.allowedCombinations?.length) return null;

  const otherOptionsTypes = options
    .filter((item) => item.id !== option.id)
    .map((option) => option.productType);

  const commonTypes = intersectionWith(
    option.allowedCombinations,
    otherOptionsTypes,
  );

  return commonTypes.map((type) => PRODUCT_TITLES[type]);
}

export const PRODUCT_TITLES = {
  fix_and_flip_mortgage: {
    title: (
      <>
        <mark>Fix and Flip</mark> mortgage
      </>
    ),
  },
  home_equity_investment: {
    title: (
      <>
        Home Equity <mark>Investment</mark>
      </>
    ),
  },
  investment_heloc: {
    title: (
      <>
        Investment <mark>HELOC</mark>
      </>
    ),
  },
  renofi_cashout_refi: {
    title: (
      <>
        RenoFi <mark>Refi</mark>
      </>
    ),
  },
  renofi_heloc: {
    title: (
      <>
        RenoFi <mark>HELOC</mark>
      </>
    ),
  },
  renofi_home_equity: {
    title: (
      <>
        RenoFi <mark>Fixed Rate Home Equity Loan</mark>
      </>
    ),
  },
  traditional_cashout_refi: {
    title: (
      <>
        Cashout <mark>Refi</mark>
      </>
    ),
  },
  traditional_heloc: {
    title: <mark>HELOC</mark>,
  },
  traditional_heloan: {
    title: <mark>Home Equity Loan</mark>,
  },
  unsecured_personal: {
    title: (
      <>
        RenoFi <mark>Personal</mark> Loan
      </>
    ),
  },
  traditional_home_equity: {
    title: (
      <>
        <mark>Fixed Rate</mark> Home Equity Loan
      </>
    ),
  },
  renovation_mortgage: {
    title: (
      <>
        Renovation <mark>Mortgage</mark>
      </>
    ),
  },
  construction_loan: {
    title: <mark>Construction Loan</mark>,
  },
  purchase_loan: {
    title: (
      <>
        Purchase <mark>Mortgage</mark>
      </>
    ),
  },
  purchase_and_renovate_loan: {
    title: (
      <>
        Purchase & Renovation <mark>Mortgage</mark>
      </>
    ),
  },
};
