import styled from '@emotion/styled/macro';
import {Text, Box, Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export const TestimonialContainer = styled(Box)(() =>
  mediaQuery({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
);

export const TestimonialText = styled(Text)(() =>
  mediaQuery({
    color: gray,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 300,
    textAlign: 'center',
    marginTop: '15px',
  }),
);

export const BurbankImage = styled(Image)(() =>
  mediaQuery({
    margin: '16px 0px 38px 0px',
  }),
);

export const PointImage = styled(Image)(() =>
  mediaQuery({
    marginBottom: '15px',
  }),
);

export const HomeEquityText = styled(Text)(() =>
  mediaQuery({
    textAlign: ['center', 'left'],
    fontSize: ['24px', '32px'],
    lineHeight: ['29px', '38px'],
    marginRight: '10px',
  }),
);

export const OfferedByText = styled(Text)(() =>
  mediaQuery({
    color: gray,
    fontSize: '14px',
    lineHeight: '17px',
    marginRight: '10px',
  }),
);

export const OfferProvider = styled(Box)(() =>
  mediaQuery({
    display: 'flex',
    alignItems: 'center',
  }),
);

export const OfferWrapper = styled(Box)(() =>
  mediaQuery({
    backgroundColor: 'white',
    margin: ['0', '0 20%'],
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
  }),
);

export const DescriptionText = styled(Text)(
  mediaQuery({
    marginBottom: '40px',
    fontSize: ['20px', '24px'],
    lineHeight: ['30px', '36px'],
    textAlign: 'center',
    padding: ['0', '0 150px'],
  }),
);
