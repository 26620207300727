import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {FixedBottom} from 'react-fixed-bottom';
import {Box} from 'rebass';

import {useScreenSize} from '@renofi/utils';

import {StickyBottomFooter} from './styled';

const StickyBottom = ({children, pushUp, css, ...props}) => {
  const {isMobile} = useScreenSize();
  const Wrapper = isMobile ? FixedBottom : 'div';

  const style = isMobile
    ? {
        zIndex: 100,
        width: '100%',
        position: 'fixed',
        bottom: '0px',
        userSelect: 'none',
      }
    : {};

  return (
    <Wrapper>
      <Box css={style}>
        <StickyBottomFooter
          {...props}
          css={{...css, ...(pushUp ? {paddingTop: [15, 185]} : {})}}
          sticky={isMobile}>
          {children}
        </StickyBottomFooter>
      </Box>
    </Wrapper>
  );
};

StickyBottom.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
  pushUp: PropTypes.bool,
  css: PropTypes.object,
};

export default memo(StickyBottom);
