import React from 'react';

import {Box} from 'rebass';
import PropTypes from 'prop-types';

import {
  OfferHeader,
  OfferContainer,
  BenefitItemWrapper,
  BenefitItemImage,
  BenefitItemText,
  DescriptionText,
  TestimonialSection,
} from './styled';
import checkIcon from './img/check.svg';

const DescriptionHeading = ({children}) => (
  <Box width={1} marginTop={'10px'}>
    <DescriptionText>{children}</DescriptionText>
  </Box>
);
DescriptionHeading.propTypes = {
  children: PropTypes.string,
};

const BenefitItem = ({content}) => (
  <BenefitItemWrapper>
    <Box width={[1 / 8, 1 / 10]}>
      <BenefitItemImage src={checkIcon} />
    </Box>
    <Box width={[7 / 8, 9 / 10]}>
      <BenefitItemText>{content}</BenefitItemText>
    </Box>
  </BenefitItemWrapper>
);

BenefitItem.propTypes = {
  content: PropTypes.string,
};

const Offer = ({
  benefitItems,
  header,
  description,
  testimonial,
  callToAction,
}) => (
  <>
    <OfferHeader>
      {header}
      <DescriptionHeading>{description}</DescriptionHeading>
    </OfferHeader>
    <OfferContainer>
      <Box width={[1, 3 / 5]}>
        {benefitItems.map((content, key) => {
          return <BenefitItem key={key} content={content} />;
        })}
      </Box>
      <TestimonialSection width={[1, 2 / 5]}>{testimonial}</TestimonialSection>
      {callToAction && <>{callToAction}</>}
    </OfferContainer>
  </>
);

Offer.propTypes = {
  heading: PropTypes.object,
  benefitItems: PropTypes.array,
  header: PropTypes.any,
  description: PropTypes.string,
  testimonial: PropTypes.any,
  callToAction: PropTypes.any,
};
export default Offer;
