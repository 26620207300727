import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {isUndefined, noop} from 'lodash';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {Flex} from 'rebass';
import * as Sentry from '@sentry/react';

import {useAuthenticateByToken} from '@renofi/api';
import {isQueryParamSet, useLocalStorage, useUTMTags} from '@renofi/utils';
import Loader from '@renofi/components/src/Loader';
import {lightGray} from '@renofi/utils/src/colors';
import {saveLeadJwt} from '@renofi/utils/src/lead';

const Auth = ({onSuccess, callback = noop, failedPath}) => {
  // Apollo
  const {authenticateByToken, response} = useAuthenticateByToken({
    onCompleted: callback,
  });
  // App
  const {token} = useParams();
  const {search} = useLocation();
  const history = useHistory();
  const path = new URLSearchParams(window.location.search).get('path');
  const isScheduleSet = isQueryParamSet('schedule');
  const [_, setScheduleStorage] = useLocalStorage('renofi:schedule');
  const {utmTagsToQueryString} = useUTMTags();
  const utmTagsQuery = utmTagsToQueryString();

  const taggedPath = `${path}?${utmTagsQuery}`;

  useEffect(() => {
    if (isScheduleSet) {
      setScheduleStorage(isScheduleSet);
    }
  }, [isScheduleSet]);

  useEffect(() => {
    (async () => {
      const accessToken = token || new URLSearchParams(search).get('token');

      if (!accessToken) {
        onError();
        return;
      }

      try {
        await authenticateByToken({variables: {token: accessToken}});
      } catch (e) {
        onError();
      }
    })();
  }, []);

  useEffect(() => {
    if (isUndefined(response)) return;

    if (response?.jwt) {
      saveLeadJwt(response?.jwt, response?.lead?.id);
      if (onSuccess) onSuccess(response);
      if (taggedPath) return history.push(taggedPath);
      reportError();
    } else {
      setTimeout(onError, 1000);
    }
  }, [response]);

  function onError() {
    if (taggedPath) return history.push(taggedPath);
    if (failedPath) return history.push(failedPath);

    Sentry.captureException(
      new Error(
        "Can't redirect from /auth page because no redirect path is specified.",
      ),
      {
        extra: {path},
      },
    );
  }

  return (
    <Flex height="100%" justifyContent="center" alignItems="center">
      <Loader color={lightGray} size={1.5} />
    </Flex>
  );
};

Auth.propTypes = {
  failedPath: PropTypes.string,
  onSuccess: PropTypes.func,
  callback: PropTypes.func,
};

export default Auth;
