import {makeVar} from '@apollo/client';

import routes from '../../App/routes';

// Reactive variables
export const progressVar = makeVar({
  progress: 1,
  locationIndex: 0,
});

// Setter
export const setProgress = (nextPath) => {
  const locationIndex = routes.findIndex((route) => route.path === nextPath);
  progressVar({
    progress: routes[locationIndex]?.progress,
    locationIndex,
  });
};

// Apollo field policy
export const progressPolicy = {
  read() {
    return progressVar();
  },
};
