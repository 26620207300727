import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {Label, TextField, SelectField} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {forEachStateMap} from '@renofi/api';

function AddressInputs({
  onChange,
  zipCode,
  city,
  county,
  state,
  showCountyField = true,
  xLarge = true,
  ...props
}) {
  const {isMobile} = useScreenSize();
  const commonProps = {
    css: {boxShadow: '0px 0px 7px 0px #00A0FF1A'},
    required: true,
    noSpecialCharacters: true,
    xLarge,
    ...props,
  };
  const mt = xLarge ? 26 : 16;

  return (
    <>
      <Flex flexDirection={['column', 'row']}>
        <Box mr={[0, '5px']} flex={1}>
          <Label small htmlFor="city" mt={mt}>
            City
          </Label>
          <TextField
            noNumbers
            value={city}
            onChange={(value, err) => onChange('city', value, err)}
            id="city"
            name="city"
            type="text"
            {...commonProps}
          />
        </Box>

        {showCountyField && (
          <Box ml={[0, '5px']} flex={1}>
            <Label small htmlFor="county" mt={mt}>
              County
            </Label>
            <TextField
              noNumbers
              value={county}
              onChange={(value, err) => onChange('county', value, err)}
              id="county"
              name="county"
              type="county"
              {...commonProps}
            />
          </Box>
        )}
      </Flex>

      <Flex flexDirection={['column', 'row']}>
        <Box mr={[0, '5px']} flex={1}>
          <Label small htmlFor="state" mt={mt}>
            State
          </Label>
          <SelectField
            autoComplete="false"
            value={state}
            onChange={(value, err) => onChange('state', value, err)}
            id="state"
            name="state"
            {...commonProps}>
            {<option />}
            {forEachStateMap((key, value) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </SelectField>
        </Box>

        <Box ml={[0, '5px']} flex={1}>
          <Label small htmlFor="zipCode" mt={mt}>
            ZIP Code
          </Label>
          <TextField
            value={zipCode}
            onChange={(value, err) => onChange('zipCode', value, err)}
            id="zipCode"
            name="zipCode"
            digitsOnly
            limit={5}
            min={5}
            inputmode="numeric"
            pattern={isMobile ? '[0-9]*' : undefined}
            {...commonProps}
          />
        </Box>
      </Flex>
    </>
  );
}

AddressInputs.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  county: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  xLarge: PropTypes.bool,
  showCountyField: PropTypes.bool,
};

export default AddressInputs;
