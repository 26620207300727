import React, {useEffect} from 'react';

import {Box, Image, Text} from 'rebass';

import {useSetPresentedTurndownOption} from '@renofi/utils/src/turndowns';
import {useNoFooter, constants} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import HeroSection from '../Components/HeroSection/HeroSection';
import DescriptionSection from '../Components/DescriptionSection/DescriptionSection';
import HowItWorksSection from '../Components/HowItWorksSection/HowItWorksSection';
import Offer from '../Components/Offer/Offer';

import {
  TestimonialContainer,
  TestimonialText,
  HomeEquityText,
  DescriptionText,
  OfferWrapper,
  CallToActionSection,
} from './styled';
import vontiveIcon from './img/vontive.svg';
import testimonial from './img/testimonial.svg';
import VontiveCTA from './VontiveCTA';

const STEPS = [
  {
    title: 'Create an account with Vontive',
    description: 'And share details about your project',
  },
  {
    title: 'Apply in minutes',
    description: 'The application takes less than 5 minutes to \ncomplete.',
  },
  {
    title: 'Compare options',
    description: 'Select the option that works best for you.',
  },
  {
    title: 'Close confidently',
    description: 'A manager will guide you through the process.',
  },
];

const BENEFIT_ITEMS = [
  'Loan amounts between $100,000 and $1.5m',
  'No prepayment penalty',
  'Close a loan in 2-3 weeks',
];

const DISCLAIMER =
  'Commercial investment loan products offered by Vontive. RenoFi is not affiliated with Vontive and is not responsible for Vontive’s products or services, the content or operation of Vontive’s website, or how Vontive handles or uses your information. Information you provide to Vontive is subject to its privacy policy and website terms of use. RenoFi receives compensation through Vontive for commercial investment projects funded through this program. Vontive’s products not available in all states or situations, and not all homeowners will qualify.';

const Heading = () => (
  <>
    Based on the information you&apos;ve provided, we found a great option for
    your investment property renovation!
  </>
);

const OfferHeader = () => (
  <>
    <HomeEquityText>Value-add Financing Fix & Flip</HomeEquityText>
  </>
);

const Testimonial = () => (
  <TestimonialContainer>
    <TestimonialText>offered by</TestimonialText>
    <Image src={vontiveIcon} margin={'15px'} />
    <Image src={testimonial} margin={'0 0 15px 0'} />
  </TestimonialContainer>
);

const DescriptionSectionHeading = () => (
  <>
    <strong>
      <mark>What is</mark>
      &nbsp;Vontive?
    </strong>
  </>
);

const CallToAction = () => (
  <CallToActionSection>
    <Text mb={3} color={'#0B7517'}>
      Generate an offer in 5 minutes
    </Text>
    <VontiveCTA />
  </CallToActionSection>
);

const VontiveTurndown = () => {
  const {TURNDOWN_PARTNERS} = constants;

  useSetPresentedTurndownOption(TURNDOWN_PARTNERS.VONTIVE);

  useNoFooter();
  useEffect(() => sendEvent('POS/Vontive-Page'), []);

  return (
    <>
      <HeroSection heading={<Heading />}>
        <OfferWrapper>
          <Offer
            benefitItems={BENEFIT_ITEMS}
            header={<OfferHeader />}
            description={
              'Easy and intuitive digital loan applications for investment properties'
            }
            testimonial={<Testimonial />}
            callToAction={<CallToAction />}
          />
        </OfferWrapper>
      </HeroSection>
      <DescriptionSection heading={<DescriptionSectionHeading />}>
        <Box width={[1, 1]}>
          <DescriptionText>
            Vontive creates an entirely new and digital way to finance
            investment real estate and is{' '}
            <strong>RenoFi&apos;s trusted partner</strong> when it comes to
            renovation loans for investment properties.
          </DescriptionText>
        </Box>
      </DescriptionSection>
      <HowItWorksSection
        steps={STEPS}
        callToAction={<VontiveCTA />}
        disclaimer={DISCLAIMER}
      />
    </>
  );
};

export default VontiveTurndown;
