import {find} from 'lodash';

import {RAAS_PARTNERS} from './const';

const getRaasPartnerName = (sourceLenderCode) => {
  return find(RAAS_PARTNERS, (partner) => partner.code === sourceLenderCode)
    ?.name;
};

export default getRaasPartnerName;
