import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {Link} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useContractorBySlug} from '@renofi/api';
import {CheckboxPanel} from '@renofi/components';
import {constants, useUTMTags} from '@renofi/utils';

import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';
import {Buttons, Heading} from '../components';
import {Wrapper} from '../App/styled';

const GcConsent = ({updateScenario}) => {
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {contractor} = useContractorBySlug({
    variables: {urlSlug: utmTags.source},
  });
  const next = useNext('/loan-type');
  const lead = useReactiveVar(leadVar);
  const consentAt = lead?.agreedToContractorCommunicationAt;

  const onNext = () => {
    next('/loan-type');
    updateScenario({agreedToContractorCommunicationAt: consentAt});
  };

  const onToggle = (value) => {
    setLead({
      agreedToContractorCommunicationAt: value
        ? new Date().toISOString()
        : null,
    });
  };

  useEffect(() => {
    sendEvent('POS/GC-Consent-Page');
    setLead({
      agreedToContractorCommunicationAt: null,
    });
  }, []);

  return (
    <Wrapper width={1140}>
      <Heading>
        No problem! Just so you know, your renovation details are secure and
        used only by {contractor?.businessName} for your renovation project.
      </Heading>

      <CheckboxPanel
        keepHover
        label={
          <>
            Share my details with {contractor?.businessName}
            By checking the box, you consent to RenoFi sharing your name,
            address, email and/or phone number with the above contractor so that
            they may contact you about their renovation services. Visit our{' '}
            <Link
              fontSize={18}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              href="https://www.renofi.com/notices/privacy/">
              Privacy Policy
            </Link>{' '}
            to learn more.
          </>
        }
        onToggle={onToggle}
        value={Boolean(consentAt)}
      />

      <Buttons disableNext={!consentAt} onNext={onNext} />
    </Wrapper>
  );
};
export default GcConsent;
