import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {lightGray} from '@renofi/utils/src/colors';
import {Button} from '@renofi/components';

export const ButtonsWrapper = styled(Flex)(
  mediaQuery({
    margin: '0 auto',
    height: [80, 'auto'],
    padding: ['0 15px', 0],
    paddingTop: [0, 40],
    alignItems: 'center',
  }),
  ({hideBack, hideNext, fullWidth, css}) =>
    mediaQuery({
      justifyContent: hideBack
        ? 'flex-end'
        : hideNext
          ? 'flex-start'
          : 'space-between',
      width: ['100%', fullWidth ? '100%' : 350],
      ...css,
    }),
);

export const SquareButton = styled(Button)({
  userSelect: 'none',
  borderRadius: 8,
  boxShadow: '0px 0px 7px rgba(0, 160, 255, 0.1)',
  borderColor: 'transparent',
  fontSize: 40,
  color: lightGray,
  fontWeight: 300,
  width: '50px !important',
  height: 50,
  padding: '0 !important',
});
