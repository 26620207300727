import {makeVar} from '@apollo/client';

// Reactive variables
export const contactVar = makeVar({
  contactFirstName: '',
  contactLastName: '',
  contactTitle: '',
  email: '',
  phoneNumber: '',
});

// Setter
export const setContact = (contact) => {
  contactVar({...contactVar(), ...contact});
};

// Apollo field policy
export const contactPolicy = {
  read() {
    return contactVar();
  },
};

export default {
  contactPolicy,
  setContact,
};
