import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {darkGreen} from '@renofi/utils/src/colors';

import {ReactComponent as CheckIcon} from './img/check-icon.svg';

const CheckItem = ({children}) => {
  return (
    <Flex
      alignItems="center"
      mx={['8px', 16]}
      mb={['8px', 0]}
      flexDirection={['column', 'row']}>
      <CheckIcon />
      <Text
        mt={['8px', 0]}
        lineHeight={['16px', '18px']}
        fontWeight="100"
        ml={[0, '5px']}
        color={darkGreen}
        fontSize={[14, 16]}>
        {children}
      </Text>
    </Flex>
  );
};

CheckItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckItem;
