import {capitalizeFirstLetter} from './format';

export default async function callZapierWebhook(webhook, data) {
  const timestamp = new Date().toISOString();

  let JSONObject = {
    timestamp,
    app: prettyAppName(),
    ...data,
  };

  let options = {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(JSONObject),
  };

  await fetch(webhook, options); // HTTP POST Request
}

export function notifyToChilipiperSlack(data) {
  const webhook = process.env.REACT_APP_ZAPIER_CP_WEBHOOK;
  callZapierWebhook(webhook, data);
}

export function prettyAppName() {
  const app = process.env.REACT_APP_SERVICE_NAME || '';
  const prettyName = app.replace('-frontend', ' App');
  return capitalizeFirstLetter(prettyName);
}
