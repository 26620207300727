import {inRange, includes} from 'lodash';

import {
  calculateTotalMortgageBalance,
  isLeadQualified,
  getLeadCreditScore,
  constants,
} from '../';

const {
  POINT_AVAILABLE_STATES,
  POINT_EXCLUDED_ZIP_CODES,
  VONTIVE_EXCLUDED_STATES,
  SPLASH_EXCLUDED_STATES,
  SOFI_AVAILABLE_STATES,
} = constants;

const isLeadOriginTurndownSupported = ({
  leadExternalOriginType,
  brokerSourced,
}) => {
  return (
    !brokerSourced &&
    !includes(constants.LEAD_ORIGIN_TYPE, leadExternalOriginType)
  );
};

export const isLeadPointMatch = (lead) => {
  const {
    homeValue,
    propertyUseType,
    state,
    zipCode,
    loanAmountDesiredForRenovation,
  } = lead;

  const creditScore = getLeadCreditScore(lead);

  const totalMortgageBalance = calculateTotalMortgageBalance(lead);

  const currentLtv = totalMortgageBalance / homeValue;

  const isValidCurrentLtv = currentLtv <= 0.7;
  const isInPointSupportedState = POINT_AVAILABLE_STATES.includes(state);
  const isInPointSupportedCounties =
    !POINT_EXCLUDED_ZIP_CODES.includes(zipCode);
  const isCreditScoreValid = creditScore >= 500;

  let isLoanAmountValid =
    loanAmountDesiredForRenovation >= 25000 &&
    loanAmountDesiredForRenovation <= 500000;
  if (propertyUseType === 'investment_property') {
    isLoanAmountValid =
      loanAmountDesiredForRenovation >= 25000 &&
      loanAmountDesiredForRenovation <= 100000;
  }

  return (
    isLeadOriginTurndownSupported(lead) &&
    !isLeadQualified(lead) &&
    isInPointSupportedState &&
    isInPointSupportedCounties &&
    isLoanAmountValid &&
    isValidCurrentLtv &&
    isCreditScoreValid
  );
};

export const isLeadEarlyPointMatch = (lead) => {
  const {
    propertyUseType,
    state,
    creditScore,
    zipCode,
    loanAmountDesiredForRenovation,
  } = lead;
  const isInPointSupportedState = POINT_AVAILABLE_STATES.includes(state);
  const isInPointSupportedCounties =
    !POINT_EXCLUDED_ZIP_CODES.includes(zipCode);
  const isCreditScoreValid = creditScore === 'one';
  const isInvestmentProperty = propertyUseType === 'investment_property';

  const isLoanAmountValid =
    loanAmountDesiredForRenovation >= 25000 &&
    loanAmountDesiredForRenovation <= 100000;

  return (
    isLeadOriginTurndownSupported(lead) &&
    isInPointSupportedState &&
    isInPointSupportedCounties &&
    isInvestmentProperty &&
    isLoanAmountValid &&
    isCreditScoreValid
  );
};

export const isLeadVontiveMatch = (lead, loanProducts) => {
  const {propertyUseType, homeValue, state, loanAmountDesiredForRenovation} =
    lead;

  const isQualified = isLeadQualified(lead);

  const creditScore = getLeadCreditScore(lead);

  const isInvestmentProperty = propertyUseType === 'investment_property';

  const isVontiveBetterMatch =
    !isQualified ||
    (loanProducts.length === 1 &&
      loanProducts[0].productType === 'unsecured_personal' &&
      loanAmountDesiredForRenovation >= 75000);
  const isLoanAmountValid =
    loanAmountDesiredForRenovation >= 75000 &&
    loanAmountDesiredForRenovation <= 1500000;
  const isHomeValueValid = homeValue >= 100000;
  const isCreditScoreValid = creditScore >= 660;
  const isStateSupported = !VONTIVE_EXCLUDED_STATES.includes(state);

  return (
    isLeadOriginTurndownSupported(lead) &&
    isCreditScoreValid &&
    isInvestmentProperty &&
    isVontiveBetterMatch &&
    isLoanAmountValid &&
    isHomeValueValid &&
    isStateSupported
  );
};

export const isLeadEarlyVontiveMatch = (lead, loanProducts) => {
  const {
    recentBankruptcy,
    propertyUseType,
    homeValue,
    state,
    loanAmountDesiredForRenovation,
  } = lead;

  const creditScore = getLeadCreditScore(lead);

  const isInvestmentProperty = propertyUseType === 'investment_property';

  const isVontiveBetterMatch =
    !loanProducts.length ||
    (loanProducts.length === 1 &&
      loanProducts[0].productType === 'unsecured_personal' &&
      loanAmountDesiredForRenovation >= 75000);
  const isLoanAmountValid =
    loanAmountDesiredForRenovation >= 75000 &&
    loanAmountDesiredForRenovation <= 1500000;
  const isHomeValueValid = homeValue >= 100000;
  const isCreditScoreValid = creditScore >= 660;
  const isStateSupported = !VONTIVE_EXCLUDED_STATES.includes(state);

  return (
    isLeadOriginTurndownSupported(lead) &&
    !recentBankruptcy &&
    isInvestmentProperty &&
    isVontiveBetterMatch &&
    isLoanAmountValid &&
    isHomeValueValid &&
    isCreditScoreValid &&
    isStateSupported
  );
};

export const isSplashAndSoFiCommonMatch = (lead) => {
  const {state, loanAmountDesiredForRenovation} = lead;

  const creditScore = getLeadCreditScore(lead);

  const stateAmountMap = {
    Georgia: 3000,
    Ohio: 5000,
    NewMexico: 5000,
    Massachusetts: 6000,
    NewHampshire: 10000,
    Arizona: 10000,
    Connecticut: 15000,
    Alaska: 25000,
    DC: 25000,
    Oregon: 50000,
  };

  const trimmedState = state?.split(' ').join('');
  const isStateAmountMatch = stateAmountMap[trimmedState]
    ? stateAmountMap[trimmedState] <= loanAmountDesiredForRenovation
    : true;

  if (loanAmountDesiredForRenovation < 20000) {
    return isLeadOriginTurndownSupported(lead) && isStateAmountMatch;
  }

  if (inRange(loanAmountDesiredForRenovation, 20000, 100001)) {
    return (
      isLeadOriginTurndownSupported(lead) &&
      !isLeadQualified(lead) &&
      isStateAmountMatch &&
      creditScore >= 580 &&
      creditScore < 680
    );
  }

  return false;
};

export const isLeadSplashMatch = (lead) => {
  const isStateSupported = !SPLASH_EXCLUDED_STATES.includes(lead.state);
  const isCommonMatch = isSplashAndSoFiCommonMatch(lead);

  return isCommonMatch && isStateSupported;
};

export const isLeadSoFiMatch = (lead) => {
  const isStateSupported = SOFI_AVAILABLE_STATES.includes(lead.state);
  const isCommonMatch = isSplashAndSoFiCommonMatch(lead);

  return isCommonMatch && isStateSupported;
};

export const isLeadTurndownPartnerMatch = (lead, loanProducts) => {
  return (
    isLeadPointMatch(lead) ||
    isLeadVontiveMatch(lead, loanProducts) ||
    isLeadSplashMatch(lead) ||
    isLeadSoFiMatch(lead)
  );
};
