import styled from '@emotion/styled/macro';
import {Image, Text, Box, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const OfferHeader = styled(Flex)(() =>
  mediaQuery({
    justifyContent: ['center', 'flex-start'],
    flexWrap: 'wrap',
    borderBottom: '1px solid #F8F8F8',
    padding: '20px 40px',
    alignItems: 'center',
  }),
);

export const OfferContainer = styled(Flex)(() =>
  mediaQuery({
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: ['10px 20px', '20px 40px'],
  }),
);

export const TestimonialSection = styled(Box)(() =>
  mediaQuery({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media screen and (max-width: 668px)': {
      flexDirection: 'column-reverse',
    },
  }),
);

export const BenefitItemWrapper = styled(Flex)(() =>
  mediaQuery({
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    margin: ['0 0 20px 0', '0'],
  }),
);

export const BenefitItemImage = styled(Image)(() =>
  mediaQuery({
    width: '24px',
    height: '24px',
  }),
);

export const BenefitItemText = styled(Text)(() =>
  mediaQuery({
    fontSize: ['20px', '24px'],
    lineHeight: ['24px', '43px'],
    fontWeight: 700,
  }),
);

export const DescriptionText = styled(Text)(() =>
  mediaQuery({
    fontSize: ['16px', '18px'],
    lineHeight: ['19px', '22px'],
    opacity: 0.7,
    textAlign: ['center', 'left'],
  }),
);
