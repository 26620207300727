import {useFlag} from '@renofi/utils/src/feature-flags';

import {isLeadSplashMatch, isLeadSoFiMatch} from './turndowns';

const useSplashOrSoFi = (lead) => {
  const sofiExperimentEnabled = useFlag('pos-sofi-experiment');

  return () => {
    const splashPath = `/personal/splash/${lead.id}`;
    const soFiPath = `/personal/sofi/${lead.id}`;
    if (isLeadSplashMatch(lead) && isLeadSoFiMatch(lead)) {
      return sofiExperimentEnabled ? soFiPath : splashPath;
    }
    if (isLeadSplashMatch(lead)) {
      return splashPath;
    }
    if (isLeadSoFiMatch(lead)) {
      return soFiPath;
    }

    return null;
  };
};

export default useSplashOrSoFi;
