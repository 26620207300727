import {
  land as landEnum,
  stageOfPurchase as purchaseEnum,
} from '@renofi/api/src/enums';

import {constants} from '../';

export default function useRenovationUtils(lead) {
  const {loanSubject, propertyOwnershipStatus, stageOfPurchase} = lead;

  const isHomePurchase = loanSubject === constants.LOAN_SUBJECT.HOME_PURCHASED;
  const isHomeBuild = loanSubject === constants.LOAN_SUBJECT.HOME_BUILD;
  const isAdu = loanSubject === constants.LOAN_SUBJECT.ADU;
  const isAduPurchase =
    isAdu && propertyOwnershipStatus === landEnum.to_be_purchased;
  const isLandPurchase =
    isHomeBuild && propertyOwnershipStatus === landEnum.to_be_purchased;
  const isPurchase = isHomePurchase || isAduPurchase;
  const isFinalizedPurchase =
    isPurchase &&
    (stageOfPurchase === purchaseEnum.offer_accepted ||
      stageOfPurchase === purchaseEnum.closed_not_moved_in);

  return {
    isPurchase,
    isFinalizedPurchase,
    isLandPurchase,
    isHomePurchase,
    isAduPurchase,
    isHomeBuild,
    isAdu,
  };
}
