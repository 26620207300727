import {makeVar} from '@apollo/client';

import {
  areAuthTokensExpired,
  getItem,
  getLocation,
  getRefreshTokenInterval,
  isJwtStale,
  logger,
  removeJwt,
  removeRefreshToken,
} from '@renofi/utils';
import {GENERATE_AUTH_TOKEN} from '@renofi/api';

import {ONE_MINUTE} from '../constants';

import setCookieByDomain from './setCookieByDomain';

const isLockAPIAvailable = navigator?.locks?.request;

const clearAuthAndLogout = () => {
  removeJwt();
  removeRefreshToken();
  getLocation()?.reload();
};

export const timeoutIdVar = makeVar(null);

export const stopAuthTokenRefresh = () => {
  clearTimeout(timeoutIdVar());
};

export default async (client, forceRefresh = false) => {
  const jwt = getItem('jwt');
  const refreshToken = getItem('refreshToken');
  const refreshInterval = getRefreshTokenInterval(jwt);

  async function validateAndRefresh() {
    const refreshAction = forceRefresh ? generateAuthToken : refreshAuthToken;

    if (forceRefresh) {
      stopAuthTokenRefresh();
    }

    if (isLockAPIAvailable) {
      const serviceName = process.env.REACT_APP_SERVICE_NAME;
      navigator?.locks?.request(`${serviceName}-refresh-token`, async () => {
        // refresh is locked across all browser tabs
        await refreshAction();
        // lock released with new token being saved in cookie
      });
    } else {
      await refreshAction();
    }
  }

  async function generateAuthToken() {
    const refreshToken = getItem('refreshToken');
    if (!refreshToken) {
      return false;
    }

    try {
      const rsp = await client.query({
        query: GENERATE_AUTH_TOKEN,
        fetchPolicy: 'no-cache',
        variables: {refreshToken},
      });

      if (Boolean(rsp?.errors)) {
        return clearAuthAndLogout();
      }

      const data = rsp?.data?.generateAuthToken;
      const newJwt = data?.jwt;
      const newRefreshToken = data?.refreshToken;
      const refreshInterval = getRefreshTokenInterval(newJwt);

      startTimeout(validateAndRefresh, refreshInterval);

      setCookieByDomain('jwt', newJwt);
      setCookieByDomain('refreshToken', newRefreshToken);
    } catch (err) {
      logger.error(err);
      clearAuthAndLogout();
    }
  }

  async function refreshAuthToken() {
    if (!isJwtStale() && isLockAPIAvailable) {
      const jwt = getItem('jwt');
      const refreshInterval = getRefreshTokenInterval(jwt);
      startTimeout(validateAndRefresh, refreshInterval);
      return;
    }

    const refreshToken = getItem('refreshToken');
    if (!isJwtStale() || !refreshToken) return;

    await generateAuthToken();
  }

  function startTimeout(callback, ms) {
    const interval = (ms / ONE_MINUTE).toFixed(2);
    logger.debug(`🔄 refreshing token in ${interval}mins`);
    timeoutIdVar(setTimeout(callback, ms));
  }

  if (!refreshToken || !jwt || areAuthTokensExpired() || refreshInterval <= 0) {
    stopAuthTokenRefresh();
    return false;
  }

  if (forceRefresh || isJwtStale()) {
    await validateAndRefresh();
  } else {
    await startTimeout(validateAndRefresh, refreshInterval);
  }

  return timeoutIdVar();
};
