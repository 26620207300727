import styled from '@emotion/styled';
import {Flex} from 'rebass';

import {Label} from '@renofi/components';
import {mediaQuery} from '@renofi/utils';

export const RangeLabel = styled(Label)(
  mediaQuery({
    width: ['50%', 200],
  }),
);

export const RangeWrapper = styled(Flex)(
  mediaQuery({
    width: ['100%', 500],
    alignItems: 'center',
    marginBottom: 16,
    '&:last-of-type': {
      marginBottom: 32,
    },
  }),
);
