import React, {useEffect} from 'react';

import {Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';

import {Buttons, Heading, Narrow} from '../components';

function Hawaii() {
  useEffect(() => sendEvent('POS/Hawaii-Page'), []);

  return (
    <>
      <Narrow maxWidth={720}>
        <Heading mt={24} css={{lineHeight: ['38px', '60px']}}>
          Unfortunately RenoFi is not currently available in Hawaii.
        </Heading>

        <Text my={24} fontSize={20}>
          But we’re working on it!
        </Text>

        <Text my={36} fontSize={20}>
          <strong>Check back again soon!</strong>
        </Text>
      </Narrow>

      <Buttons hideNext />
    </>
  );
}

export default Hawaii;
