import styled from '@emotion/styled/macro';
import {Select as RebassSelect} from '@rebass/forms';

import {lightGray, lightRed} from '@renofi/utils/src/colors';

export const Select = styled(RebassSelect)(
  {
    padding: '10px 13px',
    height: 50,
    ':focus': {
      outline: 'none',
    },
  },
  ({small, large, xLarge, error, fontSize, disabled}) => ({
    backgroundColor: disabled ? '#F2F2F2' : 'white',
    borderRadius: small ? 4 : 8,
    fontSize: (() => {
      if (fontSize) return fontSize;
      if (xLarge) return 24;
      if (large) return 20;
      return 16;
    })(),
    height: (() => {
      if (xLarge) return 60;
      if (small) return 44;
      return 50;
    })(),
    color: disabled ? lightGray : 'black',
    border: `solid 1px ${error ? lightRed : xLarge ? '#DCDCDC' : lightGray}`,
  }),
);
