import styled from '@emotion/styled/macro';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

export const CTAHeading = styled(Text)(() => ({
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '30px',
  textAlign: 'center',
  color: darkBlue,
  marginBottom: '20px',
}));

export const ImageContainer = styled(Flex)(({inset}) => {
  return mediaQuery({
    position: ['relative', 'absolute'],
    inset,
    marginLeft: 'auto',
    marginRight: 'auto',
  });
});

export const CTAWrapper = styled(Flex)(
  mediaQuery({
    padding: ['0 20px 40px 20px', '50px'],
    border: '1.00157px solid #E3E4E4',
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(0, 160, 255, 0.2)',
    borderRadius: '8px',
    flexDirection: ['column', 'row'],
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  }),
);
