import {makeVar} from '@apollo/client';
import {useHistory, useLocation} from 'react-router-dom';
import {snakeCase} from 'lodash';

import {useQuestionnaireByToken} from '@renofi/api/src/contractor';
import {useLocalStorage} from '@renofi/utils';

import onQuestionnaireFetch from '../callbacks/onQuestionnaireFetch';
import {isQuestionnaireCancelled} from '../cache';

const ACCESS_TO_PATHS = ['/confirm-references-details', '/rejected-documents'];

const mapAccessTo = (pathname) => {
  if (!ACCESS_TO_PATHS.includes(pathname)) {
    return;
  }

  return snakeCase(pathname.substring(1));
};

export const questionnaireIdVar = makeVar(null);

export default function useQuestionnaire({
  project,
  questionnaire = false,
} = {}) {
  const [token] = useLocalStorage('renofi:token');
  const history = useHistory();
  const getQuestionnaireByToken = useQuestionnaireByToken();
  const {pathname} = useLocation();

  return async () => {
    if (Boolean(questionnaire)) {
      onQuestionnaireFetch({project, questionnaire});
      questionnaireIdVar(questionnaire?.id);
      return questionnaire;
    }

    if (!token) {
      history.push('/not-found');
      return;
    }

    try {
      const accessTo = mapAccessTo(pathname);
      const {data} = await getQuestionnaireByToken({
        token,
        ...(accessTo ? {accessTo} : {}),
      });
      const questionnaire = data?.result;
      onQuestionnaireFetch({questionnaire});
      questionnaireIdVar(questionnaire?.id);

      const isCancelled = isQuestionnaireCancelled(questionnaire?.gcddReviews);
      if (isCancelled) {
        history.push('/cancelled');
      }

      return questionnaire;
    } catch (error) {
      if (error?.message === 'RECORD_NOT_FOUND') {
        history.push('/invalid-token');
      }
    }
  };
}
