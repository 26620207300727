import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {useNext} from '../hooks';
import {Heading} from '../components';
import {setLead, leadVar} from '../api/cache';
import YesNoSelect from '../components/YesNoSelect';

function VAStatus({updateScenario}) {
  const next = useNext();
  const {servedMilitary} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/VA-Status-Page'), []);

  function onSubmit(servedMilitary) {
    setLead({servedMilitary});
    updateScenario({servedMilitary});

    sendEvent('POS/VA-Status-Entered', {
      servedMilitary: servedMilitary,
    });
    next('/borrower-origin');
  }

  return (
    <>
      <Heading>Have you or your spouse served in the military?</Heading>
      <YesNoSelect value={servedMilitary} onSubmit={onSubmit} />
    </>
  );
}

VAStatus.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default VAStatus;
