import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';
import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {Button, StopwatchIcon} from '@renofi/components';

const Footer = styled.div(
  mediaQuery({
    position: 'relative',
    background: '#F8F8F8',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 40px 40px rgba(68, 68, 68, 0.113828)',
    justifyContent: 'center',
    padding: '36px 32px 29px 32px',
    margin: '0 -95px -15px -95px',
    borderRadius: '0 0 16px 16px',
  }),
);

export const DesktopFooter = ({onSubmit}) => {
  return (
    <Footer>
      <Flex width={300} alignItems="center" justifyContent="center">
        <StopwatchIcon style={{width: 50}} />
        <Text
          width={205}
          fontStyle="italic"
          color="#404040"
          ml={12}
          mt="4px"
          lineHeight="20px">
          Only takes about 5 minutes.
        </Text>
      </Flex>

      <Button style={{position: 'absolute', right: '15px'}} onClick={onSubmit}>
        Let&apos;s Begin
      </Button>
    </Footer>
  );
};

DesktopFooter.propTypes = {
  onSubmit: PropTypes.func,
};
