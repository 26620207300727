import React from 'react';

const Copyright = () => {
  return (
    <span>
      &copy; {new Date().getFullYear()} Renovation Finance LLC DBA RenoFi
    </span>
  );
};

export default Copyright;
