import {useEffect} from 'react';

const useHandleEnterPress = (enabled, onEnter) => {
  useEffect(() => {
    if (enabled) {
      const handleEnter = (event) => {
        if (event.keyCode === 13) {
          onEnter();
        }
      };

      document.addEventListener('keydown', handleEnter);

      return () => {
        document.removeEventListener('keydown', handleEnter);
      };
    }
  }, [enabled]);
};

export default useHandleEnterPress;
