import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {useNext} from '../../hooks';
import {Heading, Narrow} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

function Subcontractors({updateScenario}) {
  const next = useNext();
  const {subcontractorsIdentified} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Subcontractors-Page'), []);

  function onSubmit(subcontractorsIdentified) {
    setLead({subcontractorsIdentified});
    updateScenario({subcontractorsIdentified});

    sendEvent('POS/Subcontractors-Entered', {subcontractorsIdentified});
    next('/plans');
  }

  return (
    <Narrow maxWidth={700}>
      <Heading>
        Have you identified the subcontractors you&apos;ll be hiring?
      </Heading>
      <YesNoSelect value={subcontractorsIdentified} onSubmit={onSubmit} />
    </Narrow>
  );
}

Subcontractors.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default Subcontractors;
