import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {CurrencyField, CheckboxPanel} from '@renofi/components';

import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

function PersonalLoan({onChange, onToggle, noDebt}) {
  const {personalLoanPayment} = useReactiveVar(leadVar);
  const {isPersonalLoanGroupOpen} = useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Personal loan payment"
      onToggle={onToggle}
      value={isPersonalLoanGroupOpen}
      greyOut={noDebt}>
      <Flex>
        <Box width={1 / 2}>
          <SideLabel>
            <strong>Total monthly</strong> personal loan payments for all
            applicants
          </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <CurrencyField
            errMessages={{
              max: `You have entered $${toCurrency(
                personalLoanPayment,
              )} as your monthly personal loan payment. If that's incorrect, please update, otherwise continue.`,
            }}
            errorCss={{position: 'static'}}
            max={5000}
            icon
            name="personal-loan-payment"
            value={personalLoanPayment}
            onChange={(value) => onChange('personalLoanPayment', value)}
            analyticsEnabled
            analyticsPrefix="POS"
          />
        </Box>
      </Flex>
    </CheckboxPanel>
  );
}

PersonalLoan.propTypes = {
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default PersonalLoan;
