import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {usePrequalCompleted} from '@renofi/api';
import {isAuthenticated, isFakeLead} from '@renofi/utils/src/lead';

import routes from '../App/routes';
import {leadVar, progressVar} from '../api/cache';
import {shouldCallPrequalCompleted} from '../util';

import usePostPIIScreen from './usePostPIIScreen';

const privacyPaths = ['/phone', '/email', '/name'];

export default function useNext() {
  const history = useHistory();
  const lead = useReactiveVar(leadVar);
  const {id, prequalCompletedAt} = lead;
  const {locationIndex} = useReactiveVar(progressVar);
  const {prequalCompleted} = usePrequalCompleted();
  const getPostPIIPath = usePostPIIScreen();

  const next = async (path) => {
    // Skip email screen for all authenticated or completed leads
    if (path === '/email' && (prequalCompletedAt || isAuthenticated(id))) {
      return history.push('/phone');
    }

    // Skip privacy screens for none authenticated and completed leads
    if (
      privacyPaths.includes(path) &&
      !isAuthenticated(id) &&
      prequalCompletedAt
    ) {
      return history.push(getPostPIIPath(lead));
    }

    let nextPath = path || routes[locationIndex + 1]?.path || routes[0];

    if (lead?.id && !isFakeLead(lead) && shouldCallPrequalCompleted(nextPath)) {
      await prequalCompleted({variables: {scenarioId: id}});
    }

    history.push(nextPath);
  };

  return next;
}
