import React, {useEffect, useState} from 'react';

import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {getPercentFrom} from '@renofi/utils/src/format';
import {useRenovationUtils} from '@renofi/utils';
import {Toggle} from '@renofi/components';

import {Buttons, Heading, Slider, TooltipContent} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext} from '../hooks';
import {TooltipBox} from '../components';

const million = 1000000;

function AfterRenovationValue({updateScenario}) {
  const next = useNext();
  const [max, setMax] = useState(null);
  const lead = useReactiveVar(leadVar);

  const {renovationCost = 0, homeValue = 0, propertyPurchasePrice = 0} = lead;
  let {afterRenovatedValue} = lead;
  const propertyValue = propertyPurchasePrice || homeValue;

  let roi = 0.912;

  if (!afterRenovatedValue && propertyValue) {
    afterRenovatedValue = propertyValue + renovationCost * roi;
  } else if (!afterRenovatedValue && !propertyValue) {
    afterRenovatedValue = renovationCost;
  }

  const {isPurchase, isHomeBuild} = useRenovationUtils(lead);

  const currentROI = getPercentFrom(
    afterRenovatedValue - propertyValue,
    renovationCost,
    1,
  );

  useEffect(() => {
    sendEvent('POS/After-Renovation-Value-Page');
    if (propertyValue && renovationCost) {
      setMax(propertyValue + renovationCost * 2);
    }
  }, [propertyValue, renovationCost]);

  function onChange(value) {
    setLead({afterRenovatedValue: value});
  }

  function onSubmit() {
    onChange(afterRenovatedValue);

    updateScenario({afterRenovatedValue});

    sendEvent('POS/After-Renovation-Value-Entered', {afterRenovatedValue});

    let path = '/mortgage-balance';
    if (isPurchase || isHomeBuild) {
      path = '/credit-score';
    }

    next(path);
  }

  function getRoiMessage() {
    const upperBound = 95;

    if (currentROI >= upperBound) {
      return 'Only 6% of projects get this kind of ROI';
    }
    if (currentROI < upperBound && currentROI >= 71) {
      return 'Average renovation ROI.';
    }
    return 'Typical renovations get a higher ROI.';
  }

  return (
    <>
      <Heading>
        {isHomeBuild
          ? 'Expected property value after completion'
          : 'Expected after renovation home value is about'}
      </Heading>
      <Slider
        showExactMin
        noTooltip
        min={propertyValue}
        max={max || 2 * million}
        step={1000}
        onChange={onChange}
        value={afterRenovatedValue}>
        <Toggle show={!isHomeBuild}>
          <Text mt={-32} mb="8px">
            <strong>{currentROI}% ROI</strong> - {getRoiMessage()}
          </Text>
        </Toggle>
      </Slider>
      <Toggle show={!isHomeBuild}>
        <TooltipBox maxWidth={675} mx="auto" mt={40}>
          <TooltipContent>
            <strong>ROI</strong> can be heavily skewed by the renovations that
            you are undergoing and the current condition of the property.
          </TooltipContent>
        </TooltipBox>
      </Toggle>
      <Buttons onNext={onSubmit} />
    </>
  );
}

export default AfterRenovationValue;
