import React, {useLayoutEffect, useState, useRef} from 'react';

import {useResizeDetector} from 'react-resize-detector';
import {Box} from 'rebass';
import PropTypes from 'prop-types';

import {mediaQuery} from '@renofi/utils';

const RouteWrapper = ({children, onHeightChange, dynamic, ...props}) => {
  const ref = useRef(null);
  const refHeight = ref?.current?.clientHeight;
  const {height = refHeight} = useResizeDetector({
    targetRef: ref,
    handleWidth: false,
    ...(dynamic ? {} : {refreshMode: 'debounce', refreshRate: 300}),
  });
  const [top, setTop] = useState(props.top || 0);

  useLayoutEffect(() => {
    if (!height) return;

    const newTop = props.top || calculatePositionTop();
    setTop(props.top || newTop);

    setTimeout(() => {
      onHeightChange(height + newTop);
    });
  }, [height]);

  function calculatePositionTop() {
    const totalHeight = document
      .getElementsByTagName('body')[0]
      .getBoundingClientRect().height;
    const top = (totalHeight - 70 - 272 - height) / 2;
    return top > 36 ? top : 36;
  }

  return (
    <Box
      css={mediaQuery({
        height: '100%',
        zIndex: 100,
        width: ['calc(100% - 30px)', 960],
        top: [48, top],
        position: 'absolute',
      })}>
      <Box
        key={children}
        ref={ref}
        css={mediaQuery({
          width: '100%',
        })}>
        {children}
      </Box>
    </Box>
  );
};

RouteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  top: PropTypes.number,
  dynamic: PropTypes.bool,
};

export default RouteWrapper;
