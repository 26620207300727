const uspsAbbreviations = {
  Aly: 'Alley',
  Ave: 'Avenue',
  Blvd: 'Boulevard',
  Cswy: 'Causeway',
  Ctr: 'Center',
  Cir: 'Circle',
  Ct: 'Court',
  Cv: 'Cove',
  Xing: 'Crossing',
  Dr: 'Drive',
  Expy: 'Expressway',
  Ext: 'Extension',
  Fwy: 'Freeway',
  Grv: 'Grove',
  Hwy: 'Highway',
  Holw: 'Hollow',
  Jct: 'Junction',
  Ln: 'Lane',
  Mtwy: 'Motorway',
  Opas: 'Overpass',
  Park: 'Park',
  Pkwy: 'Parkway',
  Pl: 'Place',
  Plz: 'Plaza',
  Pt: 'Point',
  Rd: 'Road',
  Rte: 'Route',
  Skwy: 'Skyway',
  Sq: 'Square',
  St: 'Street',
  Ter: 'Terrace',
  Trl: 'Trail',
  Way: 'Way',
  S: 'South',
  W: 'West',
  N: 'North',
  E: 'East',
  Apt: 'Apartment',
  Bsmt: 'Basement',
  Bldg: 'Building',
  Dept: 'Department',
  Fl: 'Floor',
  Hngr: 'Hanger',
  Lbby: 'Lobby',
  Lowr: 'Lower',
  Ofc: 'Office',
  Ph: 'Penthouse',
  Rm: 'Room',
  Ste: 'Suite',
  Trlr: 'Trailer',
  Unit: 'Unit',
  Uppr: 'Upper',
};

export function replaceWithUSPSAbbreviations(streetAddress) {
  if (!streetAddress) return streetAddress;

  const regex = new RegExp(
    `\\b(${Object.values(uspsAbbreviations).join('|')})\\b`,
    'gi',
  );

  return streetAddress.replace(regex, (match) => {
    for (const abbreviation in uspsAbbreviations) {
      if (
        uspsAbbreviations[abbreviation].toLowerCase() === match.toLowerCase()
      ) {
        return abbreviation;
      }
    }
    return match; // If no abbreviation is found, keep the original name
  });
}
