import {useLazyQuery} from '@apollo/client';

import {VALIDATE_EMAIL} from '../queries/validateEmail';

export default function useValidateEmail(variables) {
  const [validateEmail, {loading, data, error}] = useLazyQuery(VALIDATE_EMAIL, {
    variables,
    fetchPolicy: 'cache-first',
  });
  const isEmailValid = data?.validateEmail?.valid;

  return {data, validateEmail, loading, isEmailValid, error};
}
