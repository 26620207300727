import React, {useState, useEffect, useRef} from 'react';

import PropTypes from 'prop-types';
import {noop} from 'lodash';

import {isEmail, isRenofiEmail} from '@renofi/utils/src/validate';
import {red} from '@renofi/utils/src/colors';
import {isMonoCharacterString, toCurrency} from '@renofi/utils/src/format';
import {useAutofocus} from '@renofi/utils';

import {InputWrapper, Input, Message, TopMessage} from './styled';
import TextFieldIcon from './TextFieldIcon';

const numbersRegex = /[^0-9.]/g;
const specialCharactersRegex = /[<>{}/|@#$%^&*()+=![\]\\]/g;
const filter = (value, regex) =>
  value ? String(value).replace(regex, '') : '';

const TextField = ({
  onChange = noop,
  onClickIcon = noop,
  onBlur = noop,
  limit,
  required,
  numbers, // value of number type
  digitsOnly, // keep string but allow digits only
  currency,
  min,
  max,
  icon,
  iconCss,
  iconPosition = 'left',
  message,
  topMessage,
  topMessageColor,
  email,
  renofiEmail,
  noSpecialCharacters,
  noNumbers,
  autofocus,
  value = '',
  error,
  errorCss,
  errMessages = {},
  isDirty = false,
  disabled,
  wrapperCss,
  ...props
}) => {
  const [stateError, setStateError] = useState(error);
  const [dirty, setDirty] = useState(isDirty || Boolean(value));
  const errorMessage = stateError || error;
  const ref = useRef(null);

  useEffect(() => {
    if (value === 0) return;
    const {err, validatedValue} = validate(value);
    onChangeWithValueType(validatedValue, err);
  }, [dirty, value]);

  useAutofocus(autofocus, ref, value);

  function needsNumberValidations() {
    return (numbers || currency) && dirty;
  }

  function onChangeWithValueType(validatedValue, err) {
    if (numbers) {
      onChange(!validatedValue ? null : Number(validatedValue), err);
    } else if (email) {
      onChange(validatedValue.trim(), err);
    } else {
      onChange(validatedValue, err);
    }
  }

  function validate(value) {
    let validatedValue = value;
    let err = undefined;

    if (numbers || currency || digitsOnly)
      validatedValue = filter(value, numbersRegex);
    if (needsNumberValidations() && min && validatedValue < min) {
      err = errMessages.min || `Must be greater or equal to ${min}.`;
    }
    if (needsNumberValidations() && max && validatedValue > max) {
      err = errMessages.max || `Must be smaller or equal to ${max}.`;
    }
    if (noSpecialCharacters && validatedValue?.match(specialCharactersRegex)) {
      err = 'No special characters allowed.';
    }
    if (noNumbers && validatedValue?.match(/\d/)) {
      err = 'No numbers allowed.';
    }
    if (email && !isEmail(validatedValue?.trim()) && dirty && validatedValue) {
      err = 'Please enter a valid email address.';
    }
    if (renofiEmail && !isRenofiEmail(validatedValue?.trim()) && dirty) {
      err = 'Email must be @renofi email address.';
    }
    if (
      required &&
      dirty &&
      validatedValue !== 0 &&
      (!validatedValue || isMonoCharacterString(' ', validatedValue))
    ) {
      err = 'This is required';
    }

    setStateError(err);
    return {err, validatedValue};
  }

  function onChangeValue(event) {
    const {value} = event.target;

    if (limit && value.replace(/\D/g, '').length > limit) return;
    const {err, validatedValue} = validate(value);

    onChangeWithValueType(validatedValue, err);
  }

  function onBlurValue(event) {
    const {value} = event.target;
    setDirty(true);
    onBlur(value);
  }

  function formatValue(val) {
    if (val === 0) return 0;
    if (!val) return '';
    return currency ? toCurrency(Number(val)) : val;
  }

  return (
    <InputWrapper
      css={wrapperCss}
      disabled={disabled ? true : undefined}
      error={stateError || error}
      icon={icon}
      iconPosition={iconPosition}
      xLarge={props.xLarge}
      small={props.small}
      iconCss={iconCss}>
      {Boolean(icon) ? (
        <TextFieldIcon
          error={stateError || error}
          icon={icon}
          iconCss={iconCss}
          iconPosition={iconPosition}
          onClick={onClickIcon}
          {...props}
        />
      ) : null}
      <Input
        error={stateError || error}
        value={formatValue(value)}
        ref={ref}
        onChange={onChangeValue}
        onBlur={onBlurValue}
        disabled={disabled ? true : undefined}
        {...props}
      />
      {topMessage ? (
        <TopMessage color={topMessageColor}>{topMessage}</TopMessage>
      ) : null}
      {errorMessage ? (
        <Message color={red} css={errorCss}>
          {errorMessage}
        </Message>
      ) : null}
      {message && !errorMessage ? (
        <Message css={errorCss}>{message}</Message>
      ) : null}
    </InputWrapper>
  );
};

TextField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  limit: PropTypes.number,

  min: PropTypes.number,
  max: PropTypes.number,
  numbers: PropTypes.bool,
  digitsOnly: PropTypes.bool,
  currency: PropTypes.bool,
  required: PropTypes.bool,
  xLarge: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconPosition: PropTypes.string,
  iconCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  message: PropTypes.string,
  topMessage: PropTypes.string,
  topMessageColor: PropTypes.string,
  email: PropTypes.bool,
  renofiEmail: PropTypes.bool,
  large: PropTypes.bool,
  noSpecialCharacters: PropTypes.bool,
  noNumbers: PropTypes.bool,
  autofocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errMessages: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  errorCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDirty: PropTypes.bool,
  wrapperCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default TextField;
