import React from 'react';

import PropTypes from 'prop-types';
import {findIndex, findLastIndex} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {ProgressBar} from '@renofi/components';

import routes from '../../App/routes';
import {progressVar} from '../../api/cache';
import {PROGRESS_SECTIONS} from '../../util/constants';

import {
  Wrapper,
  ProgressTitle,
  ProgressBarContainer,
  CheckImage,
  ProgressEndTitle,
} from './styled';
import CheckIcon from './img/circle-check.svg';

const ProgressBarModule = () => {
  const {locationIndex} = useReactiveVar(progressVar);

  const getSectionIndexes = (sectionId) => {
    const callback = (route) => route.progressSection === sectionId;
    return {
      firstIndex: findIndex(routes, callback),
      lastIndex: findLastIndex(routes, callback),
    };
  };

  const getProgress = (section) => {
    const {indexes} = section;
    if (indexes.firstIndex > locationIndex) {
      return 0;
    }

    if (indexes.lastIndex < locationIndex) {
      return 100;
    }

    if (locationIndex > -1) {
      return routes[locationIndex].subProgress || 0;
    }

    return 0;
  };

  const sections = [
    {
      id: PROGRESS_SECTIONS.RENOVATION_COST,
      title: 'Renovation cost',
      indexes: getSectionIndexes(PROGRESS_SECTIONS.RENOVATION_COST),
    },
    {
      id: PROGRESS_SECTIONS.CONTACT_INFO,
      title: 'Contact info',
      indexes: getSectionIndexes(PROGRESS_SECTIONS.CONTACT_INFO),
    },
    {
      id: PROGRESS_SECTIONS.PROPERTY_INFO,
      title: 'Property info',
      indexes: getSectionIndexes(PROGRESS_SECTIONS.PROPERTY_INFO),
    },
    {
      id: PROGRESS_SECTIONS.RENOVATION_TIMELINE,
      title: 'Renovation timeline',
      indexes: getSectionIndexes(PROGRESS_SECTIONS.RENOVATION_TIMELINE),
    },
    {
      id: PROGRESS_SECTIONS.INCOME_AND_PAYMENTS,
      title: 'Income & payments',
      indexes: getSectionIndexes(PROGRESS_SECTIONS.INCOME_AND_PAYMENTS),
    },
    {
      id: PROGRESS_SECTIONS.RENOVATION_EXECUTION,
      title: 'Final details',
      indexes: getSectionIndexes(PROGRESS_SECTIONS.RENOVATION_EXECUTION),
    },
  ];

  return (
    <Wrapper>
      {sections.map((sectionItem) => {
        return (
          <ProgressBarContainer key={sectionItem.title}>
            <ProgressTitle blurred={sectionItem.blurred}>
              {sectionItem.title}
            </ProgressTitle>
            <ProgressBar
              useIcon={false}
              height={6}
              progress={getProgress(sectionItem)}
            />
          </ProgressBarContainer>
        );
      })}
      <ProgressBarContainer>
        <ProgressEndTitle>Rates & Options</ProgressEndTitle>
        <CheckImage src={CheckIcon} />
      </ProgressBarContainer>
    </Wrapper>
  );
};

ProgressBarModule.propTypes = {
  section: PropTypes.string,
  progress: PropTypes.number,
};

export default ProgressBarModule;
