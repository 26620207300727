import {useState, useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {
  useUpdateScenario,
  useRenovationScenario,
  usePrequalCompleted,
} from '@renofi/api';

const useSetPresentedTurndownOption = (option) => {
  const {id} = useParams();
  const [isPrequalCompleted, setIPrequalCompleted] = useState(null);
  const {updateScenario} = useUpdateScenario({id});
  const queryScenario = useRenovationScenario();
  const {prequalCompleted} = usePrequalCompleted();

  useEffect(() => {
    const fetchScenario = async () => {
      if (id) {
        await prequalCompleted({variables: {scenarioId: id}});
        const scenario = await queryScenario({id});
        setIPrequalCompleted(
          Boolean(scenario?.data?.result?.lead?.prequalCompletedAt),
        );
      }
    };

    fetchScenario();
  }, [id]);

  useEffect(() => {
    if (isPrequalCompleted) {
      updateScenario({variables: {id, presentedTurndownOptions: [option]}});
    }
  }, [isPrequalCompleted]);
};

export default useSetPresentedTurndownOption;
