import React, {useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Flex, Link} from 'rebass';

import {deleteCookie} from '@renofi/utils';
import {Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {enableLocalStoringLead, resetLead} from '../api/cache';
import {usePosition} from '../hooks';

import footerBg from './img/footer-bg.svg';
import FooterLegal from './FooterLegal';

const isTest = process.env.REACT_APP_ENV === 'test';

const Footer = ({showBackground, onExpandCollapse}) => {
  const {isDesktop} = useScreenSize();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const position = usePosition();

  useEffect(() => {
    if (isTest) setShow(true);
    else setTimeout(() => setShow(true), 300);
  }, []);

  function onClear() {
    if (process.env.REACT_APP_ENV === 'production') return;

    deleteCookie('jwt');
    deleteCookie('renofi_id');
    deleteCookie('renofi_lead');
    deleteCookie('renofi_cookie_consent');
    deleteCookie('posStep');
    localStorage.clear();
    sessionStorage.clear();
    enableLocalStoringLead();
    resetLead({});
    history.push('/');
  }

  return (
    <Box opacity={show ? 1 : 0}>
      <Toggle show={isDesktop && showBackground}>
        <Box
          style={{
            userSelect: 'none',
            height: 130,
            backgroundPositionX: position * -700,
            transition: 'background-position-x .8s',
            backgroundImage: `url(${footerBg})`,
          }}
        />
      </Toggle>

      <FooterLegal
        onCollapse={() => onExpandCollapse(100)}
        onExpand={(height) => onExpandCollapse(height + 80)}
      />

      <Toggle show={process.env.REACT_APP_ENV !== 'production'}>
        <Flex
          justifyContent="flex-end"
          backgroundColor="white"
          css={{position: 'absolute', bottom: 0, right: 16, zIndex: 1000}}
          pb={24}>
          <Link css={{cursor: 'pointer'}} onClick={onClear}>
            Reset
          </Link>
        </Flex>
      </Toggle>
    </Box>
  );
};

Footer.propTypes = {
  onExpandCollapse: PropTypes.func.isRequired,
  showBackground: PropTypes.bool,
};

export default Footer;
