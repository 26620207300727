import React from 'react';

import PropTypes from 'prop-types';
import {Text, Image} from 'rebass';

import {ReviewGoogleLogoTime, ReviewGoogleStars} from './images';
import {IconSocialProof} from './styled';

function GoogleReviewSmall({review, reviewerName}) {
  return (
    <IconSocialProof>
      <Text width="100%" fontWeight="bold">
        {reviewerName}
      </Text>
      <Image src={ReviewGoogleStars} />
      <Text width="100%">{review}</Text>
      <Image src={ReviewGoogleLogoTime} />
    </IconSocialProof>
  );
}

GoogleReviewSmall.propTypes = {
  review: PropTypes.string.isRequired,
  reviewerName: PropTypes.string.isRequired,
};

export default GoogleReviewSmall;
