import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {toCurrency} from '@renofi/utils/src/format';
import {CurrencyField, CheckboxPanel} from '@renofi/components';

import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

function HomeEquity({onChange, onToggle, noDebt}) {
  const {secondaryMortgagePayment} = useReactiveVar(leadVar);
  const {isSecondaryMortgageGroupOpen} = useReactiveVar(paymentSelectionsVar);

  return (
    <CheckboxPanel
      label="Home Equity or HELOC payment"
      onToggle={onToggle}
      value={isSecondaryMortgageGroupOpen}
      greyOut={noDebt}>
      <Flex mb={24}>
        <Box width={1 / 2}>
          <SideLabel>
            <strong>Monthly</strong> Home Equity or HELOC payment
          </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <CurrencyField
            errMessages={{
              max: `You have entered $${toCurrency(
                secondaryMortgagePayment,
              )} as your monthly second mortgage payment. If that's incorrect, please update, otherwise continue.`,
            }}
            errorCss={{position: 'static'}}
            max={10000}
            aria-label="Secondary monthly mortgage payments"
            numbers
            icon
            name="secondary-mortgage-payment"
            value={secondaryMortgagePayment}
            onChange={(value) => onChange('secondaryMortgagePayment', value)}
            analyticsEnabled
            analyticsPrefix="POS"
          />
        </Box>
      </Flex>
    </CheckboxPanel>
  );
}

HomeEquity.propTypes = {
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default HomeEquity;
