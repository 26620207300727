import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {AlertWrapper, AlertContent} from './styled';
import {ReactComponent as InfoIcon} from './img/info.svg';
import {ReactComponent as SuccessIcon} from './img/success.svg';

const Alert = ({
  children,
  warning,
  info,
  icon,
  success,
  successIcon,
  css,
  ...props
}) => {
  return (
    <AlertWrapper
      info={info}
      success={success}
      warning={warning}
      {...props}
      css={{marginTop: 8, ...css}}>
      <AlertContent
        info={info}
        success={success}
        warning={warning}
        color={props.color}
        alignItems={props.alignItems || 'center'}>
        {icon ? (
          <Box height={20} minWidth={32}>
            <InfoIcon />
          </Box>
        ) : null}
        {successIcon ? (
          <Box height={23} minWidth={32}>
            <SuccessIcon />
          </Box>
        ) : null}
        <Box>{children}</Box>
      </AlertContent>
    </AlertWrapper>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.bool,
  success: PropTypes.bool,
  successIcon: PropTypes.bool,
  warning: PropTypes.bool,
  info: PropTypes.bool,
  border: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  bgColor: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  large: PropTypes.bool,
};

export default Alert;
