import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {red} from '@renofi/utils/src/colors';

import {Message} from '../TextField';

import {Select} from './styled';

const SelectField = ({
  onChange,
  disabled,
  onFocus = () => {},
  required,
  customError = false,
  error = null,
  ...props
}) => {
  const [dirty, setDirty] = useState(Boolean(props.value));

  function onChangeValue(event) {
    setDirty(true);
    onChange(event.target.value);
  }
  const showRequiredMessage =
    (customError && error) ||
    (!customError && required && dirty && !props?.value);

  return (
    <>
      <Select
        disabled={disabled}
        onFocus={onFocus}
        onChange={onChangeValue}
        {...props}
      />
      {showRequiredMessage && (
        <Message position="static" color={red}>
          {error || 'This is required'}
        </Message>
      )}
    </>
  );
};

SelectField.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  customError: PropTypes.bool,
  error: PropTypes.string,
};

export default SelectField;
