import {useMemo} from 'react';

import {isEmpty, isNil} from 'lodash';

import {
  fixMalformedSearch,
  getDomain,
  getLocation,
  isJsonString,
  isSearchMalformed,
} from '@renofi/utils';
import {isForcingUTM} from '@renofi/analytics/src/analytics';

import useCookie from './useCookies';

const UTM_TAGS = ['campaign', 'content', 'medium', 'source', 'term'];

const useUTMTags = (utmCookieKey = '') => {
  const {cookie, updateCookie} = useCookie(utmCookieKey, '');

  const utmTags = useMemo(() => {
    const {search = ''} = getLocation() || {};
    const searchString = isSearchMalformed(search)
      ? fixMalformedSearch(search)
      : search;
    const queryParams = new URLSearchParams(searchString);

    const urlTags = UTM_TAGS.reduce((acc, tag) => {
      const param = queryParams.get(`utm_${tag}`);
      if (param) {
        return {
          ...acc,
          [tag]: param,
        };
      }

      return acc;
    }, {});

    if (!isForcingUTM() && cookie && isJsonString(cookie)) {
      return JSON.parse(cookie);
    } else {
      return !isEmpty(urlTags) && !isNil(urlTags) ? urlTags : cookie;
    }
  }, [cookie]);

  const persistUTMTags = (getDurationDays = () => 30, domain = getDomain()) => {
    if (!Object.keys(utmTags).length) {
      return;
    }
    updateCookie({
      value: JSON.stringify(utmTags),
      numberOfDays: getDurationDays(utmTags),
      domain: domain,
    });
  };

  const utmTagsToQueryString = () => {
    return Object.keys(utmTags).reduce((acc, tag) => {
      const utm = `utm_${tag}`;
      const value = utmTags[tag];
      const param = `${utm}=${value}`;
      return acc ? `${acc}&${param}` : param;
    }, '');
  };

  return {utmTags, persistUTMTags, utmTagsToQueryString};
};

export default useUTMTags;
