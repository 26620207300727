import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const StickyBottomFooter = styled(Box)(
  mediaQuery({
    position: ['relative', 'static'],
    overflowAnchor: 'none',
  }),
  ({sticky, css}) =>
    mediaQuery({
      ...(sticky
        ? {
            marginBottom: 0,
            borderTop: 'none',
          }
        : {}),
      ...css,
    }),
);
