import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {isLeadEarlyVontiveMatch} from '@renofi/utils/src/turndowns';
import {constants} from '@renofi/utils';
import {
  stageOfPurchase as stageOfPurchaseEnums,
  land as landEnums,
} from '@renofi/api/src/enums';
import {sendEvent} from '@renofi/analytics';
import {CheckboxPanel} from '@renofi/components';

import {useNext} from '../hooks';
import {Buttons, Heading, Narrow} from '../components';
import {setLead, leadVar, loanProductsVar} from '../api/cache';

function Bankruptcy({updateScenario}) {
  const {to_be_purchased} = landEnums;
  const {LOAN_SUBJECT} = constants;
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const loanProducts = useReactiveVar(loanProductsVar) || [];
  const {
    loanSubject,
    recentBankruptcy,
    stageOfPurchase,
    bankruptcyState,
    propertyOwnershipStatus,
  } = lead;
  const {
    bankruptcy,
    foreclosure,
    shortSale,
    modification,
    forbearance,
    noIssues = recentBankruptcy === false ? true : undefined,
  } = bankruptcyState || {};

  useEffect(() => sendEvent('POS/Bankruptcy-Page'), []);

  function onSubmit() {
    let path = '/renovation-start';

    if (recentBankruptcy) {
      path = '/bankruptcy/message';
    } else if (
      [LOAN_SUBJECT.HOME_PURCHASED, LOAN_SUBJECT.ADU].includes(loanSubject) &&
      propertyOwnershipStatus === to_be_purchased &&
      stageOfPurchase === stageOfPurchaseEnums.offer_accepted
    ) {
      path = '/purchase-closing';
    } else if (
      stageOfPurchase === stageOfPurchaseEnums.researching ||
      stageOfPurchase === stageOfPurchaseEnums.making_offers
    ) {
      path = '/combined-income';
    } else if (isLeadEarlyVontiveMatch(lead, loanProducts)) {
      path = `/investment/vontive/${lead.id}`;
    } else if (
      lead.propertyUseType === 'investment_property' &&
      loanProducts.length < 1
    ) {
      path = '/property-use/message';
    }

    updateScenario({recentBankruptcy});

    sendEvent('POS/Bankruptcy-Entered', {recentBankruptcy});
    next(path);
  }

  function onToggle(value) {
    setLead({
      bankruptcyState: {...bankruptcyState, ...value, noIssues: false},
      recentBankruptcy: true,
    });
  }

  return (
    <>
      <Heading>
        Have you ever experienced any of these debt reliefs in the past 7 years?
      </Heading>
      <Narrow maxWidth={500}>
        <CheckboxPanel
          keepHover
          label="Bankruptcy"
          onToggle={() => onToggle({bankruptcy: !bankruptcy})}
          value={bankruptcy}
        />
        <CheckboxPanel
          keepHover
          label="Foreclosure"
          onToggle={() => onToggle({foreclosure: !foreclosure})}
          value={foreclosure}
        />
        <CheckboxPanel
          keepHover
          label="Short Sale"
          onToggle={() => onToggle({shortSale: !shortSale})}
          value={shortSale}
        />
        <CheckboxPanel
          keepHover
          label="Modification"
          onToggle={() => onToggle({modification: !modification})}
          value={modification}
        />
        <CheckboxPanel
          keepHover
          label="Benefits of a mortgage forbearance"
          onToggle={() => onToggle({forbearance: !forbearance})}
          value={forbearance}
        />
        <CheckboxPanel
          keepHover
          label="No"
          onToggle={() =>
            setLead({
              recentBankruptcy: false,
              bankruptcyState: {
                noIssues: !noIssues,
                bankruptcy: false,
                foreclosure: false,
                shortSale: false,
                modification: false,
                forbearance: false,
              },
            })
          }
          value={noIssues}
        />
      </Narrow>

      <Buttons
        onNext={onSubmit}
        disableNext={
          !noIssues &&
          !foreclosure &&
          !shortSale &&
          !modification &&
          !forbearance &&
          !bankruptcy
        }
      />
    </>
  );
}

Bankruptcy.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default Bankruptcy;
