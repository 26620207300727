import React, {isValidElement} from 'react';

import PropTypes from 'prop-types';

import {IconWrapper} from './styled';

const TextFieldIcon = ({
  error,
  icon,
  iconCss,
  iconPosition,
  onClick,
  small,
  xLarge,
}) => {
  const props = {error, iconCss, iconPosition, onClick, small, xLarge};

  if (isValidElement(icon)) {
    return <IconWrapper {...props}>{icon}</IconWrapper>;
  }

  return <IconWrapper {...props} icon={icon} />;
};

TextFieldIcon.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  iconPosition: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  xLarge: PropTypes.bool,
};

export default TextFieldIcon;
