import {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';

import {useNext} from '../../hooks';
import {Heading} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

function ContractorLicensed({updateScenario}) {
  const next = useNext();
  const {isLicensedContractor} = useReactiveVar(leadVar);

  useEffect(() => sendEvent('POS/Contractor-Licensed-Page'), []);

  function onSubmit(isLicensedContractor) {
    setLead({isLicensedContractor});
    updateScenario({isLicensedContractor});

    sendEvent('POS/Contractor-Licensed-Entered', {isLicensedContractor});

    if (isLicensedContractor) {
      next('/plans');
    } else {
      next('/contractors/licensed/confirmation');
    }
  }

  return (
    <>
      <Heading>Are you a licensed contractor / general contractor?</Heading>
      <YesNoSelect value={isLicensedContractor} onSubmit={onSubmit} />
    </>
  );
}

ContractorLicensed.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default ContractorLicensed;
