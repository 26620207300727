import {first} from 'lodash';

export function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export function isQueryParamSet(param) {
  const value = getQueryParam(param);
  return Boolean(value && value !== 'false');
}

export function isSearchMalformed(search) {
  return (search.match(/\?/g) || []).length > 1;
}

export function fixMalformedSearch(search) {
  const array = search?.split('?');
  const validSearch = array?.length > 1 ? array[1] : first(array);
  return `?${validSearch}`;
}
