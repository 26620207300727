import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const ProgressWrapper = styled(Flex)(
  mediaQuery({
    margin: ['-15px -30px 32px -30px', '0 -95px 36px -95px'],
    padding: ['30px 7px 15px 7px', '36px 30px 20px 30px'],
    background: ['white', 'none'],
    boxShadow: ['0px 2px 2px rgba(0, 0, 0, 0.15)', 'none'],
    borderBottom: [0, '1px solid rgba(216, 216, 216, 0.5)'],
  }),
);
