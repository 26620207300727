import React, {useEffect, useState} from 'react';

import {every, isNil} from 'lodash';
import {useReactiveVar} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import {useFeatureFlagVariantEnabled} from '@renofi/utils';
import {Label, TextField, Loader} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';
import {isMonoCharacterString} from '@renofi/utils/src/format';
import {useUpdateScenarioPersonal} from '@renofi/api';
import {isAuthenticated, isLeadValidForCreation} from '@renofi/utils/src/lead';

import {
  Heading,
  Buttons,
  Narrow,
  IconsSocialProofWrapper,
  GoogleReview,
  FeatureReview,
  FundingSmall,
  AsSeenOn,
  AvailableOptionsAlert,
  AvailableOptionsAlertV2,
} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext, useCreateFakeLead, usePostPIIScreen} from '../hooks';

function Name() {
  const history = useHistory();
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {firstName, lastName, id} = lead;
  const {updateScenarioPersonal} = useUpdateScenarioPersonal({id});
  const [errors, setErrors] = useState({});
  const isFormValid =
    every(errors, isNil) &&
    lastName &&
    firstName &&
    !isMonoCharacterString(' ', lastName) &&
    !isMonoCharacterString(' ', firstName);

  const [loading, setLoading] = useState(true);
  const dashboardSignupEnabled = useFeatureFlagVariantEnabled(
    'dashboard-sign-up',
    'challenger',
  );
  const createFakeLead = useCreateFakeLead();
  const getPostPIIScreen = usePostPIIScreen();

  const piiScreenAvailableOptionsV1Enabled = useFeatureFlagVariantEnabled(
    'pos-pii-screen-available-options',
    'v1-loanpass-data',
  );

  const piiScreenAvailableOptionsV2Enabled = useFeatureFlagVariantEnabled(
    'pos-pii-screen-available-options',
    'v2-hardcoded-random',
  );

  useEffect(() => {
    if (
      dashboardSignupEnabled &&
      isLeadValidForCreation(lead, ['selfReportedCreditScore'])
    ) {
      if (!isAuthenticated(lead.id)) {
        createFakeLead(() => history.push('/loan-type'));
      }
      history.push(getPostPIIScreen(lead));
      return;
    }
    sendEvent('POS/Lead-Name-Page');
    setLoading(false);
  }, [dashboardSignupEnabled]);

  function onChange(key, value, err) {
    setLead({[key]: value});
    setErrors({...errors, [key]: err});
  }

  function onSubmit() {
    if (isAuthenticated(id)) {
      updateScenarioPersonal({variables: {id, firstName, lastName}});
    }
    sendEvent('POS/Lead-Name-Entered', {firstName, lastName});
    next('/email');
  }

  if (loading) return <Loader />;

  return (
    <>
      {piiScreenAvailableOptionsV1Enabled && <AvailableOptionsAlert />}
      {piiScreenAvailableOptionsV2Enabled && (
        <AvailableOptionsAlertV2 hasLoader={true} />
      )}
      <Narrow>
        <Heading>My name is</Heading>
        <Label small htmlFor="first-name">
          First name
        </Label>
        <TextField
          autofocus
          css={{boxShadow: '0px 0px 7px 0px #00A0FF1A'}}
          xLarge
          required
          noSpecialCharacters
          noNumbers
          mt="5px"
          value={firstName}
          onChange={(value, err) => onChange('firstName', value, err)}
          id="first-name"
          name="first-name"
          type="text"
        />
        <Label small htmlFor="last-name" mt={26}>
          Last name
        </Label>
        <TextField
          css={{boxShadow: '0px 0px 7px 0px #00A0FF1A'}}
          xLarge
          required
          noSpecialCharacters
          noNumbers
          mt="5px"
          value={lastName}
          onChange={(value, err) => onChange('lastName', value, err)}
          id="last-name"
          name="last-name"
          type="text"
        />

        <Buttons
          fullWidth
          hideBack
          onNext={onSubmit}
          disableNext={!isFormValid}
        />
      </Narrow>
      <IconsSocialProofWrapper>
        <GoogleReview
          reviewerName="David Morris"
          review="We are very happy with the final product and would highly recommend using the service!"
        />
        <FeatureReview />
        <FundingSmall />
        <AsSeenOn />
      </IconsSocialProofWrapper>
    </>
  );
}

export default Name;
