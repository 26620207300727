import React from 'react';

import {Text, Box, Flex, Link} from 'rebass';
import {noop} from 'lodash';

import {ReactComponent as InfoIcon} from './img/info.svg';

const NoneFound = ({onClick = noop}) => {
  const onClickLink = (e) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <Text fontSize={14} onClick={onClick}>
      <Flex alignItems="center">
        <InfoIcon />
        <Box mt="1px" ml={10} mr="4px" as="span">
          Can’t find your address?
        </Box>
        <Link href="" onClick={onClickLink}>
          Add address details manually
        </Link>
      </Flex>
    </Text>
  );
};

export default NoneFound;
