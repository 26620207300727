import {useQuery} from '@apollo/client';
import {noop} from 'lodash';

import {PRICE_SCENARIO} from '../queries/priceScenario';

export default function usePriceScenario({onCompleted = noop} = {}) {
  const {refetch} = useQuery(PRICE_SCENARIO, {
    skip: true,
    onCompleted,
  });
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
