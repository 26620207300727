import {useEffect} from 'react';

import {Text} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';

import {Buttons, Heading, Narrow} from '../components';

function HomeTypeMessage() {
  useEffect(() => sendEvent('POS/Home-Type-Failure-Page'), []);

  return (
    <Narrow maxWidth={720}>
      <Heading css={{lineHeight: ['38px', '60px']}}>
        Unfortunately, there are no lenders to apply with at this time offering
        RenoFi Loans for this type of property.
      </Heading>

      <Text fontSize={24} color={gray} fontWeight={300} lineHeight="36px">
        As soon as a lender is available we will reach out to let you know.
      </Text>
      <Buttons hideNext />
    </Narrow>
  );
}

export default HomeTypeMessage;
