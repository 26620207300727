import {useEffect, useState} from 'react';

import {every, isNil} from 'lodash';
import {Text, Box, Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {useSplashOrSoFi} from '@renofi/utils/src/turndowns';
import {useValidatePhoneNumber, useUpdateScenarioPersonal} from '@renofi/api';
import {useScreenSize, useRainforest} from '@renofi/utils';
import {Label, PhoneField, Alert} from '@renofi/components';
import {darkGreen, fadeGreen, gray, lightGreen} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';
import {isAuthenticated} from '@renofi/utils/src/lead';

import {
  Heading,
  Buttons,
  Narrow,
  IconsSocialProofWrapper,
  GoogleReviewSmall,
  FeatureReview,
  Funding,
  FundingSmall,
  TCPAConsentCheckbox,
} from '../components';
import {leadVar, setLead} from '../api/cache';
import {useNext, useGiveTcpaConsent, usePostPIIScreen} from '../hooks';

import {ReactComponent as ArrowIcon} from './img/arrow.svg';

function Phone() {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {phone, tcpaConsent, id} = lead;
  const {updateScenarioPersonal} = useUpdateScenarioPersonal({id});
  const {isRainforest} = useRainforest();
  const [loading, setLoading] = useState(false);
  const [onlineValidationSuccess, setOnlineValidationSuccess] = useState(null);
  const giveTcpaConsent = useGiveTcpaConsent();
  const getNextPath = usePostPIIScreen();
  const {isDesktop} = useScreenSize();
  const [errors, setErrors] = useState({});
  const isFormValid = every(errors, isNil) && phone && phone.length === 10;
  const validatePhoneNumber = useValidatePhoneNumber();

  const getSplashOrSoFi = useSplashOrSoFi(lead);

  useEffect(() => sendEvent('POS/Phone-Page'), []);

  function onChange(key, value, err) {
    setOnlineValidationSuccess(null);
    setLead({[key]: value});
    setErrors({...errors, [key]: err});
  }

  function onNext() {
    const splashOrSoFi = getSplashOrSoFi();

    if (Boolean(splashOrSoFi)) {
      next(splashOrSoFi);
      return;
    }

    const nextPath = getNextPath(lead);
    next(nextPath);
  }

  async function validateOnline() {
    const {data, error} = await validatePhoneNumber({phoneNumber: phone});
    const isPhoneNumberValid = !isNil(data?.validatePhoneNumber);

    if (!error && !isPhoneNumberValid) {
      setLoading(false);
      setOnlineValidationSuccess(false);
      return false;
    }

    return true;
  }

  async function onSubmit() {
    setLoading(true);

    if (!isRainforest) {
      const isValid = await validateOnline();
      if (!isValid) {
        return;
      }
    }

    sendEvent('POS/Phone-Entered', {
      phone,
      tcpaConsent: !!tcpaConsent,
    });

    if (isAuthenticated(id)) {
      updateScenarioPersonal({variables: {id, phone}});
    }
    if (tcpaConsent) {
      giveTcpaConsent(lead);
    }
    onNext();
  }

  async function onCheck(value) {
    setLead(value);
  }

  return (
    <>
      <Heading>My phone number is</Heading>
      <Narrow maxWidth={366}>
        <Label small htmlFor="phone">
          Phone number
        </Label>
        <PhoneField
          error={
            onlineValidationSuccess === false
              ? 'Please enter a valid phone number.'
              : ''
          }
          autofocus
          icon
          css={{
            boxShadow: '0px 0px 7px 0px #00A0FF1A',
            marginTop: '5px',
            marginBottom: 32,
          }}
          required
          xLarge
          value={phone}
          onChange={(value, err) => onChange('phone', value, err)}
          id="phone"
          name="phone"
          type="tel"
        />
        <Alert
          color={darkGreen}
          bgColor={fadeGreen}
          border={`solid 1px ${lightGreen}`}>
          <Text lineHeight="18px" css={{fontWeight: '300'}}>
            We NEVER share your information. The only company that will call you
            is RenoFi.
          </Text>
        </Alert>
        <Heading
          xsmall
          left
          css={{
            margin: '16px 0 5px',
          }}>
          We value your privacy
        </Heading>
        <Box>
          <TCPAConsentCheckbox
            checked={tcpaConsent}
            onChange={(value) => onCheck(value)}
          />
        </Box>
        <Flex
          justifyContent="center"
          css={{position: 'relative'}}
          flexDirection="column"
          alignItems="center">
          <Buttons
            loading={loading}
            hideBack
            nextButtonProps={
              tcpaConsent ? {secondary: !tcpaConsent} : {secondary: true}
            }
            onNext={onSubmit}
            disableNext={!isFormValid}
          />
          {isDesktop && (
            <Box sx={{margin: '40px 30px 20px 0'}}>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '25px',
                  left: '10px',
                }}>
                <ArrowIcon />
              </Box>
              <Text color={gray} opacity={0.6}>
                We don&apos;t share your information
              </Text>
            </Box>
          )}
        </Flex>
      </Narrow>
      <IconsSocialProofWrapper>
        <Funding />
        <FeatureReview />
        <FundingSmall />
        <GoogleReviewSmall
          reviewerName="brandon fleischman"
          review="We just closed on our RenoFi Loan from a local credit union and our renovation is starting soon..."
        />
      </IconsSocialProofWrapper>
    </>
  );
}

export default Phone;
