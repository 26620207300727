let timeout;

export default (func, time = 10) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    func();
    timeout = null;
  }, time);

  return timeout;
};
