import {makeVar} from '@apollo/client';

// Reactive variables
export const companyVar = makeVar({
  businessName: '',
  businessType: '',
  businessTypeOtherDetails: '',
  website: '',
  officePhoneNumber: '',
  subsidaries: [],

  streetAddress: '',
  secondaryAddress: '',
  city: '',
  state: '',
  zipCode: '',

  teamSize: '',
  hasW2Employees: false,

  percentageLeadsNeedingExpertDesign: '',
  percentageSalesSpentOnMarketing: '',
  residentialHomeRemodelBlueprintingOption: '',
  residentialHomeRemodelBlueprintingOptionOther: '',
  residentialHomeRemodelRole: '',
  residentialHomeRemodelRoleOther: '',
});

// Setter
export const setCompany = (contact) => {
  companyVar({...companyVar(), ...contact});
};

// Apollo field policy
export const companyPolicy = {
  read() {
    return companyVar();
  },
};

export default {
  companyPolicy,
  setCompany,
};
