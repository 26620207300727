import {isTestSession} from '@renofi/analytics';
import {initLogRocket} from '@renofi/analytics/src/logrocket2';
import {initSegment} from '@renofi/analytics/src/segment';
import {initSentry} from '@renofi/analytics/src/sentry';
import {initDelighted} from '@renofi/analytics/src/delighted';
import {initAccessibe} from '@renofi/analytics/src/accessibe';

export const initAnalytics = () => {
  if (isTestSession()) {
    return;
  }

  initSegment();
  initDelighted();
  initAccessibe();
};

export const initLogAnalytics = () => {
  initLogRocket();
  initSentry();
};
