import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {StepContainer, StepContent, Title, Description} from './styled';

const Step = ({title, description}) => (
  <StepContainer>
    <Flex justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <Box>
        <StepContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </StepContent>
      </Box>
    </Flex>
  </StepContainer>
);

Step.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Step;
