import {useEffect} from 'react';

import {useHistory} from 'react-router-dom';

export default function useScrollToTopBetweenRoutes() {
  const history = useHistory();
  useEffect(() => {
    history.listen(async () => {
      const root = document.getElementById('root');
      if (root && root.scrollTo) {
        root.scrollTo(0, 0);
      }
    });
  });
}
