import React, {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {sendEvent, chilipiper} from '@renofi/analytics';
import {Chilipiper as ChilipiperModule} from '@renofi/modules';

import {Wrapper} from '../App/styled';
import {leadVar} from '../api/cache';

const Schedule = () => {
  const lead = useReactiveVar(leadVar);

  useEffect(() => {
    sendEvent('POS/Schedule-Page');
  }, []);

  function onReady(newLead) {
    chilipiper.initSchedule({
      lead: newLead,
      analyticsPrefix: 'POS',
      domElement: '#chilipiper-wrapper',
    });
  }

  return (
    <Wrapper width={['100%', 1140]} className="chilipiper-within-page">
      <ChilipiperModule lead={lead} onReady={onReady} title=" " />
    </Wrapper>
  );
};

export default Schedule;
