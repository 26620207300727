import React from 'react';

import {size} from 'lodash';
import PropTypes from 'prop-types';

import {lightGray, blue, red, lightRed} from '@renofi/utils/src/colors';
import {useScreenSize} from '@renofi/utils';

import {Button} from '../index';
import {Message} from '../TextField';

import {ButtonSelectWrapper} from './styled';

const ButtonSelect = ({
  options = {},
  value = null,
  onClick,
  inline,
  small,
  large,
  css,
  buttonCss,
  required,
  internal,
  error,
}) => {
  const {isDesktop} = useScreenSize();
  const showRequiredMessage = required && value === null;

  function onSelect(optionValue) {
    if (optionValue === value) return;
    onClick && onClick(optionValue);
  }

  function getBorderColor(isSelected) {
    if (error) return lightRed;
    if (isSelected) return internal ? blue : red;
    return lightGray;
  }

  return (
    <>
      <ButtonSelectWrapper
        inline={inline && (isDesktop || size(options) <= 2)}
        css={css}
        internal={internal}
        buttonCss={buttonCss}>
        {Object.entries(options).map(([key, optionValue]) => (
          <Button
            small={small}
            large={large}
            css={{
              textTransform: 'none',
              ...(small ? {padding: '8px 18px', height: 44, fontSize: 16} : {}),
            }}
            noHover={value === optionValue}
            secondary={value !== optionValue}
            borderColor={getBorderColor(value === optionValue)}
            internal={internal && value === optionValue}
            key={optionValue}
            onClick={() => onSelect(optionValue)}>
            {key}
          </Button>
        ))}
      </ButtonSelectWrapper>
      {showRequiredMessage && (
        <Message position="static" color={red}>
          This is required
        </Message>
      )}
    </>
  );
};

ButtonSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  internal: PropTypes.bool,
  error: PropTypes.bool,
  small: PropTypes.bool,
  css: PropTypes.object,
  buttonCss: PropTypes.object,
  activeBgColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
  required: PropTypes.bool,
};

export default ButtonSelect;
