import React from 'react';

import PropTypes from 'prop-types';

import {HeroContainer, HeroSectionHeading, HeroSectionWrapper} from './styled';

const HeroSection = ({heading, children}) => (
  <HeroContainer>
    <HeroSectionWrapper>
      <HeroSectionHeading>{heading}</HeroSectionHeading>
      {children}
    </HeroSectionWrapper>
  </HeroContainer>
);
HeroSection.propTypes = {
  heading: PropTypes.object,
  children: PropTypes.object,
};
export default HeroSection;
