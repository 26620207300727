import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Link, Box} from 'rebass';

import {throttle} from '@renofi/utils';
import {lightGray} from '@renofi/utils/src/colors';

import Loader from '../Loader';
import {TextField, Toggle} from '../index';
import ClickOutside from '../ClickOutside/ClickOutside';

import {ReactComponent as ClearIcon} from './img/clear.svg';
import {
  SearchWrapper,
  LoaderWrapper,
  CloseButtonWrapper,
  OptionsWrapper,
} from './styled';

const IS_TEST = process.env.NODE_ENV === 'test';
const DELAY = IS_TEST ? 0 : 600;
let timeout;

function SearchField({
  value,
  onChange,
  loading,
  placeholder = 'Search borrowers',
  onSearch,
  onSelect,
  floating,
  autocomplete = true,
  autofocus,
  children,
  xLarge,
  noneFoundComponent,
  onNoneFoundClick,
  css,
  ...props
}) {
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  async function onFocus() {
    if (value) {
      searchByValue(value);
    }
  }

  function onChangeValue(newValue) {
    onChange(newValue);

    if (newValue === value) return;

    if (!newValue || newValue.length < 2) {
      setShowOptions(false);
      return;
    }

    searchByValue(newValue);
  }

  function onSelectValue(...args) {
    setShowOptions(false);
    onSelect(...args);
  }

  function onClear() {
    onChange('');
    setShowOptions(false);
  }

  function onClickOutside() {
    if (value?.length > 0 && showOptions) {
      onNoneFoundClick && onNoneFoundClick();
    }
    setShowOptions(false);
  }

  function searchByValue(v) {
    clearTimeout(timeout);
    timeout = throttle(async () => {
      await onSearch(v);
      setShowOptions(true);
    }, DELAY);
  }

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <SearchWrapper css={css}>
        <Box css={{position: 'relative'}}>
          <TextField
            placeholder={placeholder}
            autofocus={autofocus}
            autocomplete={autocomplete}
            large
            icon="search"
            value={value}
            xLarge={xLarge}
            onChange={onChangeValue}
            onFocus={onFocus}
            wrapperCss={{paddingRight: 30}}
            {...props}
          />
          <CloseButtonWrapper>
            <Link
              role="button"
              onClick={onClear}
              css={{cursor: 'pointer'}}
              data-testid="close-search-button">
              <Toggle show={Boolean(value?.length) && !props.disabled}>
                <ClearIcon />
              </Toggle>
              {loading ? (
                <LoaderWrapper>
                  <Loader color={lightGray} size={1} />
                </LoaderWrapper>
              ) : null}
            </Link>
          </CloseButtonWrapper>
        </Box>
        {showOptions && (
          <OptionsWrapper floating={floating}>
            {children({
              onClose: () => setShowOptions(false),
              onSelect: onSelectValue,
            })}
            {noneFoundComponent ? (
              <div onClick={() => setShowOptions(false)}>
                {noneFoundComponent}
              </div>
            ) : null}
          </OptionsWrapper>
        )}
      </SearchWrapper>
    </ClickOutside>
  );
}

SearchField.propTypes = {
  value: PropTypes.string,
  loading: PropTypes.bool,
  xLarge: PropTypes.bool,
  floating: PropTypes.bool,
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  css: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  noneFoundComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onNoneFoundClick: PropTypes.func,
};

export default SearchField;
