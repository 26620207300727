import {useEffect, useState} from 'react';

import {Box, Text, Image} from 'rebass';
import {random} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {Alert} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';

import {earlyOptionsV2Var, setEarlyOptionsV2} from '../../api/cache';

import loader from './loader.svg';

const AvailableOptionsAlertV2 = ({hasLoader = false}) => {
  const earlyOptionsCount = useReactiveVar(earlyOptionsV2Var);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasLoader) {
      setLoading(true);
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  useEffect(() => {
    if (!earlyOptionsCount) {
      setEarlyOptionsV2(random(9, 18));
    }
  });

  return (
    <Box
      marginLeft="auto"
      marginRight="auto"
      marginBottom="48px"
      display="inline-block">
      <Alert bgColor="#E7F9E9" color="#0B7517" border="1px solid #13C427">
        <Text
          alignItems="center"
          textAlign="left"
          fontSize={[15, 20]}
          lineHeight="22px">
          {loading && (
            <>
              <Image height="16px" marginRight="20px" src={loader} />
              Searching for the best possible options for your project...
            </>
          )}
          {!loading && (
            <>
              🎉 {earlyOptionsCount} potential loan options found allowing you
              to borrow up to&nbsp;
              <strong>${toCurrency(500000)}</strong>
            </>
          )}
        </Text>
      </Alert>
    </Box>
  );
};

export default AvailableOptionsAlertV2;
