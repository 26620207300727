import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const CloudFlareWrapper = styled(Flex)(
  mediaQuery({
    width: '100%',
    justifyContent: 'flex-end',
  }),
);
