import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Footer = styled(Box)(
  mediaQuery({
    position: 'relative',
    overflowAnchor: 'none',
    borderRadius: [0, '0 0 16px 16px'],
    margin: ['auto', '0 -95px -15px -95px'],
    borderTop: 'solid 1px white',
    background: '#F8F8F8',
  }),
  ({sticky, css}) =>
    mediaQuery({
      ...(sticky
        ? {
            marginBottom: 0,
            boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)',
            borderTop: 'none',
          }
        : {}),
      ...css,
    }),
);
