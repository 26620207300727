import {InMemoryCache} from '@apollo/client';

import {borrowersPolicy as borrowers} from './borrowers';
import {contactPolicy as contact} from './contact';
import {companyPolicy as company} from './company';
import {eligibilityPolicy as eligibility} from './eligibility';
import {experiencePolicy as experience} from './experience';
import {referencesPolicy as references} from './references';
import {gcddReviewsPolicy as gcddReviews} from './gcddReviews';

export const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        borrowers,
        contact,
        company,
        eligibility,
        experience,
        references,
        gcddReviews,
      },
    },
  },
});
