import styled from '@emotion/styled/macro';
import {Flex, Text, Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';

const COMMON_TITLE_PROPS = {
  fontSize: ['10px', '16px'],
  lineHeight: ['12px', '16px'],
  fontWeight: 300,
};

export const Wrapper = styled(Flex)(
  mediaQuery({
    position: 'relative',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: ['25px 5px 0 5px', '25px 10% 0 10%'],
  }),
);

export const ProgressBarContainer = styled(Flex)(
  mediaQuery({
    width: '100%',
    margin: ['0 2px', '0 5px'],
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export const ProgressTitle = styled(Text)(
  mediaQuery({
    ...COMMON_TITLE_PROPS,
    marginBottom: ['15px', '30px'],
  }),
  ({blurred}) =>
    blurred && {
      color: '#b5b5b5',
    },
);

export const ProgressEndTitle = styled(Text)(
  mediaQuery({
    ...COMMON_TITLE_PROPS,
    color: '#b5b5b5',
    marginBottom: ['5px', '15px'],
  }),
);

export const CheckImage = styled(Image)(
  mediaQuery({
    position: 'absolute',
    bottom: ['-5px', '-10px'],
    width: ['20px', '30px'],
  }),
);
