import {
  constants,
  useRaasSourceLender,
  isGeneralContractorLead,
  isPartnerSiteLead,
} from '@renofi/utils';

const useLeadExternalOriginType = (utmTags) => {
  const raasSourceLender = useRaasSourceLender(utmTags);

  let leadExternalOriginType = null;
  if (isGeneralContractorLead(utmTags)) {
    leadExternalOriginType = constants.LEAD_ORIGIN_TYPE.contractor;
  } else if (isPartnerSiteLead(utmTags)) {
    leadExternalOriginType = constants.LEAD_ORIGIN_TYPE.partnerSite;
  } else if (raasSourceLender) {
    leadExternalOriginType = constants.LEAD_ORIGIN_TYPE.raasPartner;
  }

  return leadExternalOriginType;
};

export default useLeadExternalOriginType;
