import {useQuery} from '@apollo/client';

import {IS_OPEN_OPPORTUNITY} from '../queries/isOpenOpportunity';

export default function useIsOpenOpportunity() {
  const {refetch} = useQuery(IS_OPEN_OPPORTUNITY, {skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
