import styled from '@emotion/styled/macro';
import {Button, Box} from 'rebass';
import {Tooltip} from 'react-tooltip';

import infoIcon from './img/info-icon.svg';
import closeIcon from './img/close-icon.svg';

export const tooltipBgStyles = {
  backgroundColor: 'white',
  textAlign: 'left',
  padding: '20px 20px 20px 12px',
  borderRadius: 10,
  filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1))',
  width: '80%',
  '&.show': {
    opacity: 1,
  },
};

export const StyledTooltip = styled(Tooltip)(({css}) => ({
  '&.react-tooltip': {
    ...tooltipBgStyles,
    maxWidth: 295,
    zIndex: 100,
    ...css,
  },
}));

export const TooltipBox = styled(Box)(
  {
    ...tooltipBgStyles,
  },
  ({width, css}) => ({
    width,
    ...css,
  }),
);

export const TooltipButton = styled(Button)(
  {
    borderRadius: 50,
    padding: 0,
    outline: 'none',
  },
  ({clicked}) => ({
    background: `url(${clicked ? closeIcon : infoIcon}) no-repeat`,
    width: clicked ? 23 : 28,
    height: clicked ? 23 : 28,
    marginTop: clicked ? 2 : 0,
  }),
);
