import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {CurrencyField} from '@renofi/components';

export const SliderCurrencyField = styled(CurrencyField)(
  mediaQuery({
    paddingLeft: [50, 60],
    height: 54,
    boxShadow: '0px 0px 7px 0px #00A0FF1A',
    margin: ['0 16px', 0],
    width: ['calc(100% - 32px)', 'auto'],
    fontWeight: 800,
    fontSize: 32,
    maxWidth: 300,
  }),
);
