import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const FooterLegalWrapper = styled(Box)(
  mediaQuery({
    position: ['absolute', 'relative'],
    background: ['#D0EBD4', 'white'],
    textAlign: 'center',
    width: '100%',
    margin: ['80px auto 0 auto', '-20px auto 0 auto'],
    zIndex: 101,
  }),
  ({isExpand, height}) =>
    mediaQuery({
      bottom: isExpand ? 0 : -height + 20,
      padding: isExpand
        ? ['50px 15px 40px 15px', '24px 0']
        : ['50px 15px 20px 15px', '24px 0'],
      transition: 'bottom .1s',
    }),
);

export const IconWrapper = styled(Box)(
  mediaQuery({
    display: ['block', 'none'],
    position: 'absolute',
    top: -32,
    left: 40,
  }),
);

export const UpDownButton = styled.button(
  mediaQuery({
    display: ['block', 'none'],
    width: 50,
    height: 36,
    position: 'absolute',
    top: -36,
    right: 15,
    border: 'none',
    background: '#D0EBD4',
    borderRadius: '5px 5px 0 0',
    ':focus': {
      outline: 'none',
    },
  }),
  ({isExpand}) =>
    mediaQuery({
      borderRadius: isExpand ? '5px 5px 0 0' : '0 0 5px 5px',
      transform: isExpand ? 'none' : 'rotate(180deg)',
      paddingTop: isExpand ? 10 : 5,
    }),
);
