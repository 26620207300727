import styled from '@emotion/styled/macro';
import {Label as RebassLabel} from '@rebass/forms';

import {darkGray, gray, red} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export default styled(RebassLabel)(
  {},
  ({small, color, error, fontSize}) =>
    mediaQuery({
      fontWeight: 300,
      fontSize: fontSize || (small ? 14 : 18),
      color: error ? red : color || small ? gray : darkGray,
    }),
  ({css}) => css,
);
