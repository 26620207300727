import styled from '@emotion/styled/macro';
import {Box, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

import rectangle from './img/rectangle.png';
import verticalLine from './img/vertical-line.svg';

export const HowItWorksSteps = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: [`url(${verticalLine})`],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  }),
);

export const StepContainer = styled.div(
  mediaQuery({
    backgroundImage: [`url(${rectangle})`],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    padding: '0 23px',
    margin: [0, '4px 0'],
    width: ['', '450px'],
    maxWidth: '450px',
    height: '120px',
  }),
);

export const StepContent = styled.div(
  mediaQuery({
    marginBottom: '16px',
    textAlign: 'center',
    padding: '5px',
  }),
);

export const Title = styled.div(
  mediaQuery({
    fontSize: ['20px', '24px'],
    lineHeight: '29px',
    fontWeight: 700,
  }),
);

export const Description = styled.div(
  mediaQuery({
    color: gray,
  }),
);

export const CTA = styled(Box)(
  mediaQuery({
    margin: ['20px 0 0 0', '20px 0'],
  }),
);

export const Disclaimer = styled(Flex)(
  mediaQuery({
    padding: ['30px 20px 0 20px', '15px 25% 40px 25%'],
    color: gray,
    fontSize: '14px',
    textAlign: 'center',
  }),
);
