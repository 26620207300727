import {useQuery} from '@apollo/client';

import {GET_CONTRACTOR_BY_SLUG} from '../queries';

export default function useContractorBySlug({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, fetch, loading, refetch} = useQuery(GET_CONTRACTOR_BY_SLUG, {
    fetchPolicy,
    ...options,
  });
  const contractor = data?.contractorBySlug;

  return {contractor, fetch, loading, refetch};
}
