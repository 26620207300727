import styled from '@emotion/styled';
import {Flex} from 'rebass';

import {CurrencyField} from '@renofi/components';
import {lightGray, mediaQuery} from '@renofi/utils';

export const RangeWrapper = styled(Flex)(({css}) => ({
  background: '#f8f8f8',
  borderTop: '1px solid #d8d8d8',
  ...css,
}));

export const PlusMinusBtn = styled(Flex)(({size}) =>
  mediaQuery({
    border: '1px solid #f8f8f8',
    boxShadow: '0px 0px 7px #0000001a',
    borderRadius: 8,
    background: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '0px 12px',
    fontSize: 40,
    color: lightGray,
    width: [42, size],
    height: [42, size],
    flexBasis: [42, size],
    userSelect: 'none',

    '&:hover': {
      borderColor: '#80cfff',
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: '#e5f5ff',
      borderColor: '#00a0ff',
    },
  }),
);

export const CustomInput = styled(CurrencyField)(
  mediaQuery({
    textAlign: 'center',
    fontSize: 40,
    lineHeight: '50px',
    fontFamily: 'Averta, "Helvetica Neue", Helvetica, Arial, "Lucida Grande"',
    fontWeight: 'bold',
  }),
);
