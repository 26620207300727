import {useQuery} from '@apollo/client';

import {setAnalyticsIdentify} from '@renofi/analytics';
import {getLeadJwt} from '@renofi/utils/src/lead';

import {RENOVATION_SCENARIO} from '../queries/renovationScenario';

export default function useRenovationScenario(options) {
  const {refetch} = useQuery(RENOVATION_SCENARIO, {
    ...options,
    skip: true,
    context: {
      headers: {
        authorization: getLeadJwt(options?.id) || '',
      },
    },
    onCompleted: (response) => {
      const {lead, loanProductMatches} = response?.result || {};
      if (lead || loanProductMatches) {
        setAnalyticsIdentify(lead, {loanProducts: loanProductMatches});
      }
      options?.onCompleted && options.onCompleted(response);
    },
  });
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
