import {Text, Flex, Box} from 'rebass';
import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {darkBlue, lightGray, gray, blue} from '@renofi/utils/src/colors';

export const OptionsWrapper = styled(Flex)(() =>
  mediaQuery({
    flexDirection: ['column', 'row'],
    position: 'relative',
    justifyContent: 'center',
    width: '100%',
  }),
);

export const SingleOptionWrapper = styled(Flex)(() =>
  mediaQuery({
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    ':first-of-type:not(:last-of-type)': {
      borderRight: ['none', '1px solid #EBEBEB'],
    },
  }),
);

export const GCLeadOptionWrapper = styled(SingleOptionWrapper)(() =>
  mediaQuery({
    ':first-of-type:not(:last-of-type)::after': {
      content: '"AND"',
      position: 'absolute',
      right: '-14px',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontWeight: 600,
      color: lightGray,
    },
  }),
);

export const GCOption = styled(Flex)(() =>
  mediaQuery({
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: ['1px solid #ebebeb', 'none'],
    borderRight: ['none', '1px solid #EBEBEB'],
    ':last-of-type': {
      borderRight: 'none',
    },
  }),
);

export const GCOptionTitleText = styled(Text)(
  mediaQuery({
    fontSize: 24,
    lineHeight: '30px',
  }),
);

export const OptionWrapper = styled(Flex)(({qualified}) =>
  mediaQuery({
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    border: '1px solid #E3E4E4',
    position: 'relative',
    borderLeft: !qualified && 'none',
    borderRadius: qualified && '8px',
    boxShadow: qualified && '0 0 7px rgba(0, 160, 255, 0.2)',
    margin: ['8px 0 4px 0', qualified ? '0 8px 0 0' : '0 0 8px 0'],
    minHeight: ['auto', '100%'],
    '&:last-child': {
      marginRight: 0,
      borderRight: !qualified && 'none',
    },
    backgroundColor: '#FFF',
    maxWidth: 564,
  }),
);

export const OptionRow = styled(Flex)(({tinted, padded}) =>
  mediaQuery({
    minHeight: '75px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: tinted ? '#F5F6F7' : '#fff',
    padding: `${padded ? '24px' : 0} 24px`,
    '@media (max-width: 1127px)': {
      padding: `${padded ? '24px' : 0} 14px`,
    },
    '&:last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderBottom: 'none',
    },
  }),
);

export const OptionHeader = styled(Flex)(({large, px}) =>
  mediaQuery({
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '24px 33px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    minHeight: large ? [130, 170] : 130,
    mark: {
      background: 'none',
      color: gray,
    },
    '& h1': {
      textAlign: 'center',
      margin: 0,
      padding: 0,
      fontSize: '26px',
      lineHeight: '30px',
      fontWeight: 400,
      color: '#212F49',
      '& mark': {
        fontWeight: 700,
        color: '#FF5253',
        background: 'transparent',
      },
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '16.8px',
      marginTop: '8px',
    },
    ...(px ? {paddingLeft: px, paddingRight: px} : {}),
  }),
);

export const OptionItemText = styled(Flex)(
  ({bold, italic, blue, small, css}) => ({
    flex: 1,
    color: bold ? darkBlue : blue ? darkBlue : gray,
    fontSize: bold ? (small ? '14px' : '18px') : '14px',
    lineHeight: bold ? (small ? '18px' : '28px') : '18px',
    fontWeight: bold ? 700 : 300,
    textAlign: 'left',
    margin: '5px',
    fontStyle: italic && 'italic',
    ...css,
  }),
);

export const Small = styled(Text)(({css, fontWeight, lineHeight}) =>
  mediaQuery({
    color: gray,
    fontSize: 14,
    fontWeight: fontWeight || 300,
    lineHeight: lineHeight || '18px',
    ...css,
  }),
);

export const PanelBottomText = styled(Text)(({bold}) =>
  mediaQuery({
    fontSize: bold ? 16 : 12,
    lineHeight: bold ? '24px' : '15px',
    fontWeight: bold ? 700 : 400,
  }),
);

export const Narrow = styled(Box)(
  mediaQuery({
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  ({maxWidth, isNarrow, css}) =>
    mediaQuery({
      maxWidth: isNarrow ? maxWidth || 366 : 'none',
      ...css,
    }),
);

export const FatLink = styled(Text)(() =>
  mediaQuery({
    marginTop: 36,
    textAlign: 'center',
    color: blue,
    fontWeight: 800,
    cursor: 'pointer',
  }),
);
