import React from 'react';

import PropTypes from 'prop-types';
import {Text, Flex} from 'rebass';

import {gray} from '@renofi/utils/src/colors';

import {ReactComponent as BulbIcon} from './img/bulb.svg';

const TooltipContent = ({children, icon, ...props}) => {
  const Icon = icon || BulbIcon;
  return (
    <Flex {...props}>
      <Flex flexDirection="column" alignItems="center" minWidth={25} mr={15}>
        <Icon />
      </Flex>

      <Text fontWeight={300} lineHeight="21px" fontSize={16} color={gray}>
        {children}
      </Text>
    </Flex>
  );
};

TooltipContent.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.any,
};

export default TooltipContent;
