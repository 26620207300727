import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export default styled(Text)(
  mediaQuery({
    fontWeight: 400,
  }),
  ({mb, mt, mr, ml, m, p, css, small, superSmall, left}) =>
    mediaQuery({
      position: 'relative',
      fontSize: superSmall ? 14 : small ? [18, 18] : [20, 24],
      lineHeight: small || superSmall ? ['20px', '24px'] : ['24px', '32px'],
      margin: ['0 0px 70px 0px', '0 0 40px 0'],
      textAlign: left ? 'left' : 'center',
      ...(mb ? {marginBottom: mb} : {}),
      ...(mt ? {marginTop: mt} : {}),
      ...(mr ? {marginRight: mr} : {}),
      ...(ml ? {marginLeft: ml} : {}),
      ...(m ? {margin: m} : {}),
      ...(p ? {padding: p} : {padding: [0, 0]}),
      ...css,
      ':after': {
        zIndex: -1,
        display: 'none',
      },
    }),
);
