import styled from '@emotion/styled/macro';
import {Box, Text} from 'rebass';
import ReactSlider from 'react-slider';

import {gray, darkGray, lightGreen} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const SliderWrapper = styled(Box)(({fullWidth, minimal}) =>
  mediaQuery({
    width: minimal ? '100%' : fullWidth ? '100%' : ['100%', 675],
    textAlign: minimal ? 'left' : null,
  }),
);

export const Slider = styled(ReactSlider)({
  userSelect: 'none',
  height: 25,
  '.thumb': {
    outline: 'none',
    cursor: 'pointer',
    width: 36,
    height: 30,
    background: `white`,
    border: 'solid 1px #D8D8D8',
    borderRadius: 15,
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.2)',
    ':active': {
      backgroundPosition: '13px 11px',
      marginTop: -1,
      marginLeft: -1,
      width: 38,
      height: 32,
    },
  },
  '.track': {
    top: 12,
    height: 10,
  },

  '.track-0': {
    height: 5,
    background: lightGreen,
    borderRadius: 20,
  },
  '.track-1': {
    background: `#e0e0e0`,
    height: 5,
    borderRadius: 20,
  },
});

export const SliderLabel = styled(Text)(({minimal}) => ({
  fontSize: 20,
  fontWeight: 300,
  color: gray,
  marginBottom: minimal ? 5 : 48,
}));

export const SliderValue = styled(Text)(({minimal, css}) =>
  mediaQuery({
    userSelect: 'none',
    textAlign: 'center',
    fontSize: minimal ? [30, 40] : [42, 50],
    lineHeight: minimal ? 48 : ['44px', '52px'],
    fontWeight: 700,
    minWidth: 200,
    color: darkGray,
    paddingTop: minimal ? 0 : 5,
    ...css,
  }),
);
