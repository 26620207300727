import {useState, useEffect} from 'react';

import {includes, map} from 'lodash';

import {useCookies, constants} from '@renofi/utils';
import {isForcingUTM} from '@renofi/analytics/src/analytics';

const RAAS_SOURCE_LENDER_COOKIE_KEY = 'renofi_pos_raas_source_lender';

const useRaasSourceLender = (utmTags) => {
  const [sourceLender, setSourceLender] = useState(null);
  const {cookie, updateCookie} = useCookies(
    RAAS_SOURCE_LENDER_COOKIE_KEY,
    null,
  );

  useEffect(() => {
    const isRaasSource = includes(
      map(constants.RAAS_PARTNERS, 'code'),
      utmTags.source,
    );

    if (cookie) {
      setSourceLender(cookie);
      if (!isForcingUTM()) return;
    }

    if (utmTags.source && isRaasSource) {
      updateCookie({
        value: utmTags.source,
        numberOfDays: constants.HUNDRED_YEARS_IN_DAYS,
      });
      setSourceLender(utmTags.source);
    }

    if (utmTags.source && !isRaasSource && isForcingUTM()) {
      updateCookie({value: false});
      setSourceLender(null);
    }
  }, [utmTags.source, cookie]);

  return sourceLender;
};

export default useRaasSourceLender;
