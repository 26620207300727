import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {lightGray} from '@renofi/utils/src/colors';

export const IconSocialProof = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    margin: '10px',
    width: [345, 252],
    minHeight: [117, 300],
    overflow: ['hidden', 'visible'],
    color: lightGray,
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 300,
    padding: '28px',
    img: {
      display: 'block',
      margin: '0 auto',
      padding: [10, 5],
    },
  }),
);
export const IconsSocialProofWrapper = styled(Flex)(
  mediaQuery({
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'center',
    width: '120%',
    marginLeft: '-10%',
    marginTop: '40px',
  }),
);
