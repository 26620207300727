import {useMutation} from '@apollo/client';

import {SUBMIT_TCPA_CONSENT} from '../mutations/submitTCPAConsent';

export default function useSubmitTCPAConsent() {
  const [submitTcpaConsent, {data, loading, error}] =
    useMutation(SUBMIT_TCPA_CONSENT);
  const response = data?.submitTcpaConsent;
  return {submitTcpaConsent, response, loading, error};
}
