import {Text} from 'rebass';

const SubHeading = ({children, small, mt}) => {
  if (small)
    return (
      <Text fontWeight={100} fontSize={18} mt={mt || -16} mb={32}>
        {children}
      </Text>
    );

  return (
    <Text fontSize={18} color="#737373" lineHeight="21px" mb={32} mt={mt || 0}>
      {children}
    </Text>
  );
};

export default SubHeading;
