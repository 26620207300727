import React from 'react';

import {Button} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

const POINT_URL =
  'https://home.point.com/?utm_source=Renofi&utm_medium=partners&utm_campaign=point-partner-pages&utm_term=0015e00000sLy0uAAC&_ga=2.23496638.546429597.1655489001-1860493838.1654267588';

const PointCTA = () => {
  const handleCTAClick = () => {
    sendEvent('POS/Point-HEI-Button-Click');
    window.open(POINT_URL, '_blank');
  };

  return <Button onClick={handleCTAClick}>Get Started</Button>;
};

export default PointCTA;
