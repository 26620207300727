import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {lightGray} from '@renofi/utils/src/colors';

export const CheckboxWrapper = styled(Box)(({disabled}) => ({
  cursor: disabled ? 'default' : 'pointer',
  'input ~ svg': {
    position: 'relative',
    top: -4,
    left: -7,
  },
  'input:checked ~ svg': {
    color: disabled ? lightGray : '#3786FD',
  },
  'input:focus ~ svg': {
    backgroundColor: 'transparent !important',
  },
}));
