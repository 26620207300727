import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {CurrencyField, ButtonSelect, CheckboxPanel} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';

import {Info} from '../../components';
import {leadVar, paymentSelectionsVar} from '../../api/cache';
import {SideLabel} from '../styled';

const options = {
  Yes: true,
  No: false,
};
function CreditCard({onChange, onToggle, onPaymentSelectionsChange, noDebt}) {
  const {isCreditCardGroupOpen, creditCardPaidOff} =
    useReactiveVar(paymentSelectionsVar);
  const {creditCardPayment} = useReactiveVar(leadVar);

  return (
    <CheckboxPanel
      label="Credit card payment"
      onToggle={onToggle}
      value={isCreditCardGroupOpen}
      greyOut={noDebt}>
      <Flex mb={30}>
        <Box width={1 / 2}>
          <SideLabel>Do you pay off your entire balance each month? </SideLabel>
        </Box>
        <Box width={1 / 2}>
          <ButtonSelect
            value={creditCardPaidOff}
            inline
            internal
            onClick={(value) =>
              onPaymentSelectionsChange('creditCardPaidOff', value)
            }
            options={options}
          />
        </Box>
      </Flex>
      {creditCardPaidOff === false && (
        <Flex>
          <Box width={1 / 2}>
            <SideLabel>
              <strong>Minimum monthly</strong> credit card payments for all
              applicants
            </SideLabel>
          </Box>
          <Box width={1 / 2}>
            <CurrencyField
              errMessages={{
                max: `You have entered $${toCurrency(
                  creditCardPayment,
                )} as your MINIMUM monthly credit card payment. If that's incorrect, please update, otherwise continue.`,
              }}
              errorCss={{position: 'static'}}
              max={2000}
              currency
              icon
              name="credit-card-payment"
              value={creditCardPayment}
              onChange={(value) => onChange('creditCardPayment', value)}
              analyticsEnabled
              analyticsPrefix="POS"
            />
          </Box>
        </Flex>
      )}
      {creditCardPaidOff && (
        <Info>
          If you pay off your entire balance each month, it is excluded from
          your DTI calculation.
        </Info>
      )}
    </CheckboxPanel>
  );
}

CreditCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPaymentSelectionsChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  noDebt: PropTypes.bool,
};

export default CreditCard;
