import {Flex, Box, Text, Link} from 'rebass';
import PropTypes from 'prop-types';

import {getWarningReasons, constants} from '@renofi/utils';

import Carousel from './Carousel';
import CarouselItem from './CarouselItem';
import improveCsSrc from './img/improve-cs.svg';
import improveDtiSrc from './img/improve-dti.svg';
import calculateIncomeSrc from './img/calculate-income.svg';
import whyNotQualifySrc from './img/why-not-qualify.svg';
import contractorsSrc from './img/contractors.svg';
import insuranceSrc from './img/contractors-insurance.svg';

const {PREQUAL_FAILURE_TYPE} = constants;

const ProductCarousel = ({lead}) => {
  const reasons = getWarningReasons(lead);
  const isContractorFailure = reasons.includes(PREQUAL_FAILURE_TYPE.PERFORMER);

  return (
    <>
      <Flex
        flexWrap="wrap"
        textAlign="center"
        justifyContent="center"
        px={[16, 0]}>
        <Box
          width={1}
          pb={32}
          fontSize={[18, 28]}
          lineHeight={['24px', '36px']}>
          <Text css={{maxWidth: 580, margin: '0 auto'}}>
            Articles that might help you get ready to apply. You may also
            contact us via this{' '}
            <Link
              target="_blank"
              css={{
                textDecoration: 'none',
                ':hover': {textDecoration: 'underline'},
              }}
              fontSize={[18, 28]}
              href={`https://www.renofi.com/contact/?utm_medium=pos&utm_source=loan-options&utm_campaign=message&utm_content=${lead?.id}`}>
              link
            </Link>
            .
          </Text>
        </Box>
        <Box maxWidth={[320, 1000]} px={[0, 70]} width={1} pb={64}>
          <Carousel>
            <CarouselItem
              color="#E7F9E9"
              text="Why your renovation might not qualify"
              linkTo="https://www.renofi.com/guides/how-to-qualify-for-renovation-home-equity-loan/"
              imgSrc={whyNotQualifySrc}
            />
            {isContractorFailure ? (
              <CarouselItem
                color="#E8F4FE"
                text="Why contractors should have general liability insurance"
                linkTo="https://www.renofi.com/guides/contractor-insurance-bond-for-renovation-construction/"
                imgSrc={insuranceSrc}
              />
            ) : null}

            {isContractorFailure ? (
              <CarouselItem
                color="#FDEDEE"
                text="How to choose a contractor"
                linkTo="https://www.renofi.com/guides/finding-a-contractor-home-renovation/"
                imgSrc={contractorsSrc}
              />
            ) : null}
            <CarouselItem
              color="#E5F5FF"
              text="Ways to improve your credit score quickly"
              linkTo="https://www.renofi.com/guides/improving-your-credit-score-quickly/"
              imgSrc={improveCsSrc}
            />
            <CarouselItem
              color="#FFEEEE"
              text="How to improve your DTI Ratio"
              linkTo="https://www.renofi.com/guides/how-to-qualify-for-renovation-home-equity-loan/#2-your-debt-to-income-dti-ratio-is-too-high"
              imgSrc={improveDtiSrc}
            />
            <CarouselItem
              color="#E7F9E9"
              text="Calculating your household income"
              linkTo="https://www.renofi.com/guides/how-do-lenders-set-rates-for-renofi-loans/"
              imgSrc={calculateIncomeSrc}
            />
          </Carousel>
        </Box>
      </Flex>
    </>
  );
};

ProductCarousel.propTypes = {
  isQualified: PropTypes.bool,
};

export default ProductCarousel;
