import React, {useState, useEffect, useRef, Fragment} from 'react';

import PropTypes from 'prop-types';
import {every, noop, size, isFinite, isNil} from 'lodash';
import {Flex} from 'rebass';

import {useAutofocus, useScreenSize} from '@renofi/utils';

import {TextField, Period} from './styled';

const ZipInput = ({
  onComplete = noop,
  onChange = noop,
  value,
  error,
  float,
  autofocus,
  suffix,
  count = 5,
}) => {
  const {isMobile, isFirefox, isAndroid} = useScreenSize();
  const empty = Array.from(Array(count).values());
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refs = empty.map(() => useRef(null));
  const [state, setState] = useState(
    value ? toStateArray(value) : createStateArray(count),
  );
  const [autoFill, setAutoFill] = useState('');

  useAutofocus(autofocus, refs[0], value);

  useEffect(() => {
    if (
      every(Object.values(state).slice(0, count), isFinite) &&
      size(state) >= count
    ) {
      onComplete(zip());
    } else {
      onComplete(false);
    }
  }, [state]);

  function zip() {
    return Object.values(state).join('').slice(0, count);
  }

  function isNextEmpty(index) {
    return !state[index + 1] && state[index + 1] !== 0;
  }

  function isLast(index) {
    return index >= count - 1;
  }

  function setValue(value, index) {
    let autoFillValue = value.toString();
    if (autoFillValue.length > 1) {
      handleAutoFill(autoFillValue);
      return;
    }
    setState({...state, [index]: value});
  }

  function toStateArray(value) {
    return value.split('').map(Number);
  }

  function createStateArray(length) {
    return Array.from({length}).fill('');
  }

  //workaround for Android and Safari iOS keypad and auto-fill inconsistencies
  function clearSelection() {
    if (autoFill.length !== count) return;
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }

  function onInputChange(e, index) {
    const value = Number(e.target.value);
    if (!isFinite(value)) return;

    setValue(value, index);
    onChange(value);

    if (isNextEmpty(index) && !isLast(index)) {
      setTimeout(() => {
        refs[index + 1].current.focus();
      });
    }
  }

  function handleAutoFill(autoFillValue) {
    if (!isFinite(Number(autoFillValue))) return;
    let stateValue = toStateArray(autoFillValue);
    if (stateValue.length !== count) {
      stateValue = stateValue.concat(
        createStateArray(count - stateValue.length),
      );
    }
    setState(stateValue);
    setAutoFill(autoFillValue);
  }

  const handlePaste = (e) => {
    const value = e.clipboardData.getData('Text').trim().substring(0, count);
    if (!isFinite(Number(value))) return;

    let stateValue = toStateArray(value);
    if (stateValue.length !== count) {
      stateValue = stateValue.concat(
        createStateArray(count - stateValue.length),
      );
    }

    e.preventDefault();
    setState(stateValue);
    onChange(value);
  };

  return (
    <Flex justifyContent="center">
      <Flex
        id={count}
        width={['100%', 380]}
        justifyContent="center"
        alignItems="flex-end">
        <input
          id="safari-autofill-me"
          name="zip"
          style={{position: 'absolute', top: '-100px', display: 'none'}}
          type="text"
        />

        {refs.map((ref, index) => (
          <Fragment key={index}>
            <TextField
              id={`zip-input-${index}`}
              name={`disable-autocomplete-${index}`}
              error={error}
              autoComplete="new-password"
              empty={isNil(state[index]) || state[index] === ''}
              value={state[index]}
              onChange={(e) => onInputChange(e, index)}
              onClick={() => setValue('', index)}
              onPaste={handlePaste}
              onSelect={clearSelection}
              ref={ref}
              type={isMobile && isAndroid && !isFirefox ? 'number' : undefined}
              inputmode="numeric"
              pattern="[0-9]*"
            />
            {float && index === count - 3 ? <Period>.</Period> : null}
          </Fragment>
        ))}
        {suffix ? suffix : null}
      </Flex>
    </Flex>
  );
};

ZipInput.propTypes = {
  onComplete: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.bool,
  float: PropTypes.bool,
  autofocus: PropTypes.bool,
  suffix: PropTypes.node,
  count: PropTypes.number,
};

export default ZipInput;
